import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var initialState = {
  usertoken: "",
  userdata: {},
}
if (localStorage.getItem('usertoken')) {
  console.log("localStorage.getItem('token')")
  console.log(localStorage.getItem('usertoken'))
  initialState.usertoken = localStorage.getItem('usertoken')
  initialState.userdata = JSON.parse(localStorage.getItem('userdata'))
}
export const userdataSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {
    userlogin: (state, params) => {
      if (params.payload.token) {
        state.usertoken = params.payload.token
        // if (params.payload.rememberMe) {
        localStorage.setItem("usertoken", params.payload.token)
        // }
      }
      state.userdata = params.payload.user
      // if (params.payload.rememberMe) {
      localStorage.setItem("userdata", JSON.stringify(params.payload.user))
      // }
    },
    userlogout: (state) => {
      const userChecked = localStorage.getItem('userChecked');
      if (userChecked === 'false') {
        localStorage.removeItem("userEmail")
        localStorage.removeItem("userPassword")
      }

      state.usertoken = ""

      state.userdata = {}
      localStorage.removeItem("usertoken")
      localStorage.removeItem("userdata")
    }
  },
})

export const toastify = async (message) => {
  const toastId = toast.info(message.message, {
    toastId: 'success1',
    icon: false,
    autoClose: 3000
  });
};

export const dateToMDY = async (date) => {
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)+ '-' + y;
}

export const clearuser = async (message) => {
  localStorage.removeItem("userdata")
  localStorage.removeItem("usertoken")
  const userChecked = localStorage.getItem('userChecked');
  if (userChecked === 'false') {
    localStorage.removeItem("userEmail")
    localStorage.removeItem("userPassword")
  }
};

export const makeid = async (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

// Action creators are generated for each case reducer function
export const { userlogin, userlogout } = userdataSlice.actions

export default userdataSlice.reducer
