import React, { useState, useRef } from "react";
import mainlogo from "../images/splash.png";
import "./App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify } from '../redux/controller';
import Successicon from "../assets/Check.png";

export default function ChangePassword() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const userid = new URLSearchParams(search).get("user");

  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [sendedemail, setsendedemail] = useState(true);
  const [successPopup, setSuccessPopup] = useState(false);
  const [inputField, setInputField] = useState({
    newpassword: '',
    confirmpassword: ''
  });

  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const handlesuccessPopup = () => {
    setSuccessPopup(!successPopup);
  };

  const changepassword = async () => {
    if (inputField.newpassword.length < 1) {
      toastify({ "message": "Please enter new password" });
      return;
    } else if (inputField.newpassword.length < 8) {
      toastify({ "message": "Password must be at least 8 characters" });
      return;
    } else if (inputField.newpassword.search(/[a-z]/) < 0) {
      toastify({ "message": "Password must contain at least one lowercase letter" });
      return;
    } else if (inputField.newpassword.search(/[A-Z]/) < 0) {
      toastify({ "message": "Password must contain at least one uppercase letter" });
      return;
    } else if (inputField.newpassword.search(/[0-9]/) < 0) {
      toastify({ "message": "Password must contain at least one number" });
      return;
    } else if (inputField.confirmpassword.length < 1) {
      toastify({ "message": "Please enter confirm password" });
      return;
    } else if (inputField.newpassword !== inputField.confirmpassword) {
      toastify({ 'message': "Password and confirm password fields do not match. Please ensure both fields are identical." });
      return;
    }

    if (sendedemail) {
      setsendedemail(false);
      var user_ = await dispatch(fetchData({
        "apiurl": "/auth/forgot/change/password",
        "userid": userid,
        "newpassword": inputField.newpassword
      }));
      if (user_ && user_.payload && user_.payload.status === 200) {
        setsendedemail(true);
        // toastify({ 'message': "Password changed successfully" });
        setSuccessPopup(true);
        // Navigate('/')
      } else {
        setsendedemail(true);
        toastify({ 'message': user_.payload.message });
      }
    }
  };

  const inputsHandler = (e) => {
    setInputField(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleNewType = () => {
    newPasswordRef.current.type = newPasswordRef.current.type === 'text' ? 'password' : 'text';
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const handleConfirmType = () => {
    confirmPasswordRef.current.type = confirmPasswordRef.current.type === 'text' ? 'password' : 'text';
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  return (
    <div>
      <div className="changepass_section">
        <div className="w-100 text-center">
          <div className="row">
            <div className="col-lg-7 col-sm-12">
              <div className="changepass_form_content">
                <div className="content_section">
                  <form onSubmit={e => e.preventDefault()}>
                    <h2>Change Password</h2>
                    <h5>
                      To Change your Password, please fill the fields below.
                    </h5>
                    <div className="form-row">
                      <div className="input-group">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="New Password"
                          name="newpassword"
                          onChange={inputsHandler}
                          value={inputField.newpassword}
                          ref={newPasswordRef}
                        />
                        <button
                          type="button"
                          className="eye-icon btn btn-outline-secondary"
                          onClick={handleNewType}
                        >
                          {isNewPasswordVisible ? <i className="fa fa-eye" aria-hidden="true"></i> :
                            <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </button>
                      </div>
                      <div className="input-group">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="Confirm New Password"
                          name="confirmpassword"
                          onChange={inputsHandler}
                          value={inputField.confirmpassword}
                          ref={confirmPasswordRef}
                        />
                        <button
                          type="button"
                          className="eye-icon btn btn-outline-secondary"
                          onClick={handleConfirmType}
                        >
                          {isConfirmPasswordVisible ? <i className="fa fa-eye" aria-hidden="true"></i> :
                            <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </button>
                      </div>
                    </div>
                    <div className="form-row w-100">
                      <button
                        type="submit"
                        className="users_btn change-passbtn"
                        onClick={changepassword}
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12">
              <div className="image_section" style={{ textAlign: "end" }}>
                <img src={mainlogo} alt="mainlogo" />
              </div>
            </div>
          </div>
        </div>
      </div>      {successPopup && (
        <div className="chagesuccess-popup">
          <div className="chagesuccess-content">
            <i className="fa-solid fa-xmark" onClick={handlesuccessPopup}></i>
            <div className="chagesuccess-contentdiv">
              <img src={Successicon} alt="Successicon" />
              <h1>Password Changed</h1>
              <h1> Successfully </h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
