import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../../redux/apiSlice';
import { userlogout, clearuser } from "../../redux/controller";
import './chats.css'; // Ensure this includes your global styles
import { collection, query, onSnapshot, addDoc, orderBy } from 'firebase/firestore';
import { db } from "../../redux/firebase";
import Profileimg from "../../assets/user.png";

export default function Chats() {
    let Navigate = useNavigate();
    const dispatch = useDispatch();
    const initialized = useRef(false);
    const [selectedChat, setSelectedChat] = useState(null);

    const userinfo = useSelector((state) => state.userdata.userdata);

    const [chatUsers, setUserList] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [messages, setMessages] = useState([]);

    const chatBodyRef = useRef(null);

    useEffect(() => {
        if (selectedChat) {
            const messagesRef = collection(db, 'chats', `${selectedChat._id}_${userinfo._id}`, 'messages');
            const q = query(messagesRef, orderBy('time'));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const fetchedMessages = snapshot.docs.map(doc => doc.data());
                setMessages(fetchedMessages);
            });

            return () => unsubscribe();
        }
    }, [selectedChat]);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSendMessage = async () => {
        var message = newMessage;
        setNewMessage("");
        if (selectedChat && message.trim()) {
            const messagesRef = collection(db, 'chats', `${selectedChat._id}_${userinfo._id}`, "messages");
            await addDoc(messagesRef, {
                sender: 'You',
                content: message,
                time: new Date(),
                senderType: 'self'
            });
        }
    };

    const usertoken = useSelector((state) => state.userdata.usertoken);
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            if (usertoken) {
                getreviewlist();
            }
        }
    }, [usertoken]);

    async function getreviewlist() {
        if (usertoken == null || usertoken.length < 1) {
            clearuser();
            userlogout();
            Navigate('/');
            window.location.reload();
            return;
        }

        var reviewlist = await dispatch(
            fetchData({
                apiurl: "/reservation/hunter/review/list",
                headers: { "x-access-token": usertoken }
            })
        );
        console.log(reviewlist);

        if (reviewlist && reviewlist.payload && reviewlist.payload.status == 200) {
            if (reviewlist.payload.data != null && reviewlist.payload.data.length > 0) {
                const doubledList = [...reviewlist.payload.data, ...reviewlist.payload.data];
                setUserList(doubledList);
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-box">
                <div className="chat-list">
                    {chatUsers.map(chat => (
                        <div
                            key={chat.id}
                            className={`chat-item ${chat.unread ? 'unread' : ''}`}
                            onClick={() => setSelectedChat(chat)}
                        >
                            <div className="avatar">
                                {chat.image ? <img src={process.env.REACT_APP_HUNTER_BASEURL + "/" + chat.image} alt="Profileimg" /> : <img src={Profileimg} alt="Profileimg" />}
                            </div>
                            <div className="chat-info">
                                <div className="chat-name">{chat.firstname + " " + chat.lastname}</div>
                                <div className="last-message">{chat.email}</div>
                            </div>
                            <div className="chat-meta">
                                <div className="time">{chat.time}</div>
                                {chat.unread > 0 && <div className="unread-count">{chat.unread}</div>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="chat-window">
                {selectedChat ? (
                    <>
                        <div className="chat-header">
                            <div className="chat-title">{selectedChat.firstname + " " + selectedChat.lastname}</div>
                            <div className="chat-options">{/* Add icons for options */}</div>
                        </div>
                        <div className="chat-body" ref={chatBodyRef}>
                            {messages.length != 0 ?
                                messages.map((message, index) => (
                                    <div key={index} className={`chat-message ${message.senderType}`}>
                                        <div className="message-meta">
                                            <div className="message-content">
                                                {message.content}
                                            </div>
                                            <span className="message-time">
                                                {new Date(message.time.seconds * 1000).toLocaleTimeString()}
                                            </span>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className="no-messages"><p>No messages</p></div>
                            }
                        </div>
                        <div className="message-input">
                            <input
                                type="text"
                                placeholder="Type a message..."
                                value={newMessage}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setNewMessage(e.target.value)}
                            />
                            <button onClick={handleSendMessage}>Send</button>
                        </div>
                    </>
                ) : (
                    <div className="chat-placeholder">Select a chat to start messaging</div>
                )}
            </div>
        </div>
    );
}
