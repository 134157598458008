import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import "react-toastify/dist/ReactToastify.css";

// Define the initial state
const initialState = {
  data: [],
  loading: false,
  error: null,
};

// Define a thunk for fetching data asynchronously
export const fetchData = createAsyncThunk('api/fetchData', async (body) => {

  try {
    var headers = body.headers
    var apiurl = body.apiurl
    delete body.headers
    delete body.apiurl
    const response = await axios.post(process.env.REACT_APP_BASEURL+apiurl, body, {headers: headers});
    return response.data;
  } catch (error) {
    console.log('error')
    console.log(error.response.data.message === 'Invalid token provided!')
    if(error.response.data.status === 500 && error.response.data.message === 'Invalid token provided!'){
      console.log("Logout")
      console.log(error.response.data)
    }
    return error
  }
});

// Define the slice
const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default apiSlice.reducer;