import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Xicon from "../assets/Delete Icon.svg";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout, clearuser } from '../redux/controller';

export default function ManageCoupons() {


  const [searchvalue, setsearchvalue] = useState("");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1); // Page index starts from 0
  const [selectedCoupon, setSelectedCoupon] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [totalcoupons, settotalCoupons] = useState(0);
  const [manageCouponStatus, setManageCouponStatus] = useState(false);
  const Navigate = useNavigate();
  // const perPage = 10;
  const [perPage, setperPage] = useState(10);
  const [loading, setloading] = useState(false);

  const userToken = useSelector((state) => state.userdata.usertoken);

  useEffect(() => {
    getCoupons(currentPage, perPage, searchvalue);
  }, []);

  function inputperPage(e) {
    console.log(e.target.value)
    setperPage(e.target.value)
    setCurrentPage(1)
    getCoupons(1, e.target.value, searchvalue);
  }

  async function getCoupons(currentpage, limit, search) {
    if (userToken == null || userToken.length < 1) {
      clearuser()
      userlogout()
      Navigate('/')
      window.location.reload()
      return
    }
    setloading(true)
    const response = await dispatch(
      fetchData({
        apiurl: "/coupon/find",
        page: currentpage, // API pages are usually 1-indexed
        limit: limit,
        search: search,
        headers: { "x-access-token": userToken }
      })
    );
    setloading(false)
    if (response && response.payload && response.payload.data && response.payload.status == 200) {
      setCoupons(response.payload.data);
      settotalCoupons(response.payload.count)
      if (response.payload.count > 0 && response.payload.data.length == 0) {
        setCurrentPage(currentPage)
        // getCoupons(currentPage, perPage)
      }
    } else {
      setCoupons([]);
      if (response.payload.response && response.payload.response.data && response.payload.response.data.message) {
        toastify({ message: response.payload.response.data.message })
        if (response.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }

  async function handlePopupCloseDelete() {
    const response = await dispatch(fetchData({
      apiurl: "/coupon/delete",
      _id: selectedCoupon,
      headers: { "x-access-token": userToken }
    }));
    if (response && response.payload && response.payload.status === 200) {
      toastify({ message: response.payload.message });
      setManageCouponStatus(false);
      getCoupons(currentPage, perPage, "");
    }
    else {
      if (response.payload.response && response.payload.response.data && response.payload.response.data.message) {
        toastify({ message: response.payload.response.data.message })
        if (response.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }

  function handleDeleteClick(e) {
    const id = e.target.id.split("_")[1];
    if (id) {
      setSelectedCoupon(id);
      setManageCouponStatus(true);
    }
  }

  function handleEdit(e) {
    const id = e.target.id.split("_")[1];
    if (id) {
      Navigate(`/createcoupon?index=${id}`);
    }
  }

  function handleNewCoupon() {
    Navigate("/createcoupon");
  }

  function convertdate(date) {
    return date.substring(6, 8) + "/" + date.substring(9, 11) + "/" + date.substring(1, 5)
  }

  const handlePageChange = ({ selected }) => {
    console.log(selected)
    setCurrentPage(selected + 1);
    getCoupons(selected + 1, perPage, searchvalue);
  };

  const pageCount = Math.ceil(coupons.length / perPage);

  const inputsearchHandler = (e) => {
    console.log(e.target.name);
    setsearchvalue(e.target.value);
  };

  const handleClearSearch = () => {
    setsearchvalue("");
    setCurrentPage(1)
    getCoupons(1, perPage, "");
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setCurrentPage(1)
    getCoupons(1, perPage, searchvalue);
  };

  const searchfilterhandler = (e) => {
    handleSearch()
  }

  return (
    <div>
      <div className="header-btndiv1">
        <div className="search-div">
          <div className="coupon-wrapper">
            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Coupon Code"
              onKeyDown={handleKeyDown}
              value={searchvalue}
              // onChange={(e) => setSearchInput(e.target.value)}
              onChange={inputsearchHandler}
            />
            {searchvalue && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          {/* <button className="add-btn" onClick={handleSearch}>Search</button> */}
          <button className="add-btn" onClick={searchfilterhandler}>Search</button>
        </div>
        <button className="add-btn" onClick={handleNewCoupon}>
          <AddIcon /> Add New Coupon
        </button>
      </div>
      <div className="add-form managecoupons-table">
        <div className="add-table">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Coupon Code</th>
                <th scope="col">Discount Type</th>
                <th scope="col">Coupon Amount</th>
                <th scope="col">Coupon Expire Date</th>
                <th scope="col">Usage Count</th>
                <th scope="col">Action</th>
              </tr> 
            </thead>
            <tbody style={{ display: coupons && coupons.length && !loading ? 'contents' : 'table-caption' }}>
              {coupons.length && !loading ? coupons.map((coupon, index) => (
                <tr key={index}>
                  <td>{coupon.couponcode}</td>
                  <td>{coupon.discountType}</td>
                  <td>{coupon.discountType == 'percentage' ? '' : '$'}{coupon.couponamount}{coupon.discountType == 'percentage' ? '%' : ''}</td>
                  <td>{coupon.expiryDate ? convertdate(JSON.stringify(coupon.expiryDate)) : ''}</td>
                  <td>{coupon.usagelimit}</td>
                  <td>
                    <span className="action-icons">
                      <div className="manage-editdiv">
                        <button id={"coupon_" + coupon._id} className="manage-editbtn" onClick={handleEdit}>
                          <i className="fa-solid fa-pen"></i>Edit
                        </button>
                      </div>
                      <div className="manage-deletediv" onClick={handleDeleteClick}>
                        <button className="manage-deletebtn" id={"deletecoupon_" + coupon._id}>
                          <i className="fas fa-trash-alt"></i>Delete
                        </button>
                      </div>
                    </span>
                  </td>
                </tr>
              )) : loading ? <p className="header-empty">Loading...</p> : <p className="header-empty">No Coupons found</p>}
            </tbody>
          </table>
        </div>
        {coupons.length ?
          <div className="rowperpage">
            <div className="perpagecount">
              Row Per Page&nbsp;
              <select
                onChange={inputperPage}
                value={perPage}>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={200}>200</option>
              </select>
            </div> <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(totalcoupons / perPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={perPage}
              onPageChange={handlePageChange}
              containerClassName={"pagination custom-pagination"}
              activeClassName={"active"}
            />
          </div> : null}
      </div>

      {manageCouponStatus && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={() => setManageCouponStatus(false)}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure?</h1>
              <p>
                Are you sure you want to delete this coupon? Please note that
                this action cannot be undone.
              </p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={() => setManageCouponStatus(false)}>
                Cancel
              </button>
              <button className="btn2" onClick={handlePopupCloseDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
