import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./sidebar.css";
import AddIcon from "@mui/icons-material/Add";
import logoimg from "../images/dove_blaster 2.png";
import groupimg from "../images/Group.png";
import Locationicon from "../assets/land 1.png";
import Locationiconcolor from "../assets/Layer 1.png";
import Coupon from "../assets/coupon 1.png";
import Layer from "../assets/Layer 2.png";
import Chats from "../assets/chat (1).png";
import Rating from "../assets/rating 1.png";
import Reservation from "../assets/reservation 1.png";
import Areaset from "../assets/Asmanage_areaset 1 1.png";
import Dashboardicon from "../assets/dashboard.svg";
import Huntersicon from "../assets/land.svg";
import Profileimg from "../assets/user.png";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Successicon from "../assets/Check.png";
import Bellicon from "../assets/bell.png";
import Logouticon from "../assets/logout.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toastify, userlogout, clearuser } from "../redux/controller";
import { fetchData } from "../redux/apiSlice";

const drawerWidth = 240;

function opennotification(label) {
  window.location.replace(window.location.origin + label)
}

function Sidebar(props) {
  const initialized = useRef(false)
  const dispatch = useDispatch()
  const [userdata, setuserdata] = useState({});
  const usertoken = useSelector((state) => state.userdata.usertoken);
  const userinfo = useSelector((state) => state.userdata.userdata);
  const [successPopup, setSuccessPopup] = useState(false);
  const [popstatus, setPopStatus] = useState(false);
  const [logoutpopstatus, setLogoutPopStatus] = useState(false);
  const [hunterCount, setHuntersCount] = useState(0);
  const Navigate = useNavigate();
  const Navigatelogout = useNavigate();
  const search = useLocation().search;

  async function opennotificationreservation(label) {
    if (label.type == 'reservation') {

      var userdetail = await dispatch(
        fetchData({
          apiurl: "/reservation/single",
          reservationid: label.selectedid,
          headers: { "x-access-token": usertoken }
        })
      );

      if (userdetail && userdetail.payload && userdetail.payload.status == 200) {
        console.log(userdetail)
        Navigate("/huntersdetails", { state: { id: userdetail.payload.data.userid._id, hunter_name: userdetail.payload.data.userid.firstname + " " + userdetail.payload.data.userid.lastname, email: userdetail.payload.data.userid.email, phone_number: userdetail.payload.data.userid.phonenumber, createdAt: userdetail.payload.data.userid.createdAt } })
      }
    }
  }

  const handlesuccessPopup = () => {
    setSuccessPopup(!successPopup);
  };

  function handleLogout() {
    dispatch(userlogout())
    clearuser()
    Navigatelogout("/");
  }

  function handleLogoutsubmit() {
    setLogoutPopStatus(!logoutpopstatus);
  }

  function handlepopupclose() {
    setLogoutPopStatus(!logoutpopstatus);
  }

  function handleChagepassword() {
    setPopStatus(!popstatus);
  }

  async function handleChagepasswordsubmit() {
    console.log(stepinputField)
    if (stepinputField.newpassword !== stepinputField.confirmpassword) {
      toastify({ message: "Password and Confirm Password do not match!" })
      return
    }
    if (stepinputField.newpassword.length < 8) {

      toastify({ "message": "Password must be at least 8 characters" });
      return
    } else if (stepinputField.newpassword.search(/[a-z]/) < 0) {

      toastify({ "message": "Password must contain at least one lowercase letter" });
      return
    } else if (stepinputField.newpassword.search(/[A-Z]/) < 0) {

      toastify({ "message": "Password must contain at least one uppercase letter" });
      return
    } else if (stepinputField.newpassword.search(/[0-9]/) < 0) {

      toastify({ "message": "Password must contain at least one number" });
      return
    }
    var body = {
      "apiurl": "/auth/change/password",
      "password": stepinputField.currentpassword,
      "newpassword": stepinputField.newpassword,
      headers: { "x-access-token": usertoken }
    }
    var response = await dispatch(fetchData(body))
    if (response && response.payload && response.payload.status === 200) {
      toastify({ message: response.payload.message })
      setPopStatus(!popstatus);
      setSuccessPopup(true);
    }
    else {
      if (response.payload.response && response.payload.response.data && response.payload.response.data.message) {
        toastify({ message: response.payload.response.data.message })
        if (response.payload.response.data.message === "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }

  async function closeallnotification() {
    if (readnotifications) {
      closenotification('property')
      closenotification('reservation')
    }
  }

  async function closenotification(type) {
    var body = {
      "apiurl": "/auth/user/closenotification",
      "type": type,
      headers: { "x-access-token": usertoken }
    }
    await dispatch(fetchData(body))
    getprofile()
  }

  async function getprofile() {
    setpropertynotification(0)
    setreservationnotification(0)
    var body = {
      "apiurl": "/auth/profile",
      headers: { "x-access-token": usertoken }
    }
    var response = await dispatch(fetchData(body))
    if (response.payload && response.payload.data && response.payload.data.user && response.payload.data.user.notifications) {
      var notifications = response.payload.data.user.notifications
      console.log(notifications)
      if (notifications && notifications.length) {
        var reservnotify = notifications.filter(e => e.type === 'reservation')
        if (reservnotify.length) {
          setreservationnotification(reservnotify.length)
        }
        var propnotify = notifications.filter(e => e.type === 'property')
        if (propnotify.length) {
          setpropertynotification(propnotify.length)
        }
        var allnotifications = notifications.filter(e => e.type === 'property' || e.type === 'reservation')
        console.log(allnotifications)
        if (allnotifications) {
          setnotifications(allnotifications.reverse())
          setreadnotifications(allnotifications.filter(e => e.read === false))
        }
      }
    }
  }

  function handleChagepasswordclose() {
    setPopStatus(!popstatus);
  }

  const [currentpage, setcurrentpage] = useState("");
  const [reservationName, setReservationName] = useState("");

  const location = useLocation();
  useEffect(() => {
    if (userinfo) {
      setuserdata(userinfo);
    }

    const url = location.search;
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    const reservationName = params.get("reservationName");
    setReservationName(reservationName);

    setcurrentpage(location.pathname);
    if (usertoken == "" || usertoken == undefined || usertoken == null) {
      Navigate("/");
    }


    if (!initialized.current) {
      initialized.current = true
      getprofile()
    }
  });


  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activesubMenu, setActivesubMenu] = useState(null);
  const manage_area = useNavigate();
  const manage_navigate = useNavigate();
  const managecoupon_navigate = useNavigate();
  const hunter_navigate = useNavigate();
  const real_navigate = useNavigate();
  const chats_navigate = useNavigate();
  const reservations_navigate = useNavigate();
  const changepassword_navigate = useNavigate();
  const drop_navigate = useNavigate();
  const newcoupon_navigate = useNavigate();
  const hunterback_navigate = useNavigate();
  const createcoupon_navigate = useNavigate();
  const property_navigate = useNavigate();
  const realtrack_navigate = useNavigate();

  const [propertynotification, setpropertynotification] = useState(0);
  const [reservationnotification, setreservationnotification] = useState(0);
  const [notifications, setnotifications] = useState([]);
  const [readnotifications, setreadnotifications] = useState([]);
  const [currentpasswordicon, setcurrentpasswordicon] = useState(false);
  const [passwordicon, setpasswordicon] = useState(false);
  const [cpasswordicon, setcpasswordicon] = useState(false);


  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstepInputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const [stepinputField, setstepInputField] = useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: ""
  })

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleClick = (text) => {
    console.log(text, "text");
    setActiveMenu(text === activeMenu ? null : text);
    console.log(`activeMenu ${text} clicked`);
    if (text == 'Reservations') {
      closenotification('reservation')
    }
  };

  const handleSubmenuClick = (text) => {
    setActivesubMenu(text === activesubMenu ? null : text);
    console.log(`Submenu ${text} clicked`);
    if (text === "Manage Areas/Slots") {
      closenotification('property')
      manage_area("/managearea");
    } else {
      manage_navigate("/addproperty");
    }
  };

  function handleManageproperties() {
    manage_navigate("/addproperty");
  }

  function handleManage() {
    managecoupon_navigate("/managecoupons");
  }

  function handleHunter() {
    hunter_navigate("/huntersreview");
  }
  function handleRealtracking() {
    real_navigate("/realtracking");
  }
  function handleChats() {
    chats_navigate("/chats");
  }
  function handleReservations() {
    reservations_navigate("/reservations");
  }
  function handleCreatecouponback() {
    createcoupon_navigate("/managecoupons");
  }
  function handlePropertyback() {
    // property_navigate("/reservations");
    Navigate(-1)
  }
  function handleRealtrackback() {
    realtrack_navigate("/realtracking");
  }
  function handleDrop() {
    // drop_navigate("/profile");
  }
  // function handleNewcoupon(){
  //   newcoupon_navigate("/createcoupon");
  // }

  // function handleChagepassword(event) {
  //   event.preventDefault();
  //   // changepassword_navigate("/change_pass", { someData: "yourDataHere" });
  // }

  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const currenthandleType = (e) => {
    // if (e.target.parentElement.parentNode.getElementsByTagName('input')[0].type == 'text') {
    //   e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'password'
    //   setcurrentpasswordicon(false)
    // }
    // else {
    //   e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'text'
    //   setcurrentpasswordicon(true)
    // }
    currentPasswordRef.current.type = currentPasswordRef.current.type === 'text' ? 'password' : 'text';
    setcurrentpasswordicon(!currentpasswordicon);
  }

  const handleType = (e) => {
    // if (e.target.parentElement.parentNode.getElementsByTagName('input')[0].type == 'text') {
    //   e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'password'
    //   setpasswordicon(false)
    // }
    // else {
    //   e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'text'
    //   setpasswordicon(true)
    // }
    newPasswordRef.current.type = newPasswordRef.current.type === 'text' ? 'password' : 'text';
    setpasswordicon(!currentpasswordicon);
  }

  const chandleType = (e) => {
    // if (e.target.parentElement.parentNode.getElementsByTagName('input')[0].type == 'text') {
    //   e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'password'
    //   setcpasswordicon(false)
    // }
    // else {
    //   e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'text'
    //   setcpasswordicon(true)
    // }
    confirmPasswordRef.current.type = confirmPasswordRef.current.type === 'text' ? 'password' : 'text';
    setcpasswordicon(!currentpasswordicon);
  }

  function handleReservationBack() {
    hunterback_navigate("/reservations");
  }


  async function updateNotificationCount(type) {
    var data = await dispatch(
      fetchData({
        apiurl: "/auth/user/updatenotificationcount",
        headers: { "x-access-token": usertoken },
        type: type,
        userid: userdata._id
      })
    );
    if (data && data.payload && data.payload.status === 200) {
      if (type == "all") {
        // setNotificationsCount(0)
      } else
        if (type === "hunter") {
          setHuntersCount(0)
        } else if (type === "landowner") {
          // setLandownerCount(0)
        } else if (type === "property") {
          // setPropertyCount(0)
        } else if (type === "reservation") {
          // setReservationCount(0)
        }
    }
  }

  function handleHunters() {
    updateNotificationCount("hunter")
    Navigate("/hunters");
  }


  const drawer =
    (
      (
        <div>
          <div className="logo-buttom">
            <img className="logoimg" src={groupimg} alt="logoimg" />
          </div>
          <List>
            <React.Fragment>
              <ListItem disablePadding onClick={handleManageproperties}>
                <ListItemButton
                  onClick={() => handleClick("Manage Properties")}
                  id="firstmenu"
                  className={
                    activeMenu === "Manage Properties"
                      ? "activeMenu"
                      : "inheritmenu"
                  }
                >
                  <ListItemIcon>
                    <img
                      className={
                        activeMenu === "Manage Properties" ? "loc-img" : "cou-img"
                      }
                      src={
                        activeMenu === "Manage Properties"
                          ? Locationicon
                          : Locationiconcolor
                      }
                      alt="Locationicon"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Properties"
                    className={
                      activeMenu === "Manage Properties"
                        ? "activeMenu"
                        : "inheritmenu"
                    }
                  />
                  {/* {propertynotification>0?
                <Badge badgeContent={propertynotification} sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#be7637',
                    color: 'white',
                    marginRight: '20px'
                  }
                }} />
                :null} */}
                  {activeMenu === "Manage Properties" ? (
                    <ExpandLess className="arrow" />
                  ) : (
                    <ExpandMore className="arrow" />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse
                in={activeMenu === "Manage Properties"}
                timeout="auto"
                unmountOnExit
                id="submenu"
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    className={
                      activesubMenu === "Add New Property"
                        ? "activeSubmenuItem"
                        : "inactiveSubmenuItem"
                    }
                    onClick={() => handleSubmenuClick("Add New Property")}
                  >
                    <ListItemIcon>
                      <AddIcon className="addicon" />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      primary="Add New Property"
                      className="color-text"
                    />
                  </ListItemButton>
                  <ListItemButton
                    className={
                      activesubMenu === "Manage Areas/Slots"
                        ? "activeSubmenuItem"
                        : "inactiveSubmenuItem"
                    }
                    onClick={() => handleSubmenuClick("Manage Areas/Slots")}
                  >
                    <ListItemIcon>
                      <img className="cou-img" src={Areaset} alt="Areaset" />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      className="color-text"
                      primary="Manage Areas/Slots"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </React.Fragment>
          </List>

          <List>
            <ListItem disablePadding onClick={handleReservations}>
              <ListItemButton
                onClick={() => handleClick("Reservations")}
                className={
                  activeMenu === "Reservations" ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img className="cou-img" src={Reservation} alt="Reservation" />
                </ListItemIcon>
                <ListItemText
                  primary="Reservations"
                  className={
                    activeMenu === "Reservations" ? "activeMenu" : "inheritmenu"
                  }
                />
                {/* {reservationnotification>0?
                <Badge badgeContent={reservationnotification} sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#be7637',
                    color: 'white',
                    marginRight: '20px'
                  }
                }} />
                :null} */}
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding onClick={handleManage}>
              <ListItemButton
                onClick={() => handleClick("Manage Coupons")}
                className={
                  activeMenu === "Manage Coupons" ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img className="cou-img" src={Coupon} alt="Coupon" />
                </ListItemIcon>
                <ListItemText
                  primary="Manage Coupons"
                  className={
                    activeMenu === "Manage Coupons" ? "activeMenu" : "inheritmenu"
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />

          <List>

            <ListItem disablePadding onClick={handleHunters}>
              <ListItemButton
                onClick={() => handleClick("Hunters")}
                className={
                  activeMenu === "Hunters" ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img className="cou-img" src={Huntersicon} alt="Huntersicon" />
                </ListItemIcon>
                <ListItemText
                  primary="Hunters"
                  className={
                    activeMenu === "Hunters" ? "activeMenu" : "inheritmenu"
                  }
                />
                {/* <Badge badgeContent={hunterCount} sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#be7637',
                    color: 'white',
                    marginRight: '20px'
                  }
                }} /> */}

              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding onClick={handleHunter}>
              <ListItemButton
                onClick={() => handleClick("Hunters Review")}
                className={
                  activeMenu === "Hunters Review" ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img className="cou-img" src={Rating} alt="Rating" />
                </ListItemIcon>
                <ListItemText
                  primary="Hunters Review"
                  className={
                    activeMenu === "Hunters Review" ? "activeMenu" : "inheritmenu"
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding onClick={handleRealtracking}>
              <ListItemButton
                onClick={() => handleClick("Real Time Tracking")}
                className={
                  activeMenu === "Real Time Tracking"
                    ? "activeMenu"
                    : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img className="cou-img" src={Layer} alt="Layer" />
                </ListItemIcon>
                <ListItemText
                  primary="Real Time Tracking"
                  className={
                    activeMenu === "Real Time Tracking"
                      ? "activeMenu"
                      : "inheritmenu"
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          {/* <List>
            <ListItem disablePadding onClick={handleChats}>
              <ListItemButton
                onClick={() => handleClick("Chats")}
                className={
                  activeMenu === "Chats"
                    ? "activeMenu"
                    : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img className="cou-img" src={Chats} alt="Layer" />
                </ListItemIcon>
                <ListItemText
                  primary="Chats"
                  className={
                    activeMenu === "Chats"
                      ? "activeMenu"
                      : "inheritmenu"
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider /> */}
        </div>
      ));

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` }
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            {currentpage == "/managearea" ? (
              <div className="header-btndiv">
                <h1>Manage Properties</h1>
              </div>
            ) : null}
            {currentpage == "/managecoupons" ? (
              <div className="header-btndiv">
                <h1>Manage Coupons</h1>
              </div>
            ) : null}


            {currentpage.startsWith("/reservationdetails") ? (
              <div className="header-btndiv">
                <div className="header-btnicon" onClick={handleReservationBack}>
                  <ArrowBackIcon />
                </div>
                <h1>Reservation Details</h1>
              </div>
            ) : null}

            {currentpage == "/realtracking" ? (
              <div className="header-btndiv">
                <h1>Real Time Tracking</h1>
              </div>
            ) : null}

            {currentpage == "/hunters" ? (
              <div className="header-btndiv">
                <h1>Hunters </h1>
              </div>
            ) : null}

            {currentpage == "/huntersreview" ? (
              <div className="header-btndiv">
                <h1>Hunters Review</h1>
              </div>
            ) : null}

            {currentpage == "/reservations" ? (
              <div className="header-btndiv">
                <h1>Reservations</h1>
              </div>
            ) : null}

            {currentpage == "/slotproperty" ? (
              <div className="header-btndiv">
                <h1>Slots on Property</h1>
              </div>
            ) : null}

            {currentpage == "/chats" ? (
              <div className="header-btndiv">
                <h1>Chats</h1>
              </div>
            ) : null}

            {currentpage == "/propertydetailsmap" || currentpage == "/huntersdetails"? (
              <div className="header-btnicon" onClick={handlePropertyback}>
                <ArrowBackIcon />
              </div>
            ) : null}

            {currentpage == "/createcoupon" ? (
              <div className="header-btnicon" onClick={handleCreatecouponback}>
                <ArrowBackIcon />
              </div>
            ) : null}

            {currentpage == "/propertydetails" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handlePropertyback}>
                  <ArrowBackIcon />
                </div>
                <h1>Property Details</h1>
              </div>
            ) : null}

            {currentpage == "/upcomingreservations" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handlePropertyback}>
                  <ArrowBackIcon />
                </div>
                <h1>{reservationName}</h1>
              </div>
            ) : null}

            {currentpage == "/reservetonsidhistoryid" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handlePropertyback}>
                  <ArrowBackIcon />
                </div>
                <h1>{reservationName}</h1>
              </div>
            ) : null}

            {currentpage == "/realtrackingdetails" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handleRealtrackback}>
                  <ArrowBackIcon />
                </div>
                <h1>Real Time Tracking</h1>
              </div>
            ) : null}

            {/* <div className="header-btndiv">
              <button onClick={handleNewcoupon}>
                <AddIcon /> Add New Coupon
              </button>
            </div> */}
            <div
              className="btn-secondary dropdown-toggle top-main belldiv"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={closeallnotification}>
              <img src={Bellicon} alt="Bellicon" />
              {readnotifications.length > 0 ? <p className="bell_notification">{readnotifications.length}</p> : null}
            </div>
            {notifications.length ? <ul className="dropdown-menu bell_drop">

              <h4 className="notification-title">Notifications</h4>
              {notifications.map((label, index) => {
                return (<li>
                  {/* <Link className="dropdown-item" to={label.type == 'property' ? "/managearea" : "/reservations"}> */}
                  <div className="dropdown-item" onClick={() => label.type == 'property' ? opennotification('/propertyreview?index=' + label.selectedid) : opennotificationreservation(label)}>
                    {label.msg}
                  </div>
                </li>
                )
              }
              )}
            </ul> :
              <ul className="dropdown-menu bell_drop">
                <h4 className="notification-title">Notifications</h4>
                <p>&nbsp;No data!</p>
              </ul>}
            <div
              className=" btn-secondary dropdown-toggle top-main"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={handleDrop}
            >
              <div className="top-image">
                {userdata.image ? <img className="Profileimg" src={process.env.REACT_APP_BASEURL + "/" + userdata.image} alt="Profileimg" /> : <img className="Profileimg" src={Profileimg} alt="Profileimg" />}
              </div>
              <div className="top-midtext">
                <p>{userdata.email}</p>
                <p>
                  {userdata.firstname} {userdata.lastname}
                </p>
              </div>
              <div className="top-icon">
                <KeyboardArrowDownIcon />
              </div>
            </div>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/profile">
                  My Profile
                </Link>
              </li>
              <li>
                <div
                  className="dropdown-item make_link"
                  //  to="/change_pass?popstatus=true"
                  onClick={handleChagepassword}
                >
                  Change Password
                </div>
              </li>
              <li>
                <div className="dropdown-item make_link" onClick={handleLogoutsubmit}>
                  Logout
                </div>
              </li>
            </ul>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` }
          }}
        >
          <Toolbar />
          {/* <div className="step-div">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </div> */}

          {props.children}
        </Box>
      </Box>

      {popstatus && (
        <div className="chagepassword-popup">
          <div className="chagepassword-content">
            <i
              className="fa-solid fa-xmark"
              onClick={handleChagepasswordclose}
            ></i>

            <div className="chagepass-popupcontent">
              <h1>Change Password</h1>
              <p>To change your password, please fill the fields below.</p>

              <div className="form-row">
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Current Password"
                    name="currentpassword"
                    onChange={inputsHandler}
                    value={stepinputField.currentpassword}
                    ref={currentPasswordRef}
                  />
                  <button
                    type="button"
                    className="eye-icon btn btn-outline-secondary"
                    onClick={currenthandleType}
                  >{currentpasswordicon ? <i className="fa fa-eye" aria-hidden="true"></i> :
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                  </button>
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="New Password"
                    name="newpassword"
                    onChange={inputsHandler}
                    value={stepinputField.newpassword}
                    ref={newPasswordRef}
                  />
                  <button
                    type="button"
                    className="eye-icon btn btn-outline-secondary"
                    onClick={handleType}
                  >
                    {passwordicon ? <i className="fa fa-eye" aria-hidden="true"></i> :
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                  </button>
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Confirm New Password"
                    name="confirmpassword"
                    onChange={inputsHandler}
                    value={stepinputField.confirmpassword}
                    ref={confirmPasswordRef}
                  />
                  <button
                    type="button"
                    className="eye-icon btn btn-outline-secondary"
                    onClick={chandleType}
                  >
                    {cpasswordicon ? <i className="fa fa-eye" aria-hidden="true"></i> :
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                  </button>
                </div>
              </div>
              <div className="form-row w-100">
                <button
                  type="submit"
                  className="users_btn change-passbtn"
                  onClick={handleChagepasswordsubmit}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {successPopup && (
        <div className="chagesuccess-popup">
          <div className="chagesuccess-content">
            <i className="fa-solid fa-xmark" onClick={handlesuccessPopup}></i>
            <div className="chagesuccess-contentdiv">
              <img src={Successicon} alt="Successicon" />
              <h1>Password Changed</h1>
              <h1> Successfully </h1>
            </div>
          </div>
        </div>
      )}

      {logoutpopstatus && (
        <div className="logout-popup">
          <div className="logout-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="logout-contentdiv">
              <img src={Logouticon} alt="Logouticon" />
              <h1>Are you Sure?</h1>
              <p>Are you absolutely sure you want to Logout?</p>
            </div>
            <div className="logout-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
  children: PropTypes.node
};

export default Sidebar;
