import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout, clearuser } from "../redux/controller";
import { db } from "../redux/firebase";
import {
  collection, query, onSnapshot
} from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

export default function RealtrackingDetails() {
  const messaging = getMessaging();

  var map;
  var draw;
  var alltokens
  const initialized = useRef(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const usertoken = useSelector((state) => state.userdata.usertoken);
  const propertyid = queryParams.get("property");
  const [property, setProperty] = useState({});
  const userlist = location.state.users;
  const usersCollectionRef = query(collection(db, "users_location"));
  const [mapObject, setMap] = useState();
  const [mapObjectDraw, setMapDraw] = useState();
  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);
  const navigate = useNavigate();
  const [realpopup, setRealpopup] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [selectedhunter, setselectedhunter] = useState({});
  const [selectedhuntertoken, setselectedhuntertoken] = useState("");
  const [allhunterstoken, setallhunterstoken] = useState("");
  
  const [stepinputField, setstepInputField] = useState({
    description: ""
  });

  const [setStepAllDescription, setStepAll] = useState({
    description: ""
  });


  const [allHunters, setAllHunters] = useState([]);
  const dispatch = useDispatch();
  const [selectedmapstyles, setselectedmapstyles] = useState("mapbox://styles/mapbox/satellite-streets-v12");

  const handleClosepopup = () => {
    setRealpopup(false);
    setPopUp(false)
  };

  const handlepopup = () => {
    setRealpopup(true);
  };

  const handleSend = () => {
    sendNotificationToHunter(selectedhuntertoken, stepinputField.description, false);
  };

  const handleSendToAll = () => {
    console.log(allhunterstoken)
    console.log(alltokens)
    setallhunterstoken(alltokens)
    setPopUp(true)
  };

  const sendMessageToAll = () => {
    console.log(allhunterstoken)
    console.log(alltokens)
    for(var x=0; x<allhunterstoken.length; x++){
      if(allhunterstoken.length-1 == x){
        sendNotificationToHunter(allhunterstoken[x].firebase_token, setStepAllDescription.description, 'all');
      }
      else{
        sendNotificationToHunter(allhunterstoken[x].firebase_token, setStepAllDescription.description, '');
      }
    }
  };

  const sendNotificationToHunter = async (udid, message, all) => {
    if (message) {
      var response = await dispatch(fetchData({
        apiurl: "/reservation/send/notification",
        messagingtoken: udid,
        message,
        propertyname: property.title,
        type: "FromTracking",
        headers: { "x-access-token": usertoken }
      }));
      setstepInputField({ description: "" });
      setStepAll({ description: "" });
      if(all == "all"){
        toastify({message: "Notification sent to all hunters successfully"})
        handleClosepopup();
        return
      }
      if (response.payload && response.payload.status === 200) {
          toastify({ message: response.payload.message });
      } else {
          toastify({ message: response.payload.message });
          if (response.payload.response.data.message === "Invalid token provided!") {
            clearuser();
            userlogout();
            navigate('/');
            window.location.reload();
          }
      }
      handleClosepopup();
    }
  };


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchBlogs();
    }
  }, []);

  const fetchBlogs = async () => {
    var body = {
      "apiurl": "/property/single",
      "propertyid": propertyid,
      "convert": true,
      headers: { "x-access-token": usertoken }
    };
    var propertys = await dispatch(fetchData(body));
    if (propertys.payload && propertys.payload.status === 200) {
      setProperty(propertys.payload.data);
      setAllHunters(location.state.users);

      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: selectedmapstyles,
        center: propertys.payload.data.latlng.lat ? [propertys.payload.data.latlng.lng, propertys.payload.data.latlng.lat] : [0, 0],
        zoom: propertys.payload.data.zoomlevel || 10
      });

      draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: {
          ...MapboxDraw.modes
        },
        defaultMode: 'draw_polygon',
        styles: [
          // Default style for polygon
          {
            "id": "gl-draw-polygon-fill-inactive",
            "type": "fill",
            "paint": {
              "fill-color": propertys.payload.data.propertycolor,
              "fill-outline-color": propertys.payload.data.propertycolor,
              "fill-opacity": 0.1
            }
          },
          // Default style for polygon outline
          {
            "id": "gl-draw-polygon-stroke-inactive",
            "type": "line",
            "paint": {
              "line-color": propertys.payload.data.propertycolor,
              "line-dasharray": [2, 2],
              "line-width": 2,
              "line-opacity": 0.7
            }
          }
        ]
      });

      if (propertys.payload.data.geodraw && propertys.payload.data.geodraw.coords) {
        map.on('load', () => {
          addslotsmarker(map, propertys);
          // Add custom control (button) after map is loaded
          map.addControl(new SendNotificationButtonControl(), 'top-right');
        });
      }

      setMap(map);
      setMapDraw(draw);
      return () => {
        map.remove();
        drawRef.current = null;
      };
    }
  };


  async function addslotsmarker(map, propertys) {
    try {
      const unsubscribe = onSnapshot(usersCollectionRef, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        var alltoken = []
        // console.log(userlist)
        // console.log(users)
        for (var x = 0; x < userlist.length; x++) {
          if(userlist[x] && userlist[x]._id){ 
            var trackuser = users.findIndex(e => e.user_id === userlist[x]._id && e.reservation_id == userlist[x].reservationid);

            const matchedUsers = userlist.filter(user =>
              users.some(listItem => listItem.user_id === user._id && listItem.reservation_id == user.reservationid)
            );

            if (trackuser > -1) {           
              // console.log(users)
              // console.log(userlist)
              // console.log(trackuser)
              alltoken.push(users[trackuser])
              var layerindex = -1;
              var sourceindex = -1;
              if (map && map.getStyle().layers.length) {
                for (var xx = 0; xx < map.getStyle().layers.length; xx++) {
                  if (map.getStyle().layers[xx].id === "markers" + x) {
                    layerindex = xx;
                  }
                }
                if (map.getStyle().sources['markers' + x]) {
                  sourceindex = 0;
                }
              }
              if (sourceindex > -1) {
                map.getSource('markers' + x).setData({
                  type: 'FeatureCollection',
                  features: [{
                    "type": "Feature",
                    "geometry": {
                      "type": "Point",
                      "coordinates": [users[trackuser].longitude, users[trackuser].latitude]
                    },
                    "properties": {
                      "user": JSON.stringify(userlist[x]),
                      "user_token": users[trackuser].firebase_token
                    }
                  }]
                });
              } else {
                map.addSource('markers' + x, {
                  "type": "geojson",
                  "data": {
                    "type": "FeatureCollection",
                    "features": [{
                      "type": "Feature",
                      "geometry": {
                        "type": "Point",
                        "coordinates": [users[trackuser].longitude, users[trackuser].latitude]
                      },
                      "properties": {
                        "user": JSON.stringify(userlist[x]),
                        "user_token": users[trackuser].firebase_token
                      }
                    }]
                  }
                });
              }
              if (layerindex === -1) {
                map.addLayer({
                  "id": "markers" + x,
                  "source": "markers" + x,
                  "type": "circle",
                  "paint": {
                    "circle-radius": 10,
                    "circle-color": "#be7637"
                  }
                });
              }
              map.on('click', "markers" + x, (e) => {
                handlepopup();
                console.log(e.features[0].properties.user)
                setselectedhunter(JSON.parse(e.features[0].properties.user));
                setselectedhuntertoken(e.features[0].properties.user_token);
              });
            }
          }
        }
        setallhunterstoken(alltoken)
        alltokens = alltoken
      });
      map.addSource('maine', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': propertys.payload.data.geodraw.coords.paths
          }
        }
      });
      map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'maine',
        'layout': {},
        'paint': {
          'line-color': propertys.payload.data.propertycolor,
          'line-width': 3
        }
      });

      for (var y = 0; y < propertys.payload.data.area.length; y++) {
        if (propertys.payload.data.area[y].slots && propertys.payload.data.area[y].slots.length) {
          for (var x = 0; x < propertys.payload.data.area[y].slots.length; x++) {
            if (propertys.payload.data.area[y].slots[x] && propertys.payload.data.area[y].slots[x].name) {
              map.addSource('maine' + y + x, {
                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Polygon',
                    'coordinates': propertys.payload.data.area[y].slots[x].coords.paths
                  },
                  'properties': {
                    'title': 'A' + y + "-" + x
                  }
                }
              });
              map.addLayer({
                'id': 'outline' + y + x,
                'type': 'line',
                'source': 'maine' + y + x,
                'layout': {},
                'paint': {
                  'line-color': propertys.payload.data.area[y].areacolor,
                  'line-width': 3
                }
              });
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const inputsHandler = (e) => {
    setstepInputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const inputsHandlerAll = (e) => {
    setStepAll((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  class SendNotificationButtonControl {
    onAdd(map) {
      this._map = map;
      this._container = document.createElement('div');
      this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
      this._container.innerHTML = '<button class="send-all-btn">Send Notification to All Hunters</button>';
      this._container.onclick = handleSendToAll;
      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  return (
    <>
      <div className="realdetails-div">
        <div className="map-div slot-prodiv">
          <div
            ref={mapContainerRef}
            style={{ width: "100%", height: "803px", borderRadius: "10px" }}
          />
        </div>
      </div>

      {popup && (
        <div className="realdetails-popup">
          <div className="realdetails-content-all ">
            <div className="realdetails-top">
              <h1>Send notification to all hunters</h1>
              <i className="fa-solid fa-xmark" onClick={handleClosepopup}></i>
            </div>
            <div className="real-areadiv">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Message
              </label>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="description"
                  onChange={inputsHandlerAll}
                  value={setStepAllDescription.description}
                ></textarea>
                <label htmlFor="floatingTextarea2">Send Message...</label>
              </div>
            </div>
            <div className="realsave-btndiv">
              <button className="add-btn" onClick={sendMessageToAll}>Send</button>
            </div>
          </div>
        </div>
      )}

      {realpopup && (
        <div className="realdetails-popup">
          <div className="realdetails-content ">
            <div className="realdetails-top">
              <h1>Hunter Details</h1>
              <i className="fa-solid fa-xmark" onClick={handleClosepopup}></i>
            </div>

            <div className="realdetails-contentmain">
              <div className="realdetails-contentdiv1">
                <p className="th-p">Hunter Name</p>
                <p className="td-p">{selectedhunter.firstname} {selectedhunter.lastname}</p>
              </div>

              <div className="realdetails-contentdiv1">
                <p className="th-p">Email</p>
                <p className="td-p">{selectedhunter.email}</p>
              </div>

              <div className="realdetails-contentdiv1">
                <p className="th-p">Phone No</p>
                <p className="td-p">{selectedhunter.phonenumber}</p>
              </div>
            </div>
            <div className="real-areadiv">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Message
              </label>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="description"
                  onChange={inputsHandler}
                  value={stepinputField.description}
                ></textarea>
                <label htmlFor="floatingTextarea2">Send Message...</label>
              </div>
            </div>
            <div className="realsave-btndiv">
              <button className="add-btn" onClick={handleSend}>Send</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
