import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { area } from "@turf/turf";
import "./style.css";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import Deleteicon from "../assets/trash.png";
import Editicon from "../assets/pencil.png";
import Vectoricon from "../assets/Vector.png";
import Deletesloticon from "../assets/Trash can.svg";
// import colorNames from "color-names";
import { FaTimes } from 'react-icons/fa';
import Questionicon from "../assets/Info circle.svg"
import Editpencilicon from "../assets/edit.svg"
import * as turf from "@turf/turf";
import { toastify, userlogout, clearuser } from "../redux/controller";
import Xicon from "../assets/Delete Icon.svg"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX
  

export default function Propertyreview() {
  const initialized = useRef(false)
  var map
  var draw
  const dispatch = useDispatch()
  const search = useLocation().search;
  const propertyid = new URLSearchParams(search).get("index");
  const Navigate = useNavigate();
  const Navigate1 = useNavigate();
  const Navigateslot = useNavigate();
  const [activeTab, setActiveTab] = useState([]);
  const Navigategoback = useNavigate();
  const [editslotinf, setEditslotinf] = useState(false);
  const [disableslotinput, setdisableslotinput] = useState(false);
  const [numSlots, setNumSlots] = useState(2);
  const [colorStore, setColorStore] = useState("#B87333");
  var selectarea = {}
  const [propertyselectedarea, setpropertyselectedarea] = useState({});
  const [propertyselectedareas, setpropertyselectedareas] = useState([]);
  const [propertyselectedareascopy, setpropertyselectedareascopy] = useState([]);
  const [mapObject, setMap] = useState();
  const [mapObjectDraw, setMapDraw] = useState();
  const [activeStep, setActiveStep] = useState(0);

  // Set initial color to copper
  const [selectedColor, setSelectedColor] = useState("#B87333");
  const [selectedColorName, setSelectedColorName] = useState("Copper");
  const [propertydata, setpropertydata] = useState("Copper");
  const [property, setproperty] = useState({});
  const [propertyareas, setpropertyareas] = useState({});

  const [propertyselectedareaindex, setpropertyselectedareaindex] = useState(0);
  const [propertyselectedslotindex, setpropertyselectedslotindex] = useState(0);

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate());
    return tomorrow.toISOString().split("T")[0];
  };
  const [mindate, setmindate] = useState(getTomorrowDate());

  const [deletearea, setdeletearea] = useState('');
  const [deleteavailability, setdeleteavailability] = useState('');
  
  const steps = [
    {
      name: "Map",
      id: "fullmap"
    },
    { 
      name: "Property Details",
      id: "full_property_detail"
    },
    { 
      name:"Areas & Slots",
      id: "full_areas_slots"
    },
    {
      name: "Upload Media",
      id: "full_upload_media"
    }
  ];

  // Function to handle color change
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
  };

  // Function to handle update of area
  const updateArea = (e) => {
    console.log("hello")
    console.log(selectarea)
    // // Your update area logic here
    const data = draw.getAll();
    console.log(data)
    if (data.features.length > 0) {
      const area = turf.area(data);
      var areainacres = ((area * 100) / 100) / 4046.86
      const rounded_area = areainacres.toFixed(2);
      setpropertyselectedareas(prevState => ([...prevState, {
        "name": `A1-${prevState.length + 1}`,
        "size": rounded_area,
        "unit": "Acres",
        "type": e.features[0].geometry.type,
        "coords": {
          "paths": e.features[0].geometry.coordinates
        }
      }]
      ))
      setpropertyselectedareascopy(prevState => ([...prevState, {
        "name": e.features
      }]
      ))
      console.log(propertyselectedarea)
      console.log(e.features[0].geometry.coordinates)

    };
  }

  const [step1inputField, setstep1InputField] = useState({
    title: "",
    category: "",
    lease: "",
    city: "",
    state: "",
    county: "",
    country: "",
    zipcode: "",
    description: "",
    areaaddress: "",
    features: []
  });

  const [step3inputField, setstep3InputField] = useState({
    videolink: "",
    images: []
  });
  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  const inputsHandler3 = (e) => {
    console.log(e.target.name);
    setstep3InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleAddSlot = () => {
    setNumSlots(numSlots + 1);
  };

  const usertoken = useSelector((state) => state.userdata.usertoken);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      if (propertyid) {
        getproperty(true)
      }
    }
  }, []);
  const getproperty = async (refresh) => {
    if (usertoken == null || usertoken.length < 1) {
      clearuser()
      userlogout()
      Navigate('/')
      window.location.reload()
      return
    }
    var propertylist = await dispatch(
      fetchData({
        apiurl: "/property/single",
        propertyid: propertyid,
        headers: { "x-access-token": usertoken }
      })
    );

    if (propertylist && propertylist.payload && propertylist.payload.status == 200) {
      if (
        propertylist &&
        propertylist.payload &&
        propertylist.payload.data
      ) {
        setstep1InputField(prevState => ({ ...prevState, ['title']: propertylist.payload.data.title }))
        setstep1InputField(prevState => ({ ...prevState, ['category']: propertylist.payload.data.category }))
        setstep1InputField(prevState => ({ ...prevState, ['country']: propertylist.payload.data.country }))
        setstep1InputField(prevState => ({ ...prevState, ['city']: propertylist.payload.data.city }))
        setstep1InputField(prevState => ({ ...prevState, ['state']: propertylist.payload.data.state }))
        setstep1InputField(prevState => ({ ...prevState, ['zipcode']: propertylist.payload.data.zipcode }))
        setstep1InputField(prevState => ({ ...prevState, ['propertynotification']: propertylist.payload.data.propertynotification }))
        setstep1InputField(prevState => ({ ...prevState, ['totalarea']: propertylist.payload.data.totalarea }))
        setstep1InputField(prevState => ({ ...prevState, ['areaaddress']: propertylist.payload.data.areaaddress }))
        setproperty(propertylist.payload.data)
        if (refresh) {
          setpropertyareas(propertylist.payload.data.area)
        }
        if (propertylist.payload.data.area && propertylist.payload.data.area.length) {
          for (var x = 0; x < propertylist.payload.data.area.length; x++) {
            setActiveTab((prevState) => ([
              ...prevState, "unit"
            ]));
          }
        }

        setstep3InputField((prevState) => ({
          ...prevState,
          ["videolink"]: propertylist.payload.data.videolink
        }));

        console.log("new mapboxgl.Map")
        if (!mapObject) {

          const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
            style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
            center: [propertylist.payload.data.latlng ? propertylist.payload.data.latlng.lng : 0, propertylist.payload.data.latlng ? propertylist.payload.data.latlng.lat : 0],
            zoom: parseFloat(propertylist.payload.data.zoomlevel ?? 10)
          });
          if (propertylist.payload.data.geodraw) {

            map.on('load', () => {
              // Add a data source containing GeoJSON data.
              map.addSource('maine', {
                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Polygon',
                    // These coordinates outline Maine.
                    'coordinates': propertylist.payload.data.geodraw.coords.paths
                  }
                }
              });
              map.addLayer({
                'id': 'outline',
                'type': 'line',
                'source': 'maine',
                'layout': {},
                'paint': {
                  'line-color': propertylist.payload.data.propertycolor,
                  'line-width': 3
                }
              });
              if (propertylist.payload.data.area && propertylist.payload.data.area.length) {
                for (var x = 0; x < propertylist.payload.data.area.length; x++) {
                  for (var y = 0; y < propertylist.payload.data.area[x].slots.length; y++) {
                    console.log('propertylist.payload.data.area[x].areacolor')
                    console.log(propertylist.payload.data.area[x].slots[y].coords)
                    map.addSource('maine' + x + y, {
                      'type': 'geojson',
                      'data': {
                        'type': 'Feature',
                        'geometry': {
                          'type': 'Polygon',
                          // These coordinates outline Maine.
                          'coordinates': propertylist.payload.data.area[x].slots[y].coords.paths
                        }
                      }
                    });
                    map.addLayer({
                      'id': 'outline' + x + y,
                      'type': 'line',
                      'source': 'maine' + x + y,
                      'layout': {},
                      'paint': {
                        'line-color': propertylist.payload.data.area[x].areacolor,
                        'line-width': 3
                      }
                    });

                    // Add a source for the text
                    map.addSource('slot_text' + x + y, {
                      'type': 'geojson',
                      'data': {
                        'type': 'FeatureCollection',
                        'features': [{
                          'type': 'Feature',
                          'geometry': {
                            'type': 'Polygon',
                            // These coordinates outline Maine.
                            'coordinates': propertylist.payload.data.area[x].slots[y].coords.paths
                          },
                          'properties': {
                            'title': propertylist.payload.data.area[x].slots[y].name
                          }
                        }]
                      }
                    });
                    // Add a symbol layer for the text
                    map.addLayer({
                      'id': 'slot_text' + x + y,
                      'type': 'symbol',
                      'source': 'slot_text' + x + y,
                      'layout': {
                        'text-field': ['get', 'title'],
                        'text-size': 16,
                        'text-offset': [0, 0],
                        'text-anchor': 'top'
                      },
                      'paint': {
                        'text-color': "#fff", // Set the text color to red
                        'text-halo-color': "#000",
                        'text-halo-width': 3
                      }
                    });
                  }
                }
              }
            });
          }


          setMap(map);
          setMapDraw(draw);
          // Clean up on unmount
          return () => {
            map.remove();
            drawRef.current = null; // Reset the draw reference
          };
        }
      }
    }
    else {
      if (propertylist.payload.response && propertylist.payload.response.data && propertylist.payload.response.data.message) {
        toastify({ message: propertylist.payload.response.data.message })
        if (propertylist.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }


  function handlepopupcloseavailability() {
    setdeleteavailability(false);
  }
  async function handlepopupclosedeleteavailability() {

    var slots = property.area[propertyselectedareaindex].dates.filter((e, ind) => ind != propertyselectedslotindex)
    var body = {
      "apiurl": '/property/update/dates',
      "_id": property._id,
      areaid: deletearea,
      dates: slots,
      dateindex: propertyselectedareaindex,
      headers: { "x-access-token": usertoken }
    }
    console.log(body)
    var propertys = await dispatch(fetchData(body))
    if (propertys && propertys.payload && propertys.payload.status == 200) {
      toastify({ message: propertys.payload.message })
      window.location.reload()
    }
    else {
      toastify({ message: propertys.payload.message })
    }
  }

  function handlepopupclose() {
    setdeletearea(false);
  }
  async function handlepopupclosedelete() {

    var slots = property.area[propertyselectedareaindex].slots.filter((e, ind) => ind != propertyselectedslotindex)
    console.log(property._id)
    console.log(property.area[propertyselectedareaindex]._id)
    console.log(property.area[propertyselectedareaindex].slots[propertyselectedslotindex].name)
    
    var response = await dispatch(fetchData({
      "apiurl": "/candelete/slot",
      _id: property._id,
      areaid: property.area[propertyselectedareaindex]._id,
      slotid: property.area[propertyselectedareaindex].slots[propertyselectedslotindex].name,
      headers: { "x-access-token": usertoken }
    }))
    if(response.payload && response.payload.data && response.payload.data.length){
      setdeletearea(false);
      toastify({"message": response.payload.message})
    }
    else{
      var body = {
        "apiurl": '/property/update/slot',
        "_id": property._id,
        areaid: deletearea,
        slots: slots,
        areaindex: propertyselectedareaindex,
        headers: { "x-access-token": usertoken }
      }
      console.log(body)
      var propertys = await dispatch(fetchData(body))
      if (propertys && propertys.payload && propertys.payload.status == 200) {
        toastify({ message: propertys.payload.message })
        window.location.reload()
      }
      else {
        toastify({ message: propertys.payload.message })
      }
    }
  }


  // function handleEditclick (e) {
  //   setpropertyselectedslotindex(Number(e.target.id.split("_")[2]))
  //   setpropertyselectedareaindex(Number(e.target.id.split("_")[1]))
  //   var modifiedFood = property.area[Number(e.target.id.split("_")[1])].dates
  //   modifiedFood.push({
  //     start: "",
  //     end: "",
  //     price: ""
  //   })
  //   var prop2 = {
  //     areaname: property.area[Number(e.target.id.split("_")[1])].areaname,
  //     slotdetail: property.area[Number(e.target.id.split("_")[1])].slotdetail,
  //     areacolor: property.area[Number(e.target.id.split("_")[1])].areacolor,
  //     dates: modifiedFood
  //   }
  //   setstepEInputField(pre => pre = prop2)
  //   setEditslotinf(true);
  // };

  const handleDeleteSlotEdit = (e) => {
    const slotIndex = Number(e.target.id.split("_")[1]);
    console.log(stepEinputField)
    var modifiedFood = stepEinputField.dates
    modifiedFood.splice(slotIndex, 1)
    var prop2 = {
      areaname: stepEinputField.areaname,
      slotdetail: stepEinputField.slotdetail,
      areacolor: stepEinputField.areacolor,
      dates: modifiedFood
    }
    setstepEInputField(pre => pre = prop2)
  };

  const handleAddSlotEdit = () => {
    console.log(stepEinputField)
    var modifiedFood = stepEinputField.dates
    modifiedFood.push({
      start: "",
      end: "",
      price: ""
    })
    var prop2 = {
      areaname: stepEinputField.areaname,
      slotdetail: stepEinputField.slotdetail,
      areacolor: stepEinputField.areacolor,
      dates: modifiedFood
    }
    setstepEInputField(pre => pre = prop2)
  };

  function handleDeletedateclick(){

  }

  function handleEditclickdate(e) {
    setdisableslotinput(true)
    handleEditclick(e)
  }

  function handleEditclick(e) {
    const areaIndex = Number(e.target.id.split("_")[1]);
    const slotIndex = Number(e.target.id.split("_")[2]);

    setpropertyselectedslotindex(slotIndex);
    setpropertyselectedareaindex(areaIndex);

    // Create a deep copy of the area to avoid mutating the original state directly
    const areaCopy = JSON.parse(JSON.stringify(property.area[areaIndex]));

    if (areaCopy.dates && areaCopy.dates.length) {
      for (let x = 0; x < areaCopy.dates.length; x++) {
        console.log(areaCopy.dates[x].start, '=====areaCopy.dates[x].start====');
        areaCopy.dates[x].start = areaCopy.dates[x].start.toString().substring(0, 10);
        areaCopy.dates[x].end = areaCopy.dates[x].end.toString().substring(0, 10);
      }
    }

    const prop2 = {
      areaname: areaCopy.areaname,
      slotdetail: areaCopy.slotdetail,
      areacolor: areaCopy.areacolor,
      dates: areaCopy.dates
    };

    setstepEInputField(prop2);
    setEditslotinf(true);
  }

  const handleClosepopup = () => {
    setdisableslotinput(false)
    setEditslotinf(!editslotinf);
  };

  const handleGoback = (index) => {
    console.log(index)
    if(index){
      Navigategoback("/slotproperty?index=" + propertyid+"&area="+index);
    }
    else{
      Navigategoback("/slotproperty?index=" + propertyid);
    }
  };

  const handleSaveEditSlot = async () => {
    if (!stepEinputField.areaname) {
      toastify({ message: "Area name is required" })
      return
    }
    var areas = []
    if (property.area.length) {
      areas = JSON.parse(JSON.stringify(property.area))
    }
    var dates = []
    if (stepEinputField.dates.length) {
      for (var x = 0; x < stepEinputField.dates.length; x++) {
        if (stepEinputField.dates[x].start && stepEinputField.dates[x].end && stepEinputField.dates[x].price) {
          dates.push(stepEinputField.dates[x])
        }
      }
    }
    for (var x = 0; x < areas.length; x++) {
      if (areas[propertyselectedareaindex]._id == areas[x]._id) {
        areas[x].areaname = stepEinputField.areaname
        areas[x].areacolor = stepEinputField.areacolor
        areas[x].slotdetail = stepEinputField.slotdetail
        areas[x].dates = dates
      }
    }

    var body = {
      "apiurl": "/property/update",
      "_id": property._id,
      'area': areas,
      headers: { "x-access-token": usertoken }
    }
    var propertys = await dispatch(fetchData(body))
    console.log(propertys)
    setdisableslotinput(false)
    setEditslotinf(!editslotinf);
    if (propertys && propertys.payload && propertys.payload.status == 200) {
      toastify({ message: propertys.payload.message })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
    else {
      toastify({ "message": propertys.payload.message })
    }
  }

  const handleSave = () => {
    handleSaveclick()
    handleSaveclick3()
    Navigate("/managearea");
  };
  const handleEdit = () => {
    Navigate1("/addproperty?index=" + propertyid + "&step=0");
  };
  const handleAddslots = () => {
    Navigate("/addproperty?index=" + propertyid + "&step=2");
  };
  const handleSaveclick = async () => {
    handleSaveclicked({
      apiurl: "/property/update",
      _id: propertyid,
      propertynotification: step1inputField.propertynotification,
      headers: { "x-access-token": usertoken }
    })
  }
  const handleSaveclick3 = async () => {
    console.log("update")
    console.log(step3inputField.videolink)
    handleSaveclicked({
      apiurl: "/property/update",
      _id: propertyid,
      videolink: step3inputField.videolink,
      area: propertyareas,
      headers: { "x-access-token": usertoken }
    })
  }

  const handlePropertyAreas = (e, areaIndex) => {
    const value = e.target.value;

    setpropertyareas((prev) => {
      // Create a deep copy of propertyareas to avoid direct mutation
      const updatedPropertyAreas = propertyareas.map((area, index) =>
        index === areaIndex ? { ...area, slotnotification: value } : area
      );

      return updatedPropertyAreas;
    })
  }

  const [stepEinputField, setstepEInputField] = useState({
    areaname: "",
    slotdetail: "",
    areacolor: "#000",
    dates: [{
      start: "",
      end: "",
      price: ""
    }]
  });

  function handleDeleteclick(e) {
    setpropertyselectedslotindex(Number(e.target.id.split("_")[2]))
    setpropertyselectedareaindex(Number(e.target.id.split("_")[1]))
    setdeletearea(property.area[Number(e.target.id.split("_")[1])]._id);
  }

  function handleDeleteclickavailability(e) {
    setpropertyselectedslotindex(Number(e.target.id.split("_")[2]))
    setpropertyselectedareaindex(Number(e.target.id.split("_")[1]))
    setdeleteavailability(property.area[Number(e.target.id.split("_")[1])]._id);
  }

  const handleSaveclicked = async (body) => {
    console.log(body)
    var propertylist = await dispatch(
      fetchData(body)
    );
    console.log(propertylist)
    if (
      propertylist &&
      propertylist.payload &&
      propertylist.payload.data &&
      propertylist.payload.status == 200
    ) {
      getproperty(true)
    }
    toastify({ message: propertylist.payload.message })
  }

  const [imageUrls, setImageUrls] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const files = e.target.files[0];

    const formData = new FormData();
    formData.append("property-image", files);
    formData.append("property-id", propertyid);

    const response = await fetch(process.env.REACT_APP_BASEURL + '/upload/property-image-single', {
      method: "post",
      body: formData
    });
    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      console.log("data")
      console.log(data.data)
      getproperty(false)
    }
  };

  const editproperty = () => {
    Navigate("/addproperty?index=" + propertyid + "&step=1");
  }

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  function inputslotinfo(e) {
    console.log(e.target.name)

    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }

  const inputsHandlerareadatesedit = (e) => {
    const { name, value } = e.target;
    const modifiedFood = stepEinputField.dates.map((i, index) => {
      if (index === Number(name.split('_')[2])) {
        i[name.split('_')[1]] = value;
      }
      return i
    })
    var prop2 = {
      areaname: stepEinputField.areaname,
      slotdetail: stepEinputField.slotdetail,
      areacolor: stepEinputField.areacolor,
      dates: modifiedFood
    }
    setstepEInputField(pre => pre = prop2)
    console.log(stepEinputField.dates)
  };

  const inputsHandlerareaedit = (e) => {
    console.log(e.target.name);
    setstepEInputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handlesetActiveTab = (newValue, index) => {
    setActiveTab((prevState) => {
      // Create a copy of the previous state
      const newState = [...prevState];
      // Set the value at the specified index
      newState[index] = newValue;
      // Return the updated state
      return newState;
    });
  }

  const removeImage = async (indexToRemove) => {
    var images = property.images
    const filteredImages = images.filter((_, index) => index !== indexToRemove);
    // setImageUrls(filteredImages);
    var body = {
      "apiurl": "/property/update",
      "_id": property._id,
      "images": filteredImages,
      headers: { "x-access-token": usertoken }
    }
    var result = await dispatch(fetchData(body))
    console.log(result)
    if (result.payload.status == 200) {
      getproperty(false)
    }
  };

  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleSection = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  

  return (
    <>
      <div className="pro-review-top">
        <div className="pro-review-ftop">
          <h1>Property Review</h1>
        </div>
        <div className="pro-review-stop">
          <button className="btn-pro-review" onClick={editproperty}>
            <i className="fa-solid fa-pen"></i>Edit Property Outlines
          </button>
        </div>
      </div>

      <div className="step-div-map-edit">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label.name}>
                  <StepLabel><a href={"#"+label.id} >{label.name}</a></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </div>
      <div className="add-main pro-hr">
        <div className="map-div slot-prodiv"
            id="fullmap">
          <div
            ref={mapContainerRef}
            style={{ width: "100%", height: "664px", borderRadius: "10px" }}
          />
        </div>

        <div className="add-formdiv-pro" id="full_property_detail">
          <div className="add-main">
            <div className="add-formdiv">
              <div className="add-top">
                <div className="slot-datetable">
                  <div className="slot-datetable1" >
                    <h2>Property Details</h2>
                  </div>
                  <div className="slot-datetable2">
                    <img src={Editicon} alt="Editicon" onClick={handleEdit} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="add-form">
            <div className="select-div">
              <div className="select-div1">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Property Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Property Name"
                  name="title"
                  onChange={inputsHandler}
                  value={step1inputField.title}
                  disabled='true'
                />
              </div>
              <div className="select-div2">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Categary:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Category"
                  name="category"
                  onChange={inputsHandler}
                  value={step1inputField.category}
                  disabled='true'
                />
              </div>
            </div>

            <div className="select-div">
              <div className="select-div1">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Google Address:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Google Address"
                  name="areaaddress"
                  onChange={inputsHandler}
                  value={step1inputField.areaaddress}
                  disabled='true'
                />
              </div>
              <div className="select-div2">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Country:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Country Name"
                  name="country"
                  onChange={inputsHandler}
                  value={step1inputField.country}
                  disabled='true'
                />
              </div>
            </div>

            <div className="select-div">
              <div className="select-div1">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  City:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City Name"
                  name="city"
                  onChange={inputsHandler}
                  value={step1inputField.city}
                  disabled='true'
                />
              </div>
              <div className="select-div2">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  State:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter State Name"
                  name="state"
                  onChange={inputsHandler}
                  value={step1inputField.state}
                  disabled='true'
                />
              </div>
            </div>

            <div className="select-div">
              <div className="select-div1">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  ZipCode:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter ZipCode"
                  name="zipcode"
                  onChange={inputsHandler}
                  value={step1inputField.zipcode}
                  disabled='true'
                />
              </div>
              <div className="select-div2">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Total Property Area in Acres:
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Total Property Area"
                  name="totalarea"
                  onChange={inputsHandler}
                  value={step1inputField.totalarea}
                  disabled='true'
                />
              </div>
            </div>

            <div className="form-des">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Push Notification Upon Arrival to Property (Available on
                App Only)
              </label>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  id="floatingTextarea2"
                  name="propertynotification"
                  onChange={inputsHandler}
                  value={step1inputField.propertynotification}
                ></textarea>
                <label for="floatingTextarea2"></label>
              </div>
            </div>

            <div className="save-btndiv pro-review">
              <button className="save-btn" onClick={handleSaveclick}>Save</button>
            </div>
          </div>
        </div>


        <div className="pro-review-tops"  id="full_areas_slots">
          <div className="pro-review-ftop">
            <h1>Areas & Slots</h1>
          </div>
          <div className="pro-review-stop">
            <button className="btn-pro-review" onClick={handleAddslots}>
            <AddIcon /> Add New Area
            </button>
          </div>
        </div>
        <div className="accordion">
            {propertyareas && propertyareas.length ?
              propertyareas.map((area, index) => (
                <>
        <div className="add-formdiv accordion-section"  key={index} >
          <div className="slot-datetable">
            <div className="slot-datetable1 add-top" >
              <h2>
                {area.areaname}  
              </h2>
            </div>
            <div className="slot-datetable2">
              <button className="proper-add" onClick={handleEditclick} id={'revieweditareas_' + index + '_0'}>
                <img className="edit-area-pencil" src={Editpencilicon} alt="Editpencilicon" /> Edit Area
              </button>
            <svg xmlns="http://www.w3.org/2000/svg" className={openIndex === index?"arrow_open":"arrow_close"} 
            onClick={() => toggleSection(index)}  height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"/></svg>
            </div>
          </div>
          {openIndex === index && (
          <div className="add-form">
                  <div className="areas&slot-div">
                    <div className="areas&slot-buttion">
                      <button
                        className={activeTab[index] === "unit" ? "button1" : "button2"}
                        onClick={() => handlesetActiveTab("unit", index)}
                      >
                        Slots
                      </button>
                      <button
                        className={
                          activeTab[index] === "availability" ? "button1" : "button2"
                        }
                        onClick={() => handlesetActiveTab("availability", index)}
                      >
                       Slots Availability
                      </button>
                      <button
                        className={
                          activeTab[index] === "slotNotification" ? "button1" : "button2"
                        }
                        onClick={() => handlesetActiveTab("slotNotification", index)}
                      >
                        Arrival on Slot Notification
                      </button>
                    </div>
                    {activeTab[index] === "unit" && (
                      <div className="add-table">
                        <table className="table ">
                          <thead>
                            <tr>
                              <th scope="col">Slot</th>
                              <th scope="col">Size in Acres</th>
                              <th scope="col">
                                <button className="proper-add proper-add-slot" onClick={()=>handleGoback()}>
                                  <AddIcon /> Add Slot
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {property.area[index] && property.area[index].slots.length ?
                              property.area[index].slots.map((slots, slotindex) => (<tr>
                                <td>{slots.name}</td>
                                <td>{slots.size} {slots.unit}</td>
                                <td>
                                  <span className="action-icons pro-action">
                                    <img
                                      src={Editicon}
                                      alt="Editicon"
                                      onClick={()=>handleGoback(index)} id={'revieweditarea_' + index + '_' + slotindex}
                                    />
                                    <img src={Deleteicon} alt="Deleteicon" id={'reviewdeletearea_' + index + '_' + slotindex}
                                      onClick={handleDeleteclick} />
                                    {/* <img src={Vectoricon} alt="Vectoricon"
                                      onClick={handleGoback} id={'reviewcopyarea_' + index + '_' + slotindex} /> */}
                                  </span>
                                </td>
                              </tr>)) : null}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {activeTab[index] === "availability" && (
                      <div className="add-table">
                        <table className="table ">
                          <thead>
                            <tr>
                              <th scope="col">Start Date</th>
                              <th scope="col">End Date</th>
                              <th scope="col">Price($)</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {property.area[index] && property.area[index].dates.length ?
                              property.area[index].dates.map((dates, dateindex) => (
                                <tr>
                                  <td>{dates.start.substring(0, 10)}</td>
                                  <td>{dates.end.substring(0, 10)}</td>
                                  <td>{dates.price}</td>
                                  <td>
                                    <span className="action-icons pro-action">
                                      <img
                                        src={Editicon}
                                        alt="Editicon"
                                        onClick={handleEditclickdate} id={'revieweditareadate_' + index + '_' + dateindex}
                                      />
                                      <img src={Deleteicon} alt="Deleteicon" id={'reviewdeleteareadate_' + index + '_' + dateindex}
                                        onClick={handleDeleteclickavailability} />
                                      {/* <img src={Vectoricon} alt="Vectoricon"
                                        onClick={handleGoback} id={'reviewcopyarea_' + index + '_' + slotindex} /> */}
                                    </span>
                                  </td>
                                </tr>
                              )) : null}

                          </tbody>
                        </table>
                      </div>
                    )}
                    {activeTab[index] === "slotNotification" && (
                      <div className="form-des">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label required"
                        >
                          Push Notification Upon Arival to Slot
                        </label>
                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            id="floatingTextarea2"
                            name="slotnotification"
                            onChange={(e) => handlePropertyAreas(e, index)}
                            value={area.slotnotification}
                          ></textarea>
                          <label for="floatingTextarea2"></label>
                        </div>
                      </div>
                    )}
                  </div>
            {/* <div className="proper-adddiv">
                <button className="proper-add" onClick={handleAddslots}>
                  <AddIcon /> Add New Slots
                </button>
              </div> */}
          </div>
          )}
        </div></>
              )) :  <div className="header-empty">
                No Area Created!
              </div> }
        </div>

        <div className="add-formdiv pro-upload" id="full_upload_media">
          <div className="add-top" >
            <h2>Upload Media</h2>
          </div>

          <div className="add-form">
            <div
              className="form-control upload-container"
            // onClick={handleDivClick}
            >
              <input
                type="file"
                className="file-input"
                onChange={handleFileChange}
                ref={fileInputRef}
                accept="image/png, image/gif, image/jpeg"
              />
              <div className="upload-div">
                <button className="upload-button" onClick={handleDivClick}>Upload File</button>
                <p>Choose a file or drag & drop it here</p>
                <span>JPEG, PNG formats, up to 5MB</span>
                <div className="image-container">
                  {property && property.images ? property.images.map((imageUrl, index) => (
                    <div key={index} className="image-wrappers">
                      <img src={process.env.REACT_APP_BASEURL + "/" + imageUrl} alt="Uploaded" className="image" />
                      <FaTimes
                        className="remove-icon"
                        onClick={() => removeImage(index)}
                      />
                    </div>
                  )) : null}
                </div>
              </div>
            </div>
            <div className="select-div">
              <div className="select-div1">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Add Video Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add (Youtube Link)"
                  name="videolink"
                  onChange={inputsHandler3}
                  value={step3inputField.videolink}
                />
              </div>
              <div className="select-div2"> </div>
            </div>

            <div className="save-btndiv pro-review">
              <button className="save-btn" onClick={handleSaveclick3}>Save</button>
            </div>

          </div>
        </div>

        <div className="slot-btndiv">
          <button className="btn-add-back" onClick={()=>handleGoback()}>
            Go Back
          </button>
          <button className="add-btn" onClick={handleSave}>
            Save & Finish
          </button>
        </div>
      </div>

      {editslotinf && (
        <div className="pro-review-popup">
          <div className="pro-review-content">
            <div className="slot-top">
              <h1>Slot Information</h1>
              <i className="fa-solid fa-xmark" onClick={handleClosepopup}></i>
            </div>

            <div className="pro-review-popupcontent">
              <div className="add-form">
                <div className="select-div">
                  <div className="select-div1">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label manage-Qicon required"
                    >
                      Area Name
                      <img src={Questionicon} alt="Questionicon" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Area Name"
                      name="areaname"
                      disabled={disableslotinput}
                      onChange={inputsHandlerareaedit}
                      value={stepEinputField.areaname}
                    />
                  </div>
                  <div className="select-div2">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label manage-Qicon required"
                    >
                      Slot Details
                      <img src={Questionicon} alt="Questionicon" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Slot Details"
                      name="slotdetail"
                      disabled={disableslotinput}
                      onChange={inputsHandlerareaedit}
                      value={stepEinputField.slotdetail}
                    />
                  </div>
                  <div className="select-div3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label manage-Qicon required"
                    >
                      Slot Color
                      <img src={Questionicon} alt="Questionicon" />
                    </label>
                    <div className="areacolor-div">
                      <div className="areacolor-input">
                        <input
                          className=""
                          type="color"
                          id="color"
                          name="areacolor"
                          disabled={disableslotinput}
                          onChange={inputsHandlerareaedit}
                          value={stepEinputField.areacolor}
                        />
                      </div>
                      <p>{stepEinputField.areacolor}</p>
                    </div>
                  </div>
                </div>
                <div className="slot-fields">{stepEinputField.dates.map((date, i) => {

                  return (<div className="slotion-main">
                    <div className="slot-threefield" key={i}>
                      <div className="field-one">
                        <label htmlFor="startDate" className="form-label manage-Qicon required">
                          Start Date
                          <img src={Questionicon} alt="Questionicon" />
                        </label>
                        <input
                          type="date" onKeyDown={(e) => e.preventDefault()}
                          className="form-control"
                          id={`startDate-${i}`}
                          name={"area_start_" + i}
                          onChange={inputsHandlerareadatesedit}
                          value={date.start}
                          min={mindate}
                        />
                      </div>
                      <div className="field-two">
                        <label htmlFor="endDate" className="form-label manage-Qicon required">
                          End Date
                          <img src={Questionicon} alt="Questionicon" />
                        </label>
                        <input type="date" onKeyDown={(e) => e.preventDefault()} className="form-control" id={`endDate-${i}`}
                          name={"area_end_" + i}
                          onChange={inputsHandlerareadatesedit}
                          value={date.end}
                          min={date.start} />
                      </div>
                      <div className="field-three">
                        <label htmlFor="cost" className="form-label manage-Qicon required">
                          Cost($ per day)
                          <img src={Questionicon} alt="Questionicon" />
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Cost"
                          id={`cost-${i}`}
                          name={"area_price_" + i}
                          onChange={inputsHandlerareadatesedit}
                          value={date.price}
                        />
                      </div>
                    </div>
                    <div className="slotdelet-div">
                      <img src={Deletesloticon} alt="Deletesloticon" id={'deleteslotedit_' + i} onClick={handleDeleteSlotEdit} />
                    </div>
                  </div>)
                })}</div>

                <div className="slot-btndiv">
                  <button className="btn-add-slot" onClick={handleAddSlotEdit}>
                    <AddIcon /> Add New Dates
                  </button>
                  <div className="slot-backdiv">
                    <button className="back-btn" onClick={handleClosepopup}>
                      Go Back
                    </button>
                    <button className="add-btn" onClick={handleSaveEditSlot}>
                      Save & Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {deletearea && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure Want to Delete?</h1>
              <p>Are you sure you want to delete this Slot? Please note that this action cannot be undone.</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handlepopupclosedelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {deleteavailability && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupcloseavailability}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure Want to Delete?</h1>
              <p>Are you sure you want to delete this Slot Availability? Please note that this action cannot be undone.</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupcloseavailability}>
                Cancel
              </button>
              <button className="btn2" onClick={handlepopupclosedeleteavailability}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
