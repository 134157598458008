import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify, userlogout, clearuser } from "../redux/controller";

export default function Createcoupon() {
  const dispatch = useDispatch()
  const search = useLocation().search;
  const couponid = new URLSearchParams(search).get("index");
  console.log(couponid)
  const [inputField , setInputField] = useState({
    couponcode: '',
    discountType: 'percentage',
    couponamount: 0,
    expiryDate: '',
    usagelimit: 0,
    activereservationcanuse: 0,
    // removehandlingcharges: false,
    oneslotperday: false
  })

  const inputsHandler = (e) =>{
    if(e.target.name == 'couponamount' || e.target.name == 'usagelimit' || e.target.name == 'activereservationcanuse'){
      if(e.target.value < 0 || e.target.value == '-'){
        return toastify({message: "Value cannot be less than 0"})
      }
    }
    if(e.target.name == 'oneslotperday'){
      console.log(e.target.value)
      setInputField(prevState => ( {...prevState, [e.target.name]: e.target.value == 'true' || e.target.value == true?false:true} ))
    }
    else if(e.target.name == 'couponamount'){
      if(inputField.couponamount){
        if(inputField.discountType == 'percentage' && inputField.couponamount>100){
          toastify({message: "Coupon amount cannot be greater than 100%"})
        }
      }
      setInputField(prevState => ( {...prevState, [e.target.name]: e.target.value} ))
    }
    else{
      setInputField(prevState => ( {...prevState, [e.target.name]: e.target.value} ))
    }
    console.log(e.target.value)
    console.log(mindate)
    console.log(mindate<e.target.value)
  }

  const [isChecked, setIsChecked] = useState(false);
  const [mindate, setmindate] = useState(new Date().toISOString().split("T")[0]);
  
  const Navigate = useNavigate();

  const usertoken = useSelector((state) => state.userdata.usertoken);

  useEffect(() => {
    if(couponid){
      getcoupon()
    }
  }, []);
  async function getcoupon(){
    var couponslist = await dispatch(
      fetchData({
        apiurl: "/coupon/find",
        _id: couponid,
        headers: { "x-access-token": usertoken }
      })
    );
    
    if (
      couponslist &&
      couponslist.payload &&
      couponslist.payload.data
    ) {
      setInputField(prevState => ( {...prevState, ['activereservationcanuse']: couponslist.payload.data.activereservationcanuse} ))
      setInputField(prevState => ( {...prevState, ['couponamount']: couponslist.payload.data.couponamount} ))
      setInputField(prevState => ( {...prevState, ['couponcode']: couponslist.payload.data.couponcode} ))
      setInputField(prevState => ( {...prevState, ['discountType']: couponslist.payload.data.discountType} ))
      setInputField(prevState => ( {...prevState, ['expiryDate']: couponslist.payload.data.expiryDate.substring(0, 10)} ))
      setInputField(prevState => ( {...prevState, ['oneslotperday']: couponslist.payload.data.oneslotperday} ))
      // setInputField(prevState => ( {...prevState, ['removehandlingcharges']: couponslist.payload.data.removehandlingcharges} ))
      setInputField(prevState => ( {...prevState, ['usagelimit']: couponslist.payload.data.usagelimit} ))
    }
  }

  const handleCreate= async() => {
    console.log(inputField)

    if(inputField.discountType == 'percentage' && inputField.couponamount>100){
      toastify({message: "Coupon amount cannot be greater than 100%"})
      return 
    }
    if(mindate>inputField.expiryDate){
      toastify({message: "Coupon expiry date cannot be lesser than today"})
      return 
    }
    if(inputField.usagelimit<1){
      toastify({message: "Coupon usage limit can't be 0"})
      return 
    }

    if(inputField.couponcode && inputField.couponamount){
      var coupon = await dispatch(fetchData({
        "apiurl": "/coupon/create",
        couponcode: inputField.couponcode,
        discountType: inputField.discountType,
        couponamount: inputField.couponamount,
        expiryDate: inputField.expiryDate,
        usagelimit: inputField.usagelimit,
        activereservationcanuse: inputField.activereservationcanuse,
        // removehandlingcharges: inputField.removehandlingcharges,
        oneslotperday: inputField.oneslotperday,
        headers: { "x-access-token": usertoken }
      }))
      console.log(coupon)
      if(coupon && coupon.payload && coupon.payload.status == 200){
        toastify({message: coupon.payload.message})
        Navigate("/managecoupons");
      }else{
        console.log(coupon.payload)
        if (coupon.payload.response && coupon.payload.response.data && coupon.payload.response.data.message) {
          toastify({ message: coupon.payload.response.data.message })
          if (coupon.payload.response.data.message == "Invalid token provided!") {
            clearuser()
            userlogout()
            Navigate('/')
            window.location.reload()
          }
        }
        else if(coupon.payload && coupon.payload.status == 400 && coupon.payload.message){
          toastify({ message: coupon.payload.message})
        }
        return null
      }
    }
    else{
      toastify({message: "Please fill all mandatory fields"})
    }
  };
  const handleUpdate= async() => {
    console.log(inputField)
    if(inputField.discountType == 'percentage' && inputField.couponamount>100){
      toastify({message: "Coupon amount cannot be greater than 100%"})
      return 
    }
    if(inputField.usagelimit<1){
      toastify({message: "Coupon usage limit can't be 0"})
      return 
    }
    if(inputField.couponcode.replaceAll(' ','') && inputField.couponamount){
      var coupon = await dispatch(fetchData({
        "apiurl": "/coupon/update",
        _id: couponid,
        couponcode: inputField.couponcode,
        discountType: inputField.discountType,
        couponamount: inputField.couponamount,
        expiryDate: inputField.expiryDate,
        usagelimit: inputField.usagelimit,
        activereservationcanuse: inputField.activereservationcanuse,
        // removehandlingcharges: inputField.removehandlingcharges,
        oneslotperday: inputField.oneslotperday,
        headers: { "x-access-token": usertoken }
      }))
      console.log(coupon)
      if(coupon && coupon.payload && coupon.payload.status == 200){
        toastify({message: coupon.payload.message})
        Navigate("/managecoupons");
      }
      else{
        console.log(coupon.payload.response)
        if (coupon.payload.response && coupon.payload.response.data && coupon.payload.response.data.message) {
          toastify({ message: coupon.payload.response.data.message })
          if (coupon.payload.response.data.message == "Invalid token provided!") {
            clearuser()
            userlogout()
            Navigate('/')
            window.location.reload()
          }
        }
        return null
      }
    }
    else{
      toastify({message: "Please fill all mandatory fields"})
    }
  };


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="add-main">
      <div className="add-formdiv">
        <div className="add-top">
        {couponid?<h2>Update Coupon</h2>:<h2>Create Coupon</h2>}
        </div>
        <div className="add-form">
          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Coupon Code<span className="required-star">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Coupon Code"
                name="couponcode"
                onChange={inputsHandler} 
                value={inputField.couponcode}
              />
            </div>
            <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Discount Type
              </label>
              <select
                class="form-select form-select-lg mb-3"
                aria-label="Large select example"
                name="discountType"
                onChange={inputsHandler} 
                value={inputField.discountType}
              >
                <option selected value="percentage">Percentage</option>
                <option value="fixedamount">Fixed Amount</option>
              </select>
            </div>
          </div>
          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Coupon Amount<span className="required-star">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Coupon Amount"
                name="couponamount"
                onChange={inputsHandler} 
                value={inputField.couponamount}
                min={0}
              />
            </div>
            <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Coupon Expiry Date
              </label>
              <input
                type="date" onKeyDown={(e) => e.preventDefault()}
                className="form-control"
                placeholder="Enter Coupon Expiry Date"
                name="expiryDate"
                onChange={inputsHandler} 
                value={inputField.expiryDate}
                min={mindate}
              />
            </div>
          </div>
          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Usage Limit
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Usage Limit"
                name="usagelimit"
                onChange={inputsHandler} 
                value={inputField.usagelimit}
                min={0}
              />
            </div>
            <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Number of Active Reservations this Coupon Can Be Used
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Number of Active Reservations"
                name="activereservationcanuse"
                onChange={inputsHandler} 
                value={inputField.activereservationcanuse}
                min={0}
              />
            </div>
          </div>
          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Restrict Coupon to 1 Slot Per Day
              </label>
              <div className="createcoupon-checkbox">
                <input
                  type="checkbox"
                  checked={inputField.oneslotperday}
                  name="oneslotperday"
                  onChange={inputsHandler} 
                  value={inputField.oneslotperday }
                />
              </div>
            </div>
            {/* <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Remove Handing Charges
              </label>
              <div className="createcoupon-checkbox">
                <input
                  type="checkbox"
                  checked={inputField.removehandlingcharges}
                  name="removehandlingcharges"
                  onChange={inputsHandler} 
                  value={inputField.removehandlingcharges}
                />
              </div>
            </div> */}
          </div>
          <div className="save-btndiv">
          {couponid?<button className="add-btn" onClick={handleUpdate}>Update Coupon</button>:<button className="add-btn" onClick={handleCreate}>Create Coupon</button>
          }
        </div>
        </div>
        
      </div>
    </div>
  );
}
