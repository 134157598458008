import React, { useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default function ReservetonsidHistoryid() {
  const historydataid = [
    {
      id: 1,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Dave Blaster",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 2,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Big Boy” 157 acres in Melissa TXr",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 3,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Dave Blaster",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 4,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Big Boy” 157 acres in Melissa TXr",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 5,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Dave Blaster",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 6,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Big Boy” 157 acres in Melissa TXr",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    }
  ];
  const [reservationhistoryidcurrentPage, setReservationhistoryidcurrentPage] =
    useState(0);
  const reservationhistoryidperPage = 5;
  const handlereservationhistoryidPageClick = (selectedPage) => {
    setReservationhistoryidcurrentPage(selectedPage.selected);
  };

  // Calculate start and end index for the current page
  const reservationhistoryidstartIndex =
    reservationhistoryidcurrentPage * reservationhistoryidperPage;
  const reservationhistoryidendIndex =
    reservationhistoryidstartIndex + reservationhistoryidperPage;

  // Get data for the current page
  const reservationhistoryidcurrentData = historydataid.slice(
    reservationhistoryidstartIndex,
    reservationhistoryidendIndex
  );

  return (
    <div>
      <div className="reservation-idtable">
        <div className="search-div">
          <input
            type="text"
            className="form-control hunter-serch"
            placeholder="Hunter Name, Property Name, Date"
          />
          <button className="add-btn">Search</button>
        </div>
        <div className="reservation_divider">
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Name</h1>
              <p>Zakk Wylde</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Email ID</h1>
              <p>zakk@wyldeguitars.com</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Phone Number</h1>
              <p>555-555-1212</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Account Created</h1>
              <p>07/03/2023</p>
            </div>
          </div>
        </div>
        <div className="add-form pagination-table">
          <div className="add-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Payment ID</th>
                  <th>Property Name</th>
                  <th>Slot ID</th>
                  <th>Reservation Date</th>
                  <th>Cost</th>
                  <th>Coupon Used</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reservationhistoryidcurrentData.map((reservation) => (
                  <tr key={reservation.id} className="upcoming_cancel">
                    <td>{reservation.payment_id}</td>
                    <td>
                      <Link className="pro-link" to="/propertydetails">
                        {reservation.property_name}
                      </Link>
                    </td>
                    <td>{reservation.slot_id}</td>
                    <td>{reservation.reservation_date}</td>
                    <td>{reservation.cost}</td>
                    <td>{reservation.coupon_used}</td>
                    <td>
                      <button className="reservation-actionbtn">
                        <i className="fas fa-ban"></i>
                        {reservation.action}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            pageCount={Math.ceil(
              historydataid.length / reservationhistoryidperPage
            )}
            pageRangeDisplayed={5}
            breakLabel={"..."}
            marginPagesDisplayed={2}
            onPageChange={handlereservationhistoryidPageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
