import React, { useState, useEffect } from "react";
import mainlogo from "../images/splash.png";
import "./App.css";
import { useNavigate, useLocation } from "react-router-dom";

const Change_pass = () => {
  const Navigate = useNavigate();
  const Navigate1 = useNavigate();
  const location = useLocation();
  const searchData = new URLSearchParams(location.search);
  const [popstatus, setPopStatus] = useState(
    searchData.get("popstatus") || false
  );
  const [successPopup, setSuccessPopup] = useState(false);

  const handlesuccessPopup = () => {
    setSuccessPopup(!successPopup);
    setPopStatus(false);
  };

  // Use useEffect to update the state when the location changes
  useEffect(() => {
    setPopStatus(searchData.get("popstatus") || false);
  }, [location.search]);

  console.log(popstatus, "popstatus");

  const handleback = () => {
    Navigate("/");
  };
  function handlepopupclose() {
    Navigate1("/profile");
  }

  return (
    <>
      {!successPopup && (
        <div>
          {!popstatus ? (
            <div class="login_section">
              <div class="w-100 text-center">
                <div class="row">
                  <div class="col-lg-7 col-sm-12">
                    <p className="top_links back_arrow" onClick={handleback}>
                      <svg
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.0001 3.99995C16.0001 4.19886 15.9211 4.38962 15.7804 4.53027C15.6398 4.67093 15.449 4.74995 15.2501 4.74995H2.6601L4.7601 6.69994C4.90597 6.83521 4.99213 7.02287 4.99963 7.22166C5.00713 7.42045 4.93536 7.61408 4.8001 7.75995C4.66484 7.90581 4.47717 7.99198 4.27838 7.99948C4.07959 8.00698 3.88597 7.93521 3.7401 7.79995L0.240098 4.54995C0.164369 4.47974 0.103955 4.39465 0.0626404 4.30001C0.0213255 4.20537 0 4.10321 0 3.99995C0 3.89668 0.0213255 3.79453 0.0626404 3.69988C0.103955 3.60524 0.164369 3.52015 0.240098 3.44994L3.7401 0.199945C3.88597 0.0646846 4.07959 -0.00708947 4.27838 0.000411984C4.47717 0.00791344 4.66484 0.0940759 4.8001 0.239945C4.93536 0.385814 5.00713 0.579441 4.99963 0.778229C4.99213 0.977018 4.90597 1.16469 4.7601 1.29995L2.6601 3.24995H15.2501C15.449 3.24995 15.6398 3.32896 15.7804 3.46962C15.9211 3.61027 16.0001 3.80103 16.0001 3.99995Z"
                          fill="#BE7637"
                        />
                      </svg>
                      Back to Login
                    </p>
                    <div className="login_form_content">
                      <div className="content_section">
                        <form>
                          <h2>Change Password</h2>
                          <h5>
                            To Chnage your Password, please fill the fields
                            below.
                          </h5>
                          <div className="form-row">
                            <div className="input-group">
                              <input
                                type="password"
                                id="password"
                                className="form-control"
                                placeholder="Current Password"
                              />
                              <button
                                type="button"
                                className="eye-icon btn btn-outline-secondary"
                              >
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                            <div className="input-group">
                              <input
                                type="password"
                                id="password"
                                className="form-control"
                                placeholder="New Password"
                              />
                              <button
                                type="button"
                                className="eye-icon btn btn-outline-secondary"
                              >
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                            <div className="input-group">
                              <input
                                type="password"
                                id="password"
                                className="form-control"
                                placeholder="Confirm New Password"
                              />
                              <button
                                type="button"
                                className="eye-icon btn btn-outline-secondary"
                              >
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                          <div className="form-row w-100">
                            <button
                              type="submit"
                              className="users_btn change-passbtn"
                              onClick={handleback}
                            >
                              Change Password
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-12">
                    <div
                      className="image_section "
                      style={{ "text-align": "end" }}
                    >
                      <img src={mainlogo} alt="mainlogo"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="chagepassword-popup">
              <div className="chagepassword-content">
                <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>

                <div className="chagepass-popupcontent">
                  <h1>Change Password</h1>
                  <p>To change your password, please fill the fields below.</p>

                  <div className="form-row">
                    <div className="input-group">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="Current Password"
                      />
                      <button
                        type="button"
                        className="eye-icon btn btn-outline-secondary"
                      >
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="input-group">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="New Password"
                      />
                      <button
                        type="button"
                        className="eye-icon btn btn-outline-secondary"
                      >
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="input-group">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="Confirm New Password"
                      />
                      <button
                        type="button"
                        className="eye-icon btn btn-outline-secondary"
                      >
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div className="form-row w-100">
                    <button
                      type="submit"
                      className="users_btn change-passbtn"
                      onClick={handlesuccessPopup}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* {successPopup && (
        <div className="chagesuccess-popup">
          <div className="chagesuccess-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="chagesuccess-contentdiv">
              <img src={Successicon} alt="Successicon" />
              <h1>Password Change</h1>
              <h1> Successfully </h1>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Change_pass;
