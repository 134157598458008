import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import defaultimage from "../assets/defaultimage_prop.jpg";
import Documenticon from "../assets/Document.svg"
import Locationicon from "../assets/Location.svg"
import Usericon from "../assets/User.svg"
import Timeicon from "../assets/Time Circle.svg"
import Graphicon from "../assets/Graph.svg"
import Dollericon from "../assets/$.svg"
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout, clearuser } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Components/Loader";



export default function Realtracking() {
  const dispatch = useDispatch()
  const initialized = useRef(false)
  const navigate=useNavigate()
  const Navigate = useNavigate();
  const usertoken = useSelector((state) => state.userdata.usertoken);
  const [reservations, setreservations] = useState([]);
  const [users, setusers] = useState([]);
  const [properties, setproperties] = useState([]);
  const [expirydates, setexpirydates] = useState([]);
  const [maxprices, setmaxprices] = useState([]);
  const [minprices, setminprices] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {

    if (!initialized.current) {
      initialized.current = true
      getreservations()
    }
  }, [])
  async function getreservations(){

    if (usertoken == null || usertoken.length < 1) {
      clearuser()
      userlogout()
      Navigate('/')
      window.location.reload()
      return
    }
    
    var body = {
      "apiurl": "/reservation/today",
      page: 1,
      limit: 10,
      headers: { "x-access-token": usertoken }
    }
    setloading(true)
    var propertys = await dispatch(fetchData(body))
    if(propertys && propertys.payload && propertys.payload.status == 200) {
      setloading(false)
      setreservations(propertys.payload.data)

      if (
        propertys &&
        propertys.payload &&
        propertys.payload.data &&
        propertys.payload.data.length
      ) {
        var maxdate
        var maxprice = 0
        var minprice = 0
        var maxdatepush = []
        var maxpricepush = []
        var minpricepush = []
        var properties = []
        var users = []
        for(var x=0; x<propertys.payload.data.length; x++){
          maxdate = ''
          maxprice = ''
          minprice = ''
          for(var y=0; y<propertys.payload.data[x].cart[0].property.area.length; y++){
            for(var z=0; z<propertys.payload.data[x].cart[0].property.area[y].dates.length; z++){
              if(propertys.payload.data[x].cart[0].property.area[y].dates[z].price<minprice || minprice==0){
                minprice = propertys.payload.data[x].cart[0].property.area[y].dates[z].price
              }
              if(propertys.payload.data[x].cart[0].property.area[y].dates[z].price>maxprice || maxprice==0){
                maxprice = propertys.payload.data[x].cart[0].property.area[y].dates[z].price
              }
              if(propertys.payload.data[x].cart[0].property.area[y].dates[z].end>maxdate || maxdate==''){
                maxdate = propertys.payload.data[x].cart[0].property.area[y].dates[z].end
              }
            }
          }
          if(maxprice){
            maxpricepush.push(maxprice)
          }
          else{
            maxpricepush.push('')
          }
          if(minprice){
            minpricepush.push(minprice)
          }
          else{
            minpricepush.push('')
          }
          if(maxdate){
            maxdatepush.push(maxdate.substring(0, 10))
          }
          else{
            maxdatepush.push('')
          }
          setmaxprices(maxpricepush)
          setminprices(minpricepush)
          setexpirydates(maxdatepush)
          var ind = properties.findIndex(e=>e.property._id == propertys.payload.data[x].cart[0].property._id)
          if(ind==-1){
            properties.push({property: propertys.payload.data[x].cart[0].property, userlist: [propertys.payload.data[x].userid]})
          }
          else{
            console.log(properties[ind].userlist)
            if(propertys.payload.data[x].userid && propertys.payload.data[x].userid._id && properties[ind].userlist && properties[ind].userlist.length && properties[ind].userlist[0]!=null){
              var pindex = properties[ind].userlist.findIndex(e=>e._id == propertys.payload.data[x].userid._id)
              if(pindex == -1){
                properties[ind].userlist.push(propertys.payload.data[x].userid)
              }
            }
          }
          if(propertys.payload.data.length-1 == x){
            console.log(properties)
            setproperties(properties)
          }
        }
    }}
    else{
      setloading(false)
      if(propertys.payload.response && propertys.payload.response.data && propertys.payload.response.data.message){
        toastify({message: propertys.payload.response.data.message})
        if(propertys.payload.response.data.message == "Invalid token provided!"){
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }
  function handleClick(e){
    console.log(properties[e])
    console.log(reservations)
    var userslist = []
    for(var x=0; x<properties[e].userlist.length; x++){
      console.log(properties[e].userlist[x])
      if(properties[e].userlist[x] && properties[e].userlist[x]._id){
        
        userslist.push({
          _id: properties[e].userlist[x]._id, 
          reservationid:"", 
          firstname: properties[e].userlist[x].firstname?properties[e].userlist[x].firstname:"", 
          lastname: properties[e].userlist[x].lastname?properties[e].userlist[x].lastname:"",
          email: properties[e].userlist[x].email?properties[e].userlist[x].email:"", 
          phonenumber: properties[e].userlist[x].phonenumber?properties[e].userlist[x].phonenumber:'',
          udid: properties[e].userlist[x].udid?properties[e].userlist[x].udid:""
        })
        for(var y=0; y<reservations.length; y++){
          if(properties[e].userlist && properties[e].userlist[x] && properties[e].userlist[x]._id){
            if(reservations[y].userid && reservations[y].userid._id == properties[e].userlist[x]._id){
              userslist[x].reservationid = reservations[y]._id
            }
          }
        }
      }
    }
    navigate("/realtrackingdetails?property="+properties[e].property._id,{state:{users: userslist}})
  }
  return (
    <div className={properties && properties.length ? "real-main":""}>
      {properties && properties.length ? properties.map((property, index) => {
           return (<div className="real-section cursor-pointer" id={'property_'+index} onClick={() => handleClick(index)}>
           {property && property.property.images.length?<img src={process.env.REACT_APP_BASEURL+'/' + property.property.images[0]} alt="Hotwireicon" className="real-img" />:<img src={defaultimage} alt="Hotwireicon" className="real-img" />}
        <div className="real-subsection">
          <h1>{property.property.title}</h1>
          <div className="realicon-p">
           <img src={Locationicon} alt="Locationicon"/>
            <p className="ellipses">{property.property.areaaddress}</p>
          </div>
          <div className="realicon-p">
          <img src={Documenticon} alt="Documenticon"/>
            <p className="ellipses">{property.property.features.map((feature, fi) => {
            return (feature+(property.property.features.length-1 == fi?'':", "))})}</p>
          </div>
          <div className="realicon-p">
          <img src={Usericon} alt="Usericon"/>
            <p>Available number of slot`s{" "}
                    {property.property.area.reduce(
                      (res, { slots }) => res + slots.length,
                      0
                    )}</p>
          </div>
          { expirydates && expirydates.length && expirydates[index]? <div className="realicon-p">
                  <img src={Timeicon} alt="Timeicon" />
                  <p>Expires on {expirydates[index]} </p>
                </div>:null}
          {minprices[index]|| maxprices[index]?<div className="realicon-p">
                  <img src={Dollericon} alt="Dollericon" />
                  <p>Price: ${minprices[index]}-${maxprices[index]}</p>
                </div>:''}
          <div className="realicon-p">
          <img src={Graphicon} alt="Graphicon"/>
            <p>Total Area: {property.property.totalarea} Acres</p>
          </div>
        </div>
      </div>)}): loading ? <div className="loader-top-spacing"><Loader /></div> : <p className="header-empty">No Reservations!</p>}
    </div>
  );
}
