import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBHKM1b4wYU2Rpq_E4ByjRw_0Zwl__qtvE",
    authDomain: "dove-blasters-2024-c049d.firebaseapp.com",
    projectId: "dove-blasters-2024-c049d",
    storageBucket: "dove-blasters-2024-c049d.appspot.com",
    messagingSenderId: "875614013013",
    appId: "1:875614013013:web:3f3e3e032dbb5d9731f6dc",
    measurementId: "G-WCG31S7B06"
};

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
export const dbfirebaseconfig = firebaseConfig
