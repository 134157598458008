import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout, clearuser } from "../redux/controller";
import { useSelector, useDispatch } from "react-redux";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX
  


export default function PropertyDetailsMap() {
  // outline

  const [selectedaddress, setselectedaddress] = useState("");
  const [selectedmapstyles, setselectedmapstyles] = useState("mapbox://styles/mapbox/satellite-streets-v12");
  const [property, setproperty] = useState({});
  const [coords, setcoords] = useState({ "lat": 0, "lng": 0 });
  const [totalarea, settotalarea] = useState(0);
  const [zoomlevel, setzoomlevel] = useState(0)
  var map
  var draw
  const dispatch = useDispatch();
  const [colorStore, setColorStore] = useState("#fc2403");
  const Navigate = useNavigate();
  const initialized = useRef(false)
  const [mapObject, setMap] = useState();
  const [mapObjectDraw, setMapDraw] = useState();
  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);
  const navigation_continue = useNavigate()
  const Navigategoback = useNavigate();
  const search = useLocation().search;
  const reservationid = new URLSearchParams(search).get("index");
  const todayreservations = new URLSearchParams(search).get("all");

  const usertoken = useSelector((state) => state.userdata.usertoken);

  useEffect(() => {

    if (!initialized.current) {
      initialized.current = true
      if (reservationid) {
        callprop()
      }
    }
  }, []);
  async function callprop() {
    var propertys = {}
    var slots = []
    if (reservationid) {
      if(todayreservations){
        var property = await getprops()
        console.log("property")
        console.log(property)
        if(property.length){
            if(property[0] && property[0].cart){
              propertys = property[0].cart[0].property
              for(var x=0; x<property.length; x++){
                for(var y=0; y<property[x].cart.length; y++){
                  var z = slots.findIndex(e=>e.selectedslot.name == property[x].cart[y].selectedslot.name)
                  if(z==-1){
                    slots.push(property[x].cart[y])
                  }
                }
              }
            }
            else{
              propertys = property[0]
            }
        }
        else{
            if(property.cart){
              propertys = property.cart[0].property
                for(var y=0; y<property.cart.length; y++){
                  var z = slots.findIndex(e=>e.selectedslot.name == property.cart[y].selectedslot.name)
                  if(z==-1){
                    slots.push(property.cart[y])
                  }
              } 
            }
        }
      }
      else{
        var property = await getprop()
        propertys = property.cart[0].property
        for(var x=0; x<property.cart.length; x++){
          slots.push(property.cart[x])
        }
      }
    }
    console.log("slots")
    console.log(slots)

    map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: selectedmapstyles,
      center: propertys && propertys.latlng && propertys.latlng.lat ? [propertys.latlng.lng, propertys.latlng.lat] : [coords.lng, coords.lat],
      zoom: propertys && propertys.zoomlevel ? propertys.zoomlevel : 10
    });
    draw = new MapboxDraw({
      displayControlsDefault: false,
      modes: {
        ...MapboxDraw.modes
      },
      controls: {
        rectangle: true,
        polygon: true
        // trash: true
      },
      defaultMode: 'draw_polygon',
      styles: [
        // Default style for polygon
        {
          "id": "gl-draw-polygon-fill-inactive",
          "type": "fill",
          "paint": {
            "fill-color": propertys.propertycolor, // Change this color as needed
            "fill-outline-color": propertys.propertycolor,
            "fill-opacity": 0.1
          }
        },
        // Default style for polygon outline
        {
          "id": "gl-draw-polygon-stroke-inactive",
          "type": "line",
          "paint": {
            "line-color": propertys.propertycolor,
            "line-dasharray": [2, 2],
            "line-width": 2,
            "line-opacity": 0.7
          }
        }
      ]
    });
    console.log(propertys)

    if (propertys.geodraw) {

      map.on('load', () => {
        // Add a data source containing GeoJSON data.
        map.addSource('maine', {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              // These coordinates outline Maine.
              'coordinates': propertys.geodraw.coords.paths
            }
          }
        });
        map.addLayer({
          'id': 'outline',
          'type': 'line',
          'source': 'maine',
          'layout': {},
          'paint': {
            'line-color': propertys.propertycolor,
            'line-width': 3
          }
        });

        if (slots && slots.length && slots[0].selectedarea && slots[0].selectedarea.slots && slots[0].selectedarea) {
            console.log("slots")
            console.log(slots)
          // for(var x=0; x<propertys.selectedarea.length; x++){
          for (var y = 0; y < slots.length; y++) {
            console.log('propertys.area[x].areacolor')
            console.log(slots[y])
            map.addSource('maine' + y, {
              'type': 'geojson',
              'data': {
                'type': 'Feature',
                'geometry': {
                  'type': 'Polygon',
                  // These coordinates outline Maine.
                  'coordinates': slots[y].selectedslot.coords.paths
                }
              }
            });

            map.addLayer({
              'id': 'outline' + y,
              'type': 'line',
              'source': 'maine' + y,
              'layout': {},
              'paint': {
                'line-color': slots[y].selectedarea.areacolor,
                'line-width': 3
              }
            });
            // Add a source for the text
            map.addSource('slot_text' + y, {
              'type': 'geojson',
              'data': {
                'type': 'FeatureCollection',
                'features': [{
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Polygon',
                    // These coordinates outline Maine.
                    'coordinates': slots[y].selectedslot.coords.paths
                  },
                  'properties': {
                    'title': slots[y].selectedslot.name
                  }
                }]
              }
            });
            // Add a symbol layer for the text
            map.addLayer({
              'id': 'slot_text' + y,
              'type': 'symbol',
              'source': 'slot_text' + y,
              'layout': {
                'text-field': ['get', 'title'],
                'text-size': 16,
                'text-offset': [0, 0],
                'text-anchor': 'top'
              },
              'paint': {
                'text-color': slots[y].selectedarea.areacolor // Set the text color to red
              }
            });
          }
        }
      });
    }

    setMap(map);
    setMapDraw(draw);
    return () => {
      map.remove();
      drawRef.current = null;
    };

  }

  // On Edit Property
  async function getprop() {
    var body = {
      "apiurl": "/reservation/single",
      "reservationid": reservationid,
      headers: { "x-access-token": usertoken }
    }
    var propertys = await dispatch(fetchData(body))
    if (propertys && propertys.payload && propertys.payload.status == 200) {
      setproperty(propertys.payload.data.cart[0].property)
      setselectedaddress(propertys.payload.data.cart[0].areaaddress)
      return propertys.payload.data
    }
    else {
      if (propertys.payload.response && propertys.payload.response.data && propertys.payload.response.data.message) {
        toastify({ message: propertys.payload.response.data.message })
        if (propertys.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
      return null
    }
  }


  async function getprops() {
    var body = {
      "apiurl": "/reservation/single",
      "reservationid": reservationid,
      "today": todayreservations,
      headers: { "x-access-token": usertoken }
    }
    var propertys = await dispatch(fetchData(body))
    if (propertys && propertys.payload && propertys.payload.status == 200) {
      if(propertys.payload.data && propertys.payload.data && propertys.payload.data.length && propertys.payload.data[0].cart){
        setproperty(propertys.payload.data[0].cart[0].property)
        setselectedaddress(propertys.payload.data[0].cart[0].areaaddress)
      }
      else{
        setproperty(propertys.payload.data[0])
      }
      return propertys.payload.data
    }
    else {
      if (propertys.payload.response && propertys.payload.response.data && propertys.payload.response.data.message) {
        toastify({ message: propertys.payload.response.data.message })
        if (propertys.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
      return null
    }
  }

  // const handleButtonClick = (action) => {
  //   if (drawRef.current) {
  //     switch (action) {
  //       case "create":
  //         drawRef.current.changeMode("draw_polygon");
  //         break;
  //       case "delete":
  //         drawRef.current.deleteAll();
  //         break;
  //       // Add more cases for other actions if needed
  //       default:
  //         break;
  //     }
  //   }
  // };

  function handleContinue() {
    navigation_continue("/reservations")
  }

  return (
    <div>
      <div className="add-main">
        <div className="outline-container">
          <div className="left-side">


            <div className="">
              <div
                ref={mapContainerRef}
                style={{
                  width: "100%",
                  height: "813px",
                  borderRadius: "10px"
                }}
              />
            </div>
          </div>

          {property && property.title ? <div className="right-side">
            <div className="outline-top">
              <div className="add-top">
                <h2>Property Details</h2>
              </div>
              <div className="pro-detailsdiv">
                <div className="pro-details">
                  <p className="th-p">Property Name:</p>
                  <p className="td-p">{property.title}</p>
                </div>

                <div className="pro-details">
                  <p className="th-p">Category:</p>
                  <p className="td-p">{property.category}</p>
                </div>
                <div className="pro-details">
                  <p className="th-p">City:</p>
                  <p className="td-p">{property.city}</p>
                </div>
                <div className="pro-details">
                  <p className="th-p">County/Parish:</p>
                  <p className="td-p">{property.county}</p>
                </div>
                <div className="pro-details">
                  <p className="th-p">State:</p>
                  <p className="td-p">{property.state}</p>
                </div>
                <div className="pro-details">
                  <p className="th-p">Country:</p>
                  <p className="td-p">{property.country}</p>
                </div>
              </div>
            </div>
            <div className="outline-top outline-bottom">
              <div className="add-top">
                <h2>Selected Area Details</h2>
              </div>

              <div className="pro-detailsdiv">
                <div className="pro-details">
                  <p className="th-p">Address:</p>
                  <p className="td-p">
                    {property.areaaddress}
                  </p>
                </div>

                <div className="pro-details">
                  <p className="th-p">Total Area:</p>
                  <p className="td-p">{property.totalarea} {property.totalareaunit}</p>
                </div>
                <div className="pro-details">
                  <p className="th-p">Coordinates:</p>
                  <p className="td-p">Latitude {property.latlng.lat} & Longitude {property.latlng.lng}</p>
                </div>
              </div>
            </div>

            <div className="slot-btndiv prodetails-btn">
              <button className="add-btn" onClick={handleContinue}>
                Continue
              </button>
            </div>
          </div> : ''}
        </div>
        {property && property.title ? '':<div className="loader spacing-top-propertydetail"></div>}
      </div>
    </div>
  )
}
