import React, { useState, useEffect } from "react";
import "./style.css";
import { useLocation, useNavigate ,useParams } from "react-router-dom";
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import Xicon from "../assets/Delete Icon.svg";
import { toastify, userlogout, clearuser, dateToMDY } from "../redux/controller";

export default function ReservationDetails() {
  let Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { state } = location;
  // const { reservationdetails } = state || {};

  const [reservationdetailsUpdated, setReservationData] = useState();
  const [step1inputField, setstep1InputField] = useState({
    cancelreason: ""
  })
  const [cancelSlotName, setCancelledSlotName] = useState('');
  const [cancelSlotDate, setCancelledSlotDate] = useState('');

  const [totalAmount, setTotalAmount] = useState(0);
  const [refundAmount, setRefundableAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnLoading] = useState(false);

  const usertoken = useSelector((state) => state.userdata.usertoken);

  useEffect(()=>{
    getReservationDetails();
  },[])

  useEffect(() => {
    if (reservationdetailsUpdated) {
      if (reservationdetailsUpdated?.cart != null) {
        // Calculate total amount by checking for stored price or deriving it from selectedarea.dates
        const calculatedTotal = reservationdetailsUpdated.cart.reduce((total, cart) => {
          let price = cart.selectedslot.price;

          // If price is not available, find the price from selectedarea.dates based on selecteddate
          if (!price) {
            const selectedDate = new Date(cart.selecteddate);
            const matchingDate = cart.selectedarea.dates.find(dateRange => {
              const startDate = new Date(dateRange.start);
              const endDate = new Date(dateRange.end);
              return selectedDate >= startDate && selectedDate <= endDate;
            });

            if (matchingDate) {
              price = matchingDate.price;
            }
          }

          return total + (price || 0);
        }, 0);

        setTotalAmount(calculatedTotal || reservationdetailsUpdated?.paymentdetail.total);
      } else {
        setTotalAmount(reservationdetailsUpdated?.paymentdetail.total);
      }

      setLoading(false);
    }
  }, [reservationdetailsUpdated]);

  const [inputValue, setInputValue] = useState('');

  const cancelTheSlot = async () => {
    if(btnloading){
      return
    }
    if(!step1inputField.cancelreason){
      setCancelledSlotDate('')
      setCancelledSlotName('')
      toastify({ message: 'Cancellation reason required' })
      return
    }
    setbtnLoading(true)
    const cancelledreservation = await dispatch(fetchData({
      apiurl: "/reservation/cancelslotbooking",
      reservationId: reservationdetailsUpdated._id,
      isSlotCancelled: true,
      selecteddate: cancelSlotDate,
      selectedslotname: cancelSlotName,
      cancellationreason: step1inputField.cancelreason,
      headers: { "x-access-token": usertoken }
    }));
    setCancelledSlotDate('')
    setCancelledSlotName('')
    setstep1InputField({ cancelreason: "" })
    if (cancelledreservation && cancelledreservation.payload && cancelledreservation.payload.status === 200) {
      toastify({ message: 'Slot Cancelled Successfully' })
      setReservationData(cancelledreservation.payload.userreservation);
      setbtnLoading(false)
    }
    else{
      if (cancelledreservation.payload.response && cancelledreservation.payload.response.data && cancelledreservation.payload.response.data.message == "Invalid token provided!") {
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
  };


  const getReservationDetails = async () => {
    const reservationData = await dispatch(fetchData({
      apiurl: "/reservation/single",
      reservationid: id,
      headers: { "x-access-token": usertoken }
    }));
    if (reservationData && reservationData.payload && reservationData.payload.status === 200) {
      setReservationData(reservationData.payload.data);
      setRefundableAmount(reservationData.payload.remainingRefundAmount/100)
    }
    else{
      if (reservationData.payload.response && reservationData.payload.response.data && reservationData.payload.response.data.message == "Invalid token provided!") {
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
  };



  function convertdate(date) {
    return date.substring(6, 8) + "/" + date.substring(9, 11) + "/" + date.substring(1, 5)
  }
  
  const refundPrice = async () => {
    if (inputValue.length < 1) {
      toastify({ message: 'Please enter the price' })
      return
    }

    const amountInCents = inputValue * 100;

    const refundData = await dispatch(fetchData({
      apiurl: "/reservation/refundpayment",
      reservationId: reservationdetailsUpdated._id,
      price: amountInCents,
      headers: { "x-access-token": usertoken }
    }));
    setInputValue('')
    getReservationDetails()
    toastify({ message: refundData.payload.message })

  };

  function handlepopupclose() {
    setCancelledSlotDate('')
    setCancelledSlotName('')
  }

  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleCancelSlot = async (slotname, slotdate, isSlotAlreadyCancelled) => {
    if (isSlotAlreadyCancelled) {
      return
    }
    setCancelledSlotDate(slotdate)
    setCancelledSlotName(slotname)
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  if (loading) {
    return <div>Loading...</div>; // Show loader while loading
  }

  return (
    <>
      <div className="profile-details">
        <div className="profile-top">
          <h1>Reservation Details</h1>
        </div>

        <div className="profile-details-main">
          {/* Reservation details like hunter name, property name, etc. */}
          <div className="profile-details-content">
            <div className="first-part">
              <p>Hunter Name:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.userid ? `${reservationdetailsUpdated.userid.firstname} ${reservationdetailsUpdated.userid.lastname}` : ''}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Property Name:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.cart[0]?.property.title}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Coupon Applied:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.couponapplied?.couponcode ? reservationdetailsUpdated.couponapplied.couponcode : reservationdetailsUpdated.userid?.subscriptions?.[0]?.no_of_memberships ? "Membership" : "--"}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Coupon Discount:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.couponapplied?.discountType === 'percentage' ? '' : "$"}{reservationdetailsUpdated.couponapplied?.couponamount || "0"}{reservationdetailsUpdated.couponapplied?.discountType === 'percentage' ? '%' : ""}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Reservation ID:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated._id || "--"}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Purchased Date:</p>
            </div>
            <div className="second-part">
              <p>{convertdate(JSON.stringify(reservationdetailsUpdated.createdAt))}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Reservation Status:</p>
            </div>
            <div className="second-part">
              <tr
                className={
                  reservationdetailsUpdated.orderstatus.toLowerCase() === "cancel"
                    ? "upcoming_cancel"
                    : reservationdetailsUpdated.orderstatus.toLowerCase() === "pending"
                      ? "upcoming_pending" : reservationdetailsUpdated.orderstatus.toLowerCase() ===
                        "cancelled"
                        ? "upcoming_cancelled"
                        : "upcoming_booked"
                }>
                <td>
                  <button
                    className="reservation-actionbtn"
                  >
                    <i className={reservationdetailsUpdated.orderstatus.toUpperCase() === 'PENDING' ? "fa-regular fa-clock" : reservationdetailsUpdated.orderstatus.toUpperCase() === 'BOOKED' ? "fa-solid fa-circle-check" : "fa-solid fa-circle-xmark"}></i>
                    {reservationdetailsUpdated.orderstatus.toUpperCase()}
                  </button>
                </td>
              </tr>
            </div>
          </div>
        </div>
      </div>

      <div className="resv_section_grid">
        <div className="add-table Reservation_details_table">
          <table className="table">
            <thead>
              <tr>
                <th>Slot ID</th>
                <th>Selected Date</th>
                <th>Cost</th>
                <th>Action</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              {reservationdetailsUpdated.cart.map((cart) => {
                let price = cart.selectedslot.price;
                if (!price) {
                  const selectedDate = new Date(cart.selecteddate);
                  const matchingDate = cart.selectedarea.dates.find(dateRange => {
                    const startDate = new Date(dateRange.start);
                    const endDate = new Date(dateRange.end);
                    return selectedDate >= startDate && selectedDate <= endDate;
                  });

                  if (matchingDate) {
                    price = matchingDate.price;
                  }
                }

                return (
                  <tr key={cart._id}>
                    <td>{cart.selectedslot.name}</td>
                    <td>{convertdate(JSON.stringify(cart.selecteddate))}</td>
                    <td>{price ? `$${price}` : 'N/A'}</td>
                    <td>
                      <button
                        className={cart.isSlotCancelled != null ? cart.isSlotCancelled ? "cancel-slot-button-cancel":"cancel-slot-button":"cancel-slot-button"}
                        onClick={() => handleCancelSlot(cart.selectedslot.name, cart.selecteddate, cart.isSlotCancelled != null ? cart.isSlotCancelled ? true : false : false)}
                      >
                        {cart.isSlotCancelled != null ? cart.isSlotCancelled ? "Cancelled" : "Cancel" : "Cancel"}
                      </button>
                    </td>
                    <td>{cart.cancellationreason?cart.cancellationreason:"--"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* <div className="side_cart_col">
          <div className="total-amount text-start">
            <p>Total Amount: <b>${totalAmount}</b></p>
          </div>

          <div className="refund-amount text-start">
            <p>Refundable Amount: <b>${refundAmount}</b></p>
          </div>

          <div className="input-section">
            <p>Enter Refund amount:</p>

            <input
              type="number"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>

          <div className="refund-button">
            <button className="refund-action-button" onClick={refundPrice} >
              Refund
            </button>
          </div>
        </div> */}
      </div>

      {cancelSlotName && cancelSlotDate && (
        <div className="managecou-popup">
          <div className="managecou-content res-delete">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure?</h1>
              <p>Are you sure you want to cancel this slot.</p>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="cancelreason"
                  onChange={inputsHandler}
                  value={step1inputField.cancelreason}
                ></textarea>
                <label for="floatingTextarea2">Reason...</label>
              </div>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                No
              </button>
              <button className="btn2" onClick={cancelTheSlot}>
                { btnloading ? <div className="loaders"></div> : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
