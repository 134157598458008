import React, {useState, useEffect} from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import Profileimg from "../assets/user.png";
import Locationicon from "../assets/placeholder 1.png";
import Messageicon from "../assets/envelope 1.png";
import { useSelector } from 'react-redux';

export default function Profile() {
  const Navigate = useNavigate();

  const handleEditprofile= () => {
    Navigate("/editprofile");
  };
  const [userdata, setuserdata] = useState({});
  const usertoken = useSelector((state) => state.userdata.usertoken)
  const userinfo = useSelector((state) => state.userdata.userdata)
  useEffect(() => {
    console.log('userinfo')
    console.log(userinfo)
    if(userinfo){
      setuserdata(userinfo)  
    }
  }, [])

  var stripeEmail  = '';
  if(userdata.stripeemail !=null && userdata.stripeemail.length>0){
    stripeEmail = userdata.stripeemail;
  }else if(userdata.stripeaccountdata!=null){
    if(userdata.stripeaccountdata.email!=null){
      stripeEmail = userdata.stripeaccountdata.email;
    }
  }
  return (
    <div className="profile-main">
      <div className="profile-name">
        <div className="profile-img">
        {userdata.image?<img src={process.env.REACT_APP_BASEURL+"/"+userdata.image} alt="Profileimg" /> :<img src={Profileimg} alt="Profileimg" />}
        </div>

        <div className="profile-contentmain">
          <h1>{userdata.firstname} {userdata.lastname}</h1>
          <div className="profile-content">
            <div className="profile-content-icon">
               <img src={Locationicon} alt="Locationicon" />
              <p>{userdata.livein} </p>
            </div>
            <div className="message-content-icon">
               <img src={Messageicon} alt="Messageicon" />
              <p>{userdata.email} </p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile-details">
        <div className="profile-top">
          <h1>Profile Details</h1>
          <button onClick={handleEditprofile}>Edit Profile</button>
        </div>

        <div className="profile-details-main">
        <div className="profile-details-content">
          <div className="first-part">
            <p>First Name:</p>
          </div>
          <div className="second-part">
            <p>{userdata.firstname}</p>
          </div>
        </div>

        <div className="profile-details-content">
          <div className="first-part">
            <p>Last Name:</p>
          </div>
          <div className="second-part">
            <p>{userdata.lastname}</p>
          </div>
        </div>

        <div className="profile-details-content">
          <div className="first-part">
            <p>Email Address:</p>
          </div>
          <div className="second-part">
            <p>{userdata.email}</p>
          </div>
        </div>

        <div className="profile-details-content">
          <div className="first-part">
            <p className="first-part-span">Email for receiving stripe connect payment</p>
            <span>(booking fees, refund security fees, etc)</span>
          </div>
          <div className="second-part">
            <p>{stripeEmail}</p>
          </div>
        </div>

        <div className="profile-details-content">
          <div className="first-part">
            <p>Phone Number:</p>
          </div>
          <div className="second-part">
            <p>{userdata.phonenumber}</p>
          </div>
        </div>

        <div className="profile-details-content">
          <div className="first-part">
            <p>I live in:</p>
          </div>
          <div className="second-part">
            <p>{userdata.livein}</p>
          </div>
        </div>

        <div className="profile-details-content">
          <div className="first-part">
            <p>Website:</p>
          </div>
          <div className="second-part">
            <p>{userdata.website}</p>
          </div>
        </div>

        <div className="profile-details-content">
          <div className="first-part">
            <p>About Me:</p>
          </div>
          <div className="second-part">
            <p>{userdata.aboutme}</p>
          </div>
        </div>

        </div>

      </div>
    </div>
  );
}
