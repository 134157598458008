import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Sidebar from "./Sidebar/Sidebar";
import Login from "./Components/Login";
import SignUp from "./Components/Sign_up";
import ForgotPass from "./Components/Forgot_pass";
import ChangePass from "./Components/Change_pass";
import Payments from "./Components/Payments";
import Addproperty from "./Pages/Addproperty";
import Slotproperty from "./Pages/Slotproperty";
import Propertyreview from "./Pages/Propertyreview";
import Managecoupons from "./Pages/Managecoupons";
import Createcoupon from "./Pages/Createcoupon";
import Huntersreview from "./Pages/Huntersreview";
import Realtracking from "./Pages/Realtracking";
import Managearea from "./Pages/Managearea";
import Reservations from "./Pages/Reservations";
import Hunters from "./Pages/Hunters";
import HunterDetails from "./Pages/HunterDetails";
import Profile from "./Components/Profile";
import Editprofile from "./Components/Editprofile";
import PropertyDetails from "./Pages/PropertyDetails";
import ReservationDetails from "./Pages/ReservationDetails";
import RealtrackingDetails from "./Pages/RealtrackingDetails";
import Chats from "./Pages/chats/chats";
import ChangePassword from "./Components/ChangePassword";
import UpcomingReservationsid from "./Pages/UpcomingReservationsid"
import ReservetonsidHistoryid from "./Pages/ReservetonsidHistoryid"
import "./Components/App.css";
import "./Pages/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastify, userlogout } from "./redux/controller";
import PropertyDetailsMap from "./Pages/PropertyDetailsMap";

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={5000}
      />
      <Router>
        <Routes>
          <Route
            exact
            path="/addproperty"
            element={
              <Sidebar>
                <Addproperty />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/managearea"
            element={
              <Sidebar>
                <Managearea />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/slotproperty"
            element={
              <Sidebar>
                <Slotproperty />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/propertyreview"
            element={
              <Sidebar>
                <Propertyreview />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/managecoupons"
            element={
              <Sidebar>
                <Managecoupons />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/createcoupon"
            element={
              <Sidebar>
                <Createcoupon />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/realtracking"
            element={
              <Sidebar>
                <Realtracking />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/huntersreview"
            element={
              <Sidebar>
                <Huntersreview />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/reservations"
            element={
              <Sidebar>
                <Reservations />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/hunters"
            element={
              <Sidebar>
                <Hunters />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/huntersdetails"
            element={
              <Sidebar>
                <HunterDetails />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Sidebar>
                <Profile />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/editprofile"
            element={
              <Sidebar>
                <Editprofile />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/propertydetails"
            element={
              <Sidebar>
                <PropertyDetails />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/propertydetailsmap"
            element={
              <Sidebar>
                <PropertyDetailsMap />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/realtrackingdetails"
            element={
              <Sidebar>
                <RealtrackingDetails />
              </Sidebar>
            }
          />

          <Route
            exact
            path="/upcomingreservations"
            element={
              <Sidebar>
                <UpcomingReservationsid />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/reservetonsidhistoryid"
            element={
              <Sidebar>
                <ReservetonsidHistoryid />
              </Sidebar>
            }
          />


          <Route
            exact
            path="/chats"
            element={
              <Sidebar>
                <Chats />
              </Sidebar>
            }
          />

          <Route
            exact
            path="/reservationdetails/:id"
            element={
              <Sidebar>
                <ReservationDetails />
              </Sidebar>
            }
          />

          {/* Routes without Sidebar */}
          <Route exact path="/" element={<Login />} />
          <Route exact path="/sign_up" element={<SignUp />} />
          <Route exact path="/forgot_pass" element={<ForgotPass />} />
          <Route exact path="/change_pass" element={<ChangePass />} />
          <Route exact path="/changepassword" element={<ChangePassword />} />
          <Route exact path="/payments" element={<Payments />} />
        </Routes>
      </Router></>
  );
}

export default App;
