import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { Link,  useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { toastify, userlogout } from "../redux/controller";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import {format} from 'date-fns';

export default function UpcomingReservationsid() {
  const dispatch = useDispatch()
  const initialized = useRef(false)
  const usertoken = useSelector((state) => state.userdata.usertoken);
  const search = useLocation().search;
  const userid = new URLSearchParams(search).get("user");
  const [userdetail, setuserdetail] = useState({});
  const [userdetailreservations, setuserdetailreservations] = useState([]);
  useEffect(() => {
    if (!initialized.current) {
        initialized.current = true
      if (userid) {
        getuserdetail()
      }
    }
  }, []);

  async function getuserdetail(){

    var userdetail = await dispatch(
      fetchData({
        apiurl: "/auth/user/detail",
        fetchid: userid,
        headers: { "x-access-token": usertoken }
      })
    );

    if (userdetail && userdetail.payload && userdetail.payload.status === 200) {
      setuserdetail(userdetail.payload.data);
      
      if (userdetail.payload.data.reservations && userdetail.payload.data.reservations.length) {
        // Create a shallow copy of the reservations array before sorting
        var reservation = [...userdetail.payload.data.reservations];
        
        reservation.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        
        setuserdetailreservations(reservation);
      }
    }
    else{
      if(userdetail.payload && userdetail.payload.message){
        toastify({message: userdetail.payload.message})
      }
    }
  }

  const upcomingdataid = [
    {
      id: 1,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Dave Blaster",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 2,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Big Boy” 157 acres in Melissa TXr",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 3,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Dave Blaster",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 4,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Big Boy” 157 acres in Melissa TXr",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 5,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Dave Blaster",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    },
    {
      id: 6,
      payment_id: "ch_1covYHAjkok0sz1xsg",
      property_name: "Big Boy” 157 acres in Melissa TXr",
      reservation_date: "07/05/2023",
      slot_id: "A1 -13",
      cost: "$90.00",
      coupon_used: "full",
      total: "$80.00",
      reservation_id: "406235",
      action: "cancel"
    }
  ];
  
  function convertdate(date) {
    return date.substring(6, 8) + "/" + date.substring(9, 11) + "/" + date.substring(1, 5)
  }
  
  function inputhistoryperPage(e) {
    setreservationupcomingidperPage(e.target.value)
    setReservationupcomingidcurrentPage(1);
  }

  const handlereservationupcomingidPageClick = (selectedPage) => {
    setReservationupcomingidcurrentPage(selectedPage.selected+1);
  };

  // Calculate start and end index for the current page
  const [
    reservationupcomingidcurrentPage,
    setReservationupcomingidcurrentPage
  ] = useState(1);
  const [
    reservationupcomingidperPage,
    setreservationupcomingidperPage
  ] = useState(10);
  // const reservationupcomingidperPage = 10;
  const reservationupcomingidstartIndex =
    reservationupcomingidcurrentPage * reservationupcomingidperPage;
  const reservationupcomingidendIndex =
    reservationupcomingidstartIndex + reservationupcomingidperPage;

  // Get data for the current page
  const reservationupcomingidcurrentData = userdetailreservations ? userdetailreservations:[]
  // const reservationupcomingidcurrentData = userdetail && userdetail.reservations ? userdetail.reservations.slice(
  //   reservationupcomingidstartIndex,
  //   reservationupcomingidendIndex
  // ).sort((a,b)=>new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime())  :[];
  return (
    <>
    {userdetail && userdetail.user?<div>
      <div className="reservation-idtable">
        <div className="reservation_divider">
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Name</h1>
              <p>{userdetail.user.firstname} {userdetail.user.lastname}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Email ID</h1>
              <p>{userdetail.user.email}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Phone Number</h1>
              <p>{userdetail.user.phonenumber}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Account Created</h1>
              <p>{convertdate(JSON.stringify(userdetail.user.createdAt))}</p>
            </div>
          </div>
        </div>
        <div className="add-form pagination-table">
          <div className="add-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Property Name</th>
                  <th>Slot ID</th>
                  <th>Reservation Date</th>
                  <th>Cost</th>
                  <th>Coupon Used</th>
                  <th>Payment ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userdetailreservations?userdetailreservations.map((reservation, index) => (
                    index<reservationupcomingidperPage*reservationupcomingidcurrentPage && index>=reservationupcomingidperPage*(reservationupcomingidcurrentPage-1)?
                  <tr key={reservation._id} className={
                    reservation.orderstatus.toLowerCase() === "cancelled"
                      ? "history_cancelled"
                      : reservation.orderstatus.toLowerCase() ===
                        "pending"
                        ? "history_pending" : reservation.orderstatus.toLowerCase() ===
                          "completed"
                          ? "history_completed"
                          : "history_booked"}>
                    <td>
                      <Link className="pro-link" to={"/propertydetails?index="+reservation._id}>
                        {reservation.cart[0].property.title}
                      </Link>
                    </td>
                    <td>{reservation.cart.map((name) => ( <p>{name.selectedslot.name}</p> ))}</td>
                    <td>{reservation.cart.map((name) => ( <p>{name.selecteddate?convertdate(JSON.stringify(name.selecteddate)):''}</p> ))}</td>
                    <td>{reservation.paymentdetail ? reservation.paymentdetail.total:''}</td>
                    <td>{reservation.couponapplied && reservation.couponapplied.couponcode ? reservation.couponapplied.couponcode: userdetail.user.subscriptions&&userdetail.user.subscriptions.length&&userdetail.user.subscriptions[0].no_of_memberships?"Membership" : "--"}</td>
                    <td>{reservation.payment&&reservation.payment.paymentdata&&reservation.payment.paymentdata.paymenttId? reservation.payment.paymentdata.paymenttId:'--'}</td>
                    
                    <td>
                      <button className="reservation-actionbtn">
                        <i className={reservation.orderstatus.toUpperCase() == "BOOKED"?"fa-solid fa-circle-check":reservation.orderstatus.toUpperCase() == "PENDING"?"fa-regular fa-clock":"fa-solid fa-circle-xmark"}></i>
                        {reservation.orderstatus.toUpperCase()}
                      </button>
                    </td>
                    
                  </tr>:""
                )):""}
              </tbody>
            </table>
          </div>
              <div className="rowperpage">
                <div className="perpagecount">
                  Row Per Page&nbsp;
                  <select
                    onChange={inputhistoryperPage}
                    value={reservationupcomingidperPage}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={200}>200</option>
                  </select>
                </div>
          <ReactPaginate
            pageCount={Math.ceil(
              userdetailreservations.length / reservationupcomingidperPage
            )}
            pageRangeDisplayed={reservationupcomingidperPage}
            breakLabel={"..."}
            marginPagesDisplayed={2}
            onPageChange={handlereservationupcomingidPageClick}
            containerClassName={"pagination custom-pagination"}
            activeClassName={"active"}
          />
        </div>
        </div>
      </div>
    </div>:<div className="loader spacing-top"></div>}</>
  );
}
