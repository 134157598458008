import React, { useEffect, useState, useRef } from "react";
import mainlogo from "../images/splash.png";
import "./App.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify, userlogin, userlogout } from '../redux/controller';

const Login = () => {
  const initialized = useRef(false)
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch()
  const usertoken = useSelector((state) => state.userdata.usertoken)
  const [passwordicon, setpasswordicon] = useState(false);
  const [loggedin, setloggedin] = useState(false);
  // landowner@yopmail.com
  // 12345678

  const [loading, setLoading] = useState(false);
  const [inputField, setInputField] = useState({
    email: '',
    password: ''
  })

  const inputsHandler = (e) => {
    setInputField(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  let Navigate = useNavigate();
  let Navigate1 = useNavigate();
  let Navigate2 = useNavigate();

  const handleback = () => {
    Navigate("/Sign_up");
  };

  const handleType = (e) => {
    const inputType = e.target.parentElement.parentNode.getElementsByTagName('input')[0].type;
    e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = inputType === 'text' ? 'password' : 'text';
    setpasswordicon(!passwordicon);
  }

  const handleForgot = () => {
    Navigate1("/forgot_pass");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    if (inputField.email.length < 1) {
      toastify({ "message": "Please enter email" });
      return
    } else if (!validateEmail(inputField.email)) {
      toastify({ "message": "Please enter a valid email address" });
      return;
    } else if (inputField.password.length < 1) {
      toastify({ "message": "Please enter password" });
      return
    }
    setLoading(true);
    var signeduser = await dispatch(fetchData({
      "apiurl": "/auth/login",
      "email": inputField.email,
      "password": inputField.password,
      "role": "landowner"
    }));
    console.log(signeduser)
    if (signeduser && signeduser.payload && signeduser.payload.status == 200) {
      if (checked) {
        localStorage.setItem("userEmail", inputField.email)
        localStorage.setItem("userPassword", inputField.password)
      }
      localStorage.setItem("userChecked", checked)
      dispatch(userlogin({ ...signeduser.payload.data, rememberMe: checked }))
      setloggedin(true)
      if (usertoken != '') {
        Navigate("/managearea");
      }
      setLoading(false);
    }
    else {
      setLoading(false);
      dispatch(userlogout())
      toastify({ message: signeduser.payload.message })
    }
  };

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userPassword = localStorage.getItem('userPassword');

    if (userEmail != null && userEmail.length > 0) {
      setInputField(prevState => ({ ...prevState, ['email']: userEmail }))
    }
    if (userPassword != null && userPassword.length > 0) {
      setInputField(prevState => ({ ...prevState, ['password']: userPassword }))
    }

    if (!initialized.current) {
      initialized.current = true
      if (usertoken != '') {
        Navigate("/managearea");
      }
    }
    else {
      if (usertoken != '') {
        Navigate("/managearea");
      }
    }
  }, [loggedin]);

  return (
    <div className="login_section">
      <div className="w-100 text-center">
        <div className="row">
          <div className="col-lg-7 col-sm-12">
            <p className="top_links">
              Don't have an account?{" "}
              <span style={{ color: "cf6c1b" }} onClick={handleback}>
                Signup
              </span>
            </p>
            <div className="login_form_content">
              <div className="content_section">
                <form onSubmit={e => e.preventDefault()}>
                  <h2>Welcome to Landowner Panel</h2>
                  <h5>Login into your account</h5>
                  <div className="form-row">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    ></label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      name="email"
                      onChange={inputsHandler}
                      value={inputField.email}
                    />
                  </div>
                  <div className="form-row">
                    <div className="input-group">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={inputsHandler}
                        value={inputField.password}
                      />
                      <button
                        type="button"
                        className="eye-icon btn btn-outline-secondary"
                        onClick={handleType}
                      >
                        {passwordicon ? <i className="fa fa-eye" aria-hidden="true"></i> :
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                      </button>
                    </div>
                  </div>
                  <div className="forget_row">
                    <div className="form-checks">
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                        className="form-check-input"
                        id="rememberMeCheckbox"
                      />
                      <label className="form-check-label" htmlFor="rememberMeCheckbox">
                        Remember me
                      </label>
                    </div>
                    <p className="mb-0" onClick={handleForgot}>Forgot your Password?</p>{" "}
                  </div>
                  <div className="form-row w-100 login_btn_colum">
                    <button
                      type="submit"
                      className="users_btn change-passbtn "
                      onClick={handleLogin}
                    >
                      {loading ? <div className="loader"></div> :  'Login'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-sm-12">
            <div className="image_section " style={{ textAlign: "end" }}>
              <img src={mainlogo} alt="mainlogo"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
