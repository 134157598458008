import ReactPaginate from "react-paginate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';
import {format} from 'date-fns';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";



export default function HuntersDetails(props) {
  const location = useLocation();
  const { state } = location;
  const { id, hunter_name, email, phone_number, createdAt } = state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");

  const [hunterId, setHunterId] = useState("");
  const [hunterName, setHunterName] = useState("");
  const [hunterEmail, setHunterEmail] = useState("");
  const [hunterPhoneNumber, setHunterPhoneNumber] = useState("");
  const [hinterCreatedAt, setHunterCreatedAt] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const [
    reservationupcomingidperPage,
    setreservationupcomingidperPage
  ] = useState(10);

  const [
    reservationupcomingidcurrentPage,
    setReservationupcomingidcurrentPage
  ] = useState(1);
  // const usertoken = useSelector((state) => state.userdata.usertoken);
  const usertoken = localStorage.getItem('usertoken');

  const [status, setStatus] = useState('Loading...');
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      if(id){
        setHunterId(id)
        setHunterName(hunter_name)
        setHunterEmail(email)
        setHunterPhoneNumber(phone_number)
        setHunterCreatedAt(createdAt)
        getAllHunters()
      }
      else{
        window.location.replace(window.location.origin)
      }
      return () => {
        effectRan.current = true;
      };
    }
  }, [dispatch, usertoken, navigate]);


  function convertdate(date) {
    return date.substring(6, 8) + "/" + date.substring(9, 11) + "/" + date.substring(1, 5)
  }

  function gotopropertydetail(reservation, today){
    console.log(reservation)
    var element = reservation.cart[0]
    var url = today?"/propertydetailsmap?index=" + reservation.id+"&all="+element.property._id:"/propertydetailsmap?index=" + reservation.id
    navigate(url, {state:{             
      property: element.property,
      id: element.property.id,
      property_name: element.property.title,
      category: element.property.category,
      stateLocal: element.property.state,
      county: element.property.country,
      city: element.property.city,
      zipcode: element.property.zipcode,
      totalarea: element.property.totalarea,
      action: element.property.approved == null ? 'Pending' : element.property.approved === "false" ? 'Rejected' :  element.property.approved === "paused" ? 'Paused': 'Approved',
      landownerId: element?.property.userid}})
  }

  const getAllHunters = async (searchQuery = "") => {

    setSearchResults([]);
    setStatus('Loading...')
    var reservationList = await dispatch(
      fetchData({
        apiurl: "/reservation/allhunterreservations",
        page: 1,
        hunterId: id ?? hunterId,
        limit: 10000,
        search: searchQuery,
        headers: { "x-access-token": usertoken }
      })
    );

    if (reservationList && reservationList.payload && reservationList.payload.status === 200) {
      if (
        reservationList.payload.reservations &&
        reservationList.payload.reservations.length
      ) {
        setStatus('');
        const huntersListLocal = reservationList.payload.reservations.map((reservations) => {

        //   const selectedDate = new Date(reservations.reservations.cart[0].selecteddate);
        //   const formattedDate = `${String(selectedDate.getDate()).padStart(2, '0')}/${String(selectedDate.getMonth() + 1).padStart(2, '0')}/${selectedDate.getFullYear()}`;
        const formattedDate = reservations.reservations.cart[0].selecteddate
          console.log('formattedDate')
          console.log(formattedDate)
          const paymentAmount = reservations?.reservations?.payment?.paymentdata?.paymentAmount;
          const total = (paymentAmount == null || paymentAmount === '') ? '0' : paymentAmount;

          const paymentId = reservations.reservations?.payment?.paymentdata?.paymenttId;
          const id = (paymentId == null || paymentId === '') ? '--' : paymentId;

          return {
            id: reservations.reservations._id,
            payment_id: id,
            hunter_name: reservations.hunter?.username ?? '--',
            landowner_name: reservations.landowner?.firstname + ' ' + reservations.landowner.lastname ?? '--',
            property_name: reservations.reservations.cart[0].property.title,
            reservation_date: formattedDate,
            cart: reservations.reservations.cart,
            slot_id: reservations.reservations.cart,
            cost: reservations.reservations.paymentdetail.total ?? '0',
            coupon_discount: reservations.reservations?.couponapplied?.couponamount ?? '--',
            coupon_discount_type: reservations.reservations?.couponapplied?.discountType ?? "--",
            coupon_applied: reservations.reservations?.couponapplied?.couponcode ?? "--",
            total: total,
            reservation_id: reservations.reservations._id,
            createdAt: convertdate(JSON.stringify(reservations.reservations.createdAt)),
            createdAts: reservations.reservations.createdAt,
            action: "cancel",
          };
        });
        console.log(huntersListLocal.length)
        for(var x=0; x<huntersListLocal.length; x++){
          console.log(huntersListLocal[x])
        }
        setSearchResults(huntersListLocal);
      } else {
        setStatus('No reservations!');
      }
    } else {
      if (reservationList.payload.response && reservationList.payload.response.message) {
        if (reservationList.payload.response.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }
  }

  const handlereservationupcomingidPageClick = (selectedPage) => {
    console.log(selectedPage.selected+1)
    setReservationupcomingidcurrentPage(selectedPage.selected+1);
    console.log(reservationupcomingidcurrentPage)
  };
  // const reservationupcomingidperPage = 10;
  const reservationupcomingidstartIndex =
    reservationupcomingidcurrentPage * reservationupcomingidperPage;
  const reservationupcomingidendIndex =
    reservationupcomingidstartIndex + reservationupcomingidperPage;

  // const reservationupcomingidcurrentData = searchResults.slice(
  //   reservationupcomingidstartIndex,
  //   reservationupcomingidendIndex
  // );
  const reservationupcomingidcurrentData = searchResults
  const handleSearch = () => {
    getAllHunters(searchInput);
  };

  const handleClearSearch = () => {
    setSearchInput("");
    getAllHunters();
  };


  function inputhistoryperPage(e) {
    setreservationupcomingidperPage(e.target.value)
    setReservationupcomingidcurrentPage(1);
  }

  return (
    <div className="hunter-ditailsdiv">
      <div className="reservation-idtable">
        <div className="search-div">
            {/* <div className="header-btnicon" >
                <ArrowBackIcon />
            </div> */}
          <div className="input-wrapper">

            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Landowner Name, Property Name, Payment Id"

              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="add-btn" onClick={handleSearch}>Search</button>
        </div>

        <div className="reservation_divider">
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Name</h1>
              <p>{hunterName}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Email ID</h1>
              <p>{hunterEmail}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Phone Number</h1>
              <p>{hunterPhoneNumber}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Account Created</h1>
              <p>{hinterCreatedAt?`${convertdate(JSON.stringify(hinterCreatedAt))}`:''}</p>
            </div>
          </div>
        </div>
        <div className="add-form pagination-table">
          <div className="add-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Landowner</th>
                  <th>Property Name</th>
                  <th>Reservation Date</th>
                  <th>Slot Id</th>
                  <th>Cost</th>
                  <th>Coupon Applied</th>
                  <th>Coupon Discount</th>
                  <th>Total</th>
                  <th>Payment Id</th>
                </tr>
              </thead>
              <tbody>

                {reservationupcomingidcurrentData.length === 0 ?
                  <td colSpan="9" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      display: 'inline-block',
                    }}>
                      {status}
                    </div>
                  </td>
                  :
                  reservationupcomingidcurrentData.map((reservation, ind) => (
                    ind<reservationupcomingidperPage*reservationupcomingidcurrentPage && ind>=reservationupcomingidperPage*(reservationupcomingidcurrentPage-1)?
                    <tr key={reservation.id} className="upcoming_cancel">
                      <td>{reservation.landowner_name}</td>
                      <td ><Link className="pro-link" 
                        to={reservation.createdAts?`/propertydetailsmap?index=${reservation.id}&all=${reservation.cart[0].property._id}`:`/propertydetailsmap?index=${reservation.id}`}
                        state={{             
                          property: reservation.cart[0].property,
                          id: reservation.cart[0].property.id,
                          property_name: reservation.cart[0].property.title,
                          category: reservation.cart[0].property.category,
                          stateLocal: reservation.cart[0].property.state,
                          county: reservation.cart[0].property.country,
                          city: reservation.cart[0].property.city,
                          zipcode: reservation.cart[0].property.zipcode,
                          totalarea: reservation.cart[0].property.totalarea,
                          action: reservation.cart[0].property.approved == null ? 'Pending' : reservation.cart[0].property.approved === "false" ? 'Rejected' :  reservation.cart[0].property.approved === "paused" ? 'Paused': 'Approved',
                          landownerId: reservation.cart[0]?.property.userid}}>{reservation.property_name}</Link></td>
                      {/* <td  onClick={()=>gotopropertydetail(reservation, reservation.createdAts)}><Link className="pro-link" >{reservation.property_name}</Link></td> */}
                      <td>{reservation.reservation_date?convertdate(JSON.stringify(reservation.reservation_date)):''}</td>
                        <td>{reservation.cart.map((name) => (<p>
                          <Link className="pro-link" to={"/propertydetails?index=" + reservation.id}>{name.selectedslot.name}</Link></p>))}</td>
                      <td>${reservation.cost}</td>
                      <td>{reservation.coupon_applied}</td>
                      {
                        reservation.coupon_discount_type === '--' ?
                          <td>--</td>
                          :
                          reservation.coupon_discount_type === 'percentage' ?
                            <td>{reservation.coupon_discount}%</td>

                            :
                            <td>${reservation.coupon_discount}</td>
                      }
                      <td>${reservation.total}</td>
                      <td>{reservation.payment_id}</td>
                    </tr>:''
                  ))}
              </tbody>
            </table>
          </div>
              <div className="rowperpage">
                <div className="perpagecount">
                  Row Per Page&nbsp;
                  <select
                    onChange={inputhistoryperPage}
                    value={reservationupcomingidperPage}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={200}>200</option>
                  </select>
                </div>
          <ReactPaginate
            pageCount={Math.ceil(
              searchResults.length / reservationupcomingidperPage
            )}
            pageRangeDisplayed={reservationupcomingidperPage}
            breakLabel={"..."}
            marginPagesDisplayed={2}
            onPageChange={handlereservationupcomingidPageClick}
            containerClassName={"pagination  custom-pagination"}
            pageClassName={"page-item "}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
        </div>
      </div>
    </div>
  );
}
