import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import AddIcon from "@mui/icons-material/Add";
import Deleteicon from "../assets/trash.png";
import Editicon from "../assets/pencil.png";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout, clearuser } from "../redux/controller";
import * as turf from "@turf/turf";
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import Questionicon from "../assets/Info circle.svg"
import Xicon from "../assets/Delete Icon.svg"


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX
  

function Addproperty() {
  const initialized = useRef(false)
  var map
  var draw
  const dispatch = useDispatch();
  const [mapObject, setMap] = useState();
  const [mapObjectDraw, setMapDraw] = useState();
  const [totalarea, settotalarea] = useState(0);
  const [zoomlevel, setzoomlevel] = useState(0)
  const [geodraw, setgeodraw] = useState(0)
  const [deletearea, setdeletearea] = useState('');
  const [editslotinf, setEditslotinf] = useState(false);
  const [editselectedarea, seteditselectedarea] = useState({});
  const [suggestionlist, setsuggestionlist] = useState([]);
  const Navigategoback = useNavigate();
  const search = useLocation().search;
  const propertyid = new URLSearchParams(search).get("index");
  const propertystep = new URLSearchParams(search).get("step");
  const areaautoselect = new URLSearchParams(search).get("area");

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate());
    return tomorrow.toISOString().split("T")[0];
  };


  const [mindate, setmindate] = useState(getTomorrowDate());


  const [countries, setcountries] = useState([

    { "country": "Select Country", "code": "", "iso": "" },
    { "country": "United States", "code": "1", "iso": "US" },
    { "country": "Afghanistan", "code": "93", "iso": "AF" },
    { "country": "Albania", "code": "355", "iso": "AL" },
    { "country": "Algeria", "code": "213", "iso": "DZ" },
    { "country": "American Samoa", "code": "1-684", "iso": "AS" },
    { "country": "Andorra", "code": "376", "iso": "AD" },
    { "country": "Angola", "code": "244", "iso": "AO" },
    { "country": "Anguilla", "code": "1-264", "iso": "AI" },
    { "country": "Antarctica", "code": "672", "iso": "AQ" },
    { "country": "Antigua and Barbuda", "code": "1-268", "iso": "AG" },
    { "country": "Argentina", "code": "54", "iso": "AR" },
    { "country": "Armenia", "code": "374", "iso": "AM" },
    { "country": "Aruba", "code": "297", "iso": "AW" },
    { "country": "Australia", "code": "61", "iso": "AU" },
    { "country": "Austria", "code": "43", "iso": "AT" },
    { "country": "Azerbaijan", "code": "994", "iso": "AZ" },
    { "country": "Bahamas", "code": "1-242", "iso": "BS" },
    { "country": "Bahrain", "code": "973", "iso": "BH" },
    { "country": "Bangladesh", "code": "880", "iso": "BD" },
    { "country": "Barbados", "code": "1-246", "iso": "BB" },
    { "country": "Belarus", "code": "375", "iso": "BY" },
    { "country": "Belgium", "code": "32", "iso": "BE" },
    { "country": "Belize", "code": "501", "iso": "BZ" },
    { "country": "Benin", "code": "229", "iso": "BJ" },
    { "country": "Bermuda", "code": "1-441", "iso": "BM" },
    { "country": "Bhutan", "code": "975", "iso": "BT" },
    { "country": "Bolivia", "code": "591", "iso": "BO" },
    { "country": "Bosnia and Herzegovina", "code": "387", "iso": "BA" },
    { "country": "Botswana", "code": "267", "iso": "BW" },
    { "country": "Brazil", "code": "55", "iso": "BR" },
    { "country": "British Indian Ocean Territory", "code": "246", "iso": "IO" },
    { "country": "British Virgin Islands", "code": "1-284", "iso": "VG" },
    { "country": "Brunei", "code": "673", "iso": "BN" },
    { "country": "Bulgaria", "code": "359", "iso": "BG" },
    { "country": "Burkina Faso", "code": "226", "iso": "BF" },
    { "country": "Burundi", "code": "257", "iso": "BI" },
    { "country": "Cambodia", "code": "855", "iso": "KH" },
    { "country": "Cameroon", "code": "237", "iso": "CM" },
    { "country": "Canada", "code": "1", "iso": "CA" },
    { "country": "Cape Verde", "code": "238", "iso": "CV" },
    { "country": "Cayman Islands", "code": "1-345", "iso": "KY" },
    { "country": "Central African Republic", "code": "236", "iso": "CF" },
    { "country": "Chad", "code": "235", "iso": "TD" },
    { "country": "Chile", "code": "56", "iso": "CL" },
    { "country": "China", "code": "86", "iso": "CN" },
    { "country": "Christmas Island", "code": "61", "iso": "CX" },
    { "country": "Cocos Islands", "code": "61", "iso": "CC" },
    { "country": "Colombia", "code": "57", "iso": "CO" },
    { "country": "Comoros", "code": "269", "iso": "KM" },
    { "country": "Cook Islands", "code": "682", "iso": "CK" },
    { "country": "Costa Rica", "code": "506", "iso": "CR" },
    { "country": "Croatia", "code": "385", "iso": "HR" },
    { "country": "Cuba", "code": "53", "iso": "CU" },
    { "country": "Curacao", "code": "599", "iso": "CW" },
    { "country": "Cyprus", "code": "357", "iso": "CY" },
    { "country": "Czech Republic", "code": "420", "iso": "CZ" },
    { "country": "Democratic Republic of the Congo", "code": "243", "iso": "CD" },
    { "country": "Denmark", "code": "45", "iso": "DK" },
    { "country": "Djibouti", "code": "253", "iso": "DJ" },
    { "country": "Dominica", "code": "1-767", "iso": "DM" },
    { "country": "Dominican Republic", "code": "1-809, 1-829, 1-849", "iso": "DO" },
    { "country": "East Timor", "code": "670", "iso": "TL" },
    { "country": "Ecuador", "code": "593", "iso": "EC" },
    { "country": "Egypt", "code": "20", "iso": "EG" },
    { "country": "El Salvador", "code": "503", "iso": "SV" },
    { "country": "Equatorial Guinea", "code": "240", "iso": "GQ" },
    { "country": "Eritrea", "code": "291", "iso": "ER" },
    { "country": "Estonia", "code": "372", "iso": "EE" },
    { "country": "Ethiopia", "code": "251", "iso": "ET" },
    { "country": "Falkland Islands", "code": "500", "iso": "FK" },
    { "country": "Faroe Islands", "code": "298", "iso": "FO" },
    { "country": "Fiji", "code": "679", "iso": "FJ" },
    { "country": "Finland", "code": "358", "iso": "FI" },
    { "country": "France", "code": "33", "iso": "FR" },
    { "country": "French Polynesia", "code": "689", "iso": "PF" },
    { "country": "Gabon", "code": "241", "iso": "GA" },
    { "country": "Gambia", "code": "220", "iso": "GM" },
    { "country": "Georgia", "code": "995", "iso": "GE" },
    { "country": "Germany", "code": "49", "iso": "DE" },
    { "country": "Ghana", "code": "233", "iso": "GH" },
    { "country": "Gibraltar", "code": "350", "iso": "GI" },
    { "country": "Greece", "code": "30", "iso": "GR" },
    { "country": "Greenland", "code": "299", "iso": "GL" },
    { "country": "Grenada", "code": "1-473", "iso": "GD" },
    { "country": "Guam", "code": "1-671", "iso": "GU" },
    { "country": "Guatemala", "code": "502", "iso": "GT" },
    { "country": "Guernsey", "code": "44-1481", "iso": "GG" },
    { "country": "Guinea", "code": "224", "iso": "GN" },
    { "country": "Guinea-Bissau", "code": "245", "iso": "GW" },
    { "country": "Guyana", "code": "592", "iso": "GY" },
    { "country": "Haiti", "code": "509", "iso": "HT" },
    { "country": "Honduras", "code": "504", "iso": "HN" },
    { "country": "Hong Kong", "code": "852", "iso": "HK" },
    { "country": "Hungary", "code": "36", "iso": "HU" },
    { "country": "Iceland", "code": "354", "iso": "IS" },
    { "country": "India", "code": "91", "iso": "IN" },
    { "country": "Indonesia", "code": "62", "iso": "ID" },
    { "country": "Iran", "code": "98", "iso": "IR" },
    { "country": "Iraq", "code": "964", "iso": "IQ" },
    { "country": "Ireland", "code": "353", "iso": "IE" },
    { "country": "Isle of Man", "code": "44-1624", "iso": "IM" },
    { "country": "Israel", "code": "972", "iso": "IL" },
    { "country": "Italy", "code": "39", "iso": "IT" },
    { "country": "Ivory Coast", "code": "225", "iso": "CI" },
    { "country": "Jamaica", "code": "1-876", "iso": "JM" },
    { "country": "Japan", "code": "81", "iso": "JP" },
    { "country": "Jersey", "code": "44-1534", "iso": "JE" },
    { "country": "Jordan", "code": "962", "iso": "JO" },
    { "country": "Kazakhstan", "code": "7", "iso": "KZ" },
    { "country": "Kenya", "code": "254", "iso": "KE" },
    { "country": "Kiribati", "code": "686", "iso": "KI" },
    { "country": "Kosovo", "code": "383", "iso": "XK" },
    { "country": "Kuwait", "code": "965", "iso": "KW" },
    { "country": "Kyrgyzstan", "code": "996", "iso": "KG" },
    { "country": "Laos", "code": "856", "iso": "LA" },
    { "country": "Latvia", "code": "371", "iso": "LV" },
    { "country": "Lebanon", "code": "961", "iso": "LB" },
    { "country": "Lesotho", "code": "266", "iso": "LS" },
    { "country": "Liberia", "code": "231", "iso": "LR" },
    { "country": "Libya", "code": "218", "iso": "LY" },
    { "country": "Liechtenstein", "code": "423", "iso": "LI" },
    { "country": "Lithuania", "code": "370", "iso": "LT" },
    { "country": "Luxembourg", "code": "352", "iso": "LU" },
    { "country": "Macao", "code": "853", "iso": "MO" },
    { "country": "Macedonia", "code": "389", "iso": "MK" },
    { "country": "Madagascar", "code": "261", "iso": "MG" },
    { "country": "Malawi", "code": "265", "iso": "MW" },
    { "country": "Malaysia", "code": "60", "iso": "MY" },
    { "country": "Maldives", "code": "960", "iso": "MV" },
    { "country": "Mali", "code": "223", "iso": "ML" },
    { "country": "Malta", "code": "356", "iso": "MT" },
    { "country": "Marshall Islands", "code": "692", "iso": "MH" },
    { "country": "Mauritania", "code": "222", "iso": "MR" },
    { "country": "Mauritius", "code": "230", "iso": "MU" },
    { "country": "Mayotte", "code": "262", "iso": "YT" },
    { "country": "Mexico", "code": "52", "iso": "MX" },
    { "country": "Micronesia", "code": "691", "iso": "FM" },
    { "country": "Moldova", "code": "373", "iso": "MD" },
    { "country": "Monaco", "code": "377", "iso": "MC" },
    { "country": "Mongolia", "code": "976", "iso": "MN" },
    { "country": "Montenegro", "code": "382", "iso": "ME" },
    { "country": "Montserrat", "code": "1-664", "iso": "MS" },
    { "country": "Morocco", "code": "212", "iso": "MA" },
    { "country": "Mozambique", "code": "258", "iso": "MZ" },
    { "country": "Myanmar", "code": "95", "iso": "MM" },
    { "country": "Namibia", "code": "264", "iso": "NA" },
    { "country": "Nauru", "code": "674", "iso": "NR" },
    { "country": "Nepal", "code": "977", "iso": "NP" },
    { "country": "Netherlands", "code": "31", "iso": "NL" },
    { "country": "Netherlands Antilles", "code": "599", "iso": "AN" },
    { "country": "New Caledonia", "code": "687", "iso": "NC" },
    { "country": "New Zealand", "code": "64", "iso": "NZ" },
    { "country": "Nicaragua", "code": "505", "iso": "NI" },
    { "country": "Niger", "code": "227", "iso": "NE" },
    { "country": "Nigeria", "code": "234", "iso": "NG" },
    { "country": "Niue", "code": "683", "iso": "NU" },
    { "country": "North Korea", "code": "850", "iso": "KP" },
    { "country": "Northern Mariana Islands", "code": "1-670", "iso": "MP" },
    { "country": "Norway", "code": "47", "iso": "NO" },
    { "country": "Oman", "code": "968", "iso": "OM" },
    { "country": "Pakistan", "code": "92", "iso": "PK" },
    { "country": "Palau", "code": "680", "iso": "PW" },
    { "country": "Palestine", "code": "970", "iso": "PS" },
    { "country": "Panama", "code": "507", "iso": "PA" },
    { "country": "Papua New Guinea", "code": "675", "iso": "PG" },
    { "country": "Paraguay", "code": "595", "iso": "PY" },
    { "country": "Peru", "code": "51", "iso": "PE" },
    { "country": "Philippines", "code": "63", "iso": "PH" },
    { "country": "Pitcairn", "code": "64", "iso": "PN" },
    { "country": "Poland", "code": "48", "iso": "PL" },
    { "country": "Portugal", "code": "351", "iso": "PT" },
    { "country": "Puerto Rico", "code": "1-787, 1-939", "iso": "PR" },
    { "country": "Qatar", "code": "974", "iso": "QA" },
    { "country": "Republic of the Congo", "code": "242", "iso": "CG" },
    { "country": "Reunion", "code": "262", "iso": "RE" },
    { "country": "Romania", "code": "40", "iso": "RO" },
    { "country": "Russia", "code": "7", "iso": "RU" },
    { "country": "Rwanda", "code": "250", "iso": "RW" },
    { "country": "Saint Barthelemy", "code": "590", "iso": "BL" },
    { "country": "Saint Helena", "code": "290", "iso": "SH" },
    { "country": "Saint Kitts and Nevis", "code": "1-869", "iso": "KN" },
    { "country": "Saint Lucia", "code": "1-758", "iso": "LC" },
    { "country": "Saint Martin", "code": "590", "iso": "MF" },
    { "country": "Saint Pierre and Miquelon", "code": "508", "iso": "PM" },
    { "country": "Saint Vincent and the Grenadines", "code": "1-784", "iso": "VC" },
    { "country": "Samoa", "code": "685", "iso": "WS" },
    { "country": "San Marino", "code": "378", "iso": "SM" },
    { "country": "Sao Tome and Principe", "code": "239", "iso": "ST" },
    { "country": "Saudi Arabia", "code": "966", "iso": "SA" },
    { "country": "Senegal", "code": "221", "iso": "SN" },
    { "country": "Serbia", "code": "381", "iso": "RS" },
    { "country": "Seychelles", "code": "248", "iso": "SC" },
    { "country": "Sierra Leone", "code": "232", "iso": "SL" },
    { "country": "Singapore", "code": "65", "iso": "SG" },
    { "country": "Sint Maarten", "code": "1-721", "iso": "SX" },
    { "country": "Slovakia", "code": "421", "iso": "SK" },
    { "country": "Slovenia", "code": "386", "iso": "SI" },
    { "country": "Solomon Islands", "code": "677", "iso": "SB" },
    { "country": "Somalia", "code": "252", "iso": "SO" },
    { "country": "South Africa", "code": "27", "iso": "ZA" },
    { "country": "South Korea", "code": "82", "iso": "KR" },
    { "country": "South Sudan", "code": "211", "iso": "SS" },
    { "country": "Spain", "code": "34", "iso": "ES" },
    { "country": "Sri Lanka", "code": "94", "iso": "LK" },
    { "country": "Sudan", "code": "249", "iso": "SD" },
    { "country": "Suriname", "code": "597", "iso": "SR" },
    { "country": "Svalbard and Jan Mayen", "code": "47", "iso": "SJ" },
    { "country": "Swaziland", "code": "268", "iso": "SZ" },
    { "country": "Sweden", "code": "46", "iso": "SE" },
    { "country": "Switzerland", "code": "41", "iso": "CH" },
    { "country": "Syria", "code": "963", "iso": "SY" },
    { "country": "Taiwan", "code": "886", "iso": "TW" },
    { "country": "Tajikistan", "code": "992", "iso": "TJ" },
    { "country": "Tanzania", "code": "255", "iso": "TZ" },
    { "country": "Thailand", "code": "66", "iso": "TH" },
    { "country": "Togo", "code": "228", "iso": "TG" },
    { "country": "Tokelau", "code": "690", "iso": "TK" },
    { "country": "Tonga", "code": "676", "iso": "TO" },
    { "country": "Trinidad and Tobago", "code": "1-868", "iso": "TT" },
    { "country": "Tunisia", "code": "216", "iso": "TN" },
    { "country": "Turkey", "code": "90", "iso": "TR" },
    { "country": "Turkmenistan", "code": "993", "iso": "TM" },
    { "country": "Turks and Caicos Islands", "code": "1-649", "iso": "TC" },
    { "country": "Tuvalu", "code": "688", "iso": "TV" },
    { "country": "U.S. Virgin Islands", "code": "1-340", "iso": "VI" },
    { "country": "Uganda", "code": "256", "iso": "UG" },
    { "country": "Ukraine", "code": "380", "iso": "UA" },
    { "country": "United Arab Emirates", "code": "971", "iso": "AE" },
    { "country": "United Kingdom", "code": "44", "iso": "GB" },
    { "country": "Uruguay", "code": "598", "iso": "UY" },
    { "country": "Uzbekistan", "code": "998", "iso": "UZ" },
    { "country": "Vanuatu", "code": "678", "iso": "VU" },
    { "country": "Vatican", "code": "379", "iso": "VA" },
    { "country": "Venezuela", "code": "58", "iso": "VE" },
    { "country": "Vietnam", "code": "84", "iso": "VN" },
    { "country": "Wallis and Futuna", "code": "681", "iso": "WF" },
    { "country": "Western Sahara", "code": "212", "iso": "EH" },
    { "country": "Yemen", "code": "967", "iso": "YE" },
    { "country": "Zambia", "code": "260", "iso": "ZM" },
    { "country": "Zimbabwe", "code": "263", "iso": "ZW" }])
  const [step1inputField, setstep1InputField] = useState({
    title: "",
    category: "Dove Hunting",
    lease: "",
    city: "",
    state: "",
    county: "",
    country: "",
    zipcode: "",
    description: "",
    features: []
  });
  const [step2inputField, setstep2InputField] = useState({
    areaname: "",
    slotdetail: "",
    areacolor: "#000",
    dates: [{
      start: "",
      end: "",
      price: ""
    }]
  });
  const [stepEinputField, setstepEInputField] = useState({
    areaname: "",
    slotdetail: "",
    areacolor: "#000",
    dates: [{
      start: "",
      end: "",
      price: ""
    }]
  });

  const [mapstyles, setmapstyles] = useState([
    {
      name: "Map",
      value: "mapbox://styles/mapbox/navigation-day-v1"
    },
    {
      name: "Satellite",
      value: "mapbox://styles/mapbox/satellite-streets-v12"
    }
  ]);
  const [selectedmapstyles, setselectedmapstyles] = useState("mapbox://styles/mapbox/satellite-streets-v12");
  const [coords, setcoords] = useState({ "lat": 0, "lng": 0 });
  const [colorStore, setColorStore] = useState("#fc2403");
  function selectmapstyle(e) {
    console.log(e.target.value)
    setselectedmapstyles(e.target.value)
    mapObject.setStyle(e.target.value)
  }

  function handlepopupclose() {
    setdeletearea(false);
  }
  async function handlepopupclosedelete() {
    console.log(deletearea)
    var response = await dispatch(fetchData({
      "apiurl": "/property/delete/area",
      _id: property._id,
      areaid: deletearea,
      headers: { "x-access-token": usertoken }
    }))
    console.log(response)
    if (response && response.payload && response.payload.status == 200) {
      toastify({ message: response.payload.message })
    } else {
      if (response.payload.response && response.payload.response.data && response.payload.response.data.message) {
        toastify({ message: response.payload.response.data.message })
      }
      else if(response.payload && response.payload.status == 400 && response.payload.message) {
        toastify({ message: response.payload.message })
      }
      if (response.payload.response.data.message == "Invalid token provided!") {
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
    setdeletearea(false);
    getprop()
  }

  const handleDeleteclick = (e) => {
    console.log(Number(e.target.id.split("_")[1]))
    setdeletearea(property.area[Number(e.target.id.split("_")[1])]._id);
  }

  const handleEditclick = (e) => {
    // Create a deep copy of the property object
    const areaIndex = Number(e.target.id.split("_")[1]);
    if (property && property.area) {
      const pro = JSON.parse(JSON.stringify(property.area[areaIndex]));

      // Check if dates exist and modify them
      if (pro.dates && pro.dates.length) {
        for (let x = 0; x < pro.dates.length; x++) {
          console.log(pro.dates[x].start, '=====pro.dates[x].start====');
          pro.dates[x].start = pro.dates[x].start.toString().substring(0, 10);
          pro.dates[x].end = pro.dates[x].end.toString().substring(0, 10);
        }
      }

      // Update the state with the modified object
      seteditselectedarea(pro);
      setstepEInputField(pro);
      setEditslotinf(true);
    }
    else {
      const pro = JSON.parse(JSON.stringify(e.property.area[areaIndex]));

      // Check if dates exist and modify them
      if (pro.dates && pro.dates.length) {
        for (let x = 0; x < pro.dates.length; x++) {
          console.log(pro.dates[x].start, '=====pro.dates[x].start====');
          pro.dates[x].start = pro.dates[x].start.toString().substring(0, 10);
          pro.dates[x].end = pro.dates[x].end.toString().substring(0, 10);
        }
      }

      // Update the state with the modified object
      seteditselectedarea(pro);
      setstepEInputField(pro);
      setEditslotinf(true);
    }
  };


  const handleClosepopup = () => {
    setEditslotinf(!editslotinf);
  };
  const handleGoback = () => {
    Navigategoback("/slotproperty");
  };

  const changepropertyoutline = (colorId) => {
    const newStyles = mapObjectDraw.options.styles.map(style => {
      return {
        ...style,
        paint: {
          ...style.paint,
          "line-color": colorId,
          "fill-color": colorId,
          "circle-color": colorId,
          "fill-outline-color": colorId
        }
      };
    });

    // Update the draw options with new styles
    mapObjectDraw.options.styles = newStyles;

    // Re-add the control to apply new styles
    mapObject.removeControl(mapObjectDraw);
    mapObject.addControl(mapObjectDraw);
  }
  const colorHandle = (colorId) => {
    setColorStore(colorId);
    changeColor(colorId);
    changepropertyoutline(colorId)

  };
  // Function to change the style color
  const changeColor = (color) => {
    console.log("drawFeatureID")
    console.log(drawFeatureID)
    if (drawFeatureID !== '' && typeof draw === 'object') {

      // add whatever colors you want here...
      if (color === 'black') {
        draw.setFeatureProperty(drawFeatureID, 'portColor', '#000');
      } else if (color === 'red') {
        draw.setFeatureProperty(drawFeatureID, 'portColor', '#ff0000');
      } else if (color === 'green') {
        draw.setFeatureProperty(drawFeatureID, 'portColor', '#008000');
      }

      var feat = draw.get(drawFeatureID);
      draw.add(feat)
    }
  }
  const handleInputChange = (event) => {
    const selectedColor = event.target.value;
    setColorStore(selectedColor);
    changepropertyoutline(selectedColor)
  }

  function removesuggestionlist() {
    setTimeout(() => {
      setsuggestionlist([])
    }, 800)
  }

  function validateNewPlantsForm(latlng){
    var latlngArray = latlng.split(",");
    var response = {status: "invalid"}
    if(latlngArray.length==2){
        var lat = latlngArray[0]
        var lng = latlngArray[1]
        if(lat>-90 && lat<90 && lng>-180 && lng<180){
            response = {status: "valid", lat, lng}
        }
    }
    return response
  }

  function checklatlng(e){
    var latlng = validateNewPlantsForm(e.target.name)
    console.log(latlng)
    if(latlng.status == 'valid'){
      mapObject.setCenter([latlng.lng, latlng.lat])
      console.log("Delect Lat lng")
      console.log(latlng)
      return
    }
  }

  const inputsHandler = (e) => {
    console.log(e.target.name);
    if (e.target.name == 'city' && e.target.value.length > 2) {
      var index = countries.findIndex(e => e.country == step1inputField.country)
      var countrycodeurl
      if (step1inputField.country != '' && index > -1) {
        countrycodeurl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target.value}.json?country=${countries[index].iso}&access_token=${mapboxgl.accessToken}`
      }
      else {
        countrycodeurl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target.value}.json?&access_token=${mapboxgl.accessToken}`
      }
      fetch(countrycodeurl)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log("data")
          console.log(data.features)
          if (data.features && data.features.length) {
            setsuggestionlist(data.features)
          }
          else {
            setsuggestionlist([])
          }
        })
    }
    else {
      setsuggestionlist([])
    }
    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const inputsHandlerarea = (e) => {
    console.log(e.target.name);
    if (e.target.name == 'areacolor') {
      setSelectedColor(e.target.value)
    }
    setstep2InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  const inputsHandlerareaedit = (e) => {
    console.log(e.target.name);
    setstepEInputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const inputsHandlerareadatesedit = (e) => {
    const { name, value } = e.target;
    const modifiedFood = stepEinputField.dates.map((i, index) => {
      if (index === Number(name.split('_')[2])) {
        i[name.split('_')[1]] = value;
      }
      return i
    })
    var prop2 = {
      areaname: stepEinputField.areaname,
      slotdetail: stepEinputField.slotdetail,
      areacolor: stepEinputField.areacolor,
      dates: modifiedFood
    }
    setstepEInputField(pre => pre = prop2)
    console.log(stepEinputField.dates)
  };
  const inputsHandlerareadates = (e) => {
    const { name, value } = e.target;
    const modifiedFood = step2inputField.dates.map((i, index) => {
      if (index === Number(name.split('_')[2])) {
        i[name.split('_')[1]] = value;
      }
      return i
    })
    var prop2 = {
      areaname: step2inputField.areaname,
      slotdetail: step2inputField.slotdetail,
      areacolor: step2inputField.areacolor,
      dates: modifiedFood
    }
    setstep2InputField(pre => pre = prop2)
    console.log(step2inputField.dates)
  };

  const [selectedColor, setSelectedColor] = useState("#B87333");
  const [selectedColorName, setSelectedColorName] = useState("Copper");
  const [selectedaddress, setselectedaddress] = useState("");
  const [property, setproperty] = useState({});

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
  };

  const steps = [
    "Add New Property",
    "Outline Property",
    "Property Area Information"
  ];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeStep1 = queryParams.get("activeStep");
  // console.log(activeStep1,"activeStep1");
  const [activeStep, setActiveStep] = useState(propertystep ? parseInt(propertystep) : 0);

  console.log(activeStep1, "activeStep1");
  useEffect(() => {

    if (!initialized.current) {
      initialized.current = true
      if (!propertyid) {
        navigator.geolocation.getCurrentPosition((pos) => {
          if (!propertystep) {
            setcoords(prevState => ({
              ...prevState,
              ['lat']: pos.coords.latitude
            }));
            setcoords(prevState => ({
              ...prevState,
              ['lng']: pos.coords.longitude
            }));
            setAddress(pos.coords.latitude, pos.coords.longitude)
          }
        });
      }
      callprop()
    }
  }, [activeStep, activeStep1]);

  async function callprop() {
    var propertys
    if (propertyid && propertystep) {
      propertys = await getprop()
    }
    console.log('activeStep')
    console.log(activeStep)
    if (activeStep === 1) {
      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: selectedmapstyles,
        center: propertys && propertys.latlng && propertys.latlng.lat ? [propertys.latlng.lng, propertys.latlng.lat] : [coords.lng, coords.lat],
        zoom: propertys && propertys.zoomlevel ? propertys.zoomlevel : 16
      });
      draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: {
          ...MapboxDraw.modes
        },
        controls: {
          polygon: true
          // trash: true
        },
        defaultMode: 'draw_polygon',
        styles: [
          // Default style for polygon
          {
            "id": "gl-draw-polygon-fill-inactive",
            "type": "fill",
            "paint": {
              "fill-color": colorStore, // Change this color as needed
              "fill-outline-color": colorStore,
              "fill-opacity": 0.5
            }
          },
          // Default style for polygon outline
          {
            "id": "gl-draw-polygon-stroke-inactive",
            "type": "line",
            "paint": {
              "line-color": colorStore,
              "line-dasharray": [2, 2],
              "line-width": 2,
              "line-opacity": 0.7
            }
          },
          // vertex point halos
          {
            "id": "gl-draw-polygon-and-line-vertex-halo-active",
            "type": "circle",
            "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
            "paint": {
              "circle-radius": 7,
              "circle-color": colorStore
            }
          },
          // vertex points
          {
            "id": "gl-draw-polygon-and-line-vertex-active",
            "type": "circle",
            "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
            "paint": {
              "circle-radius": 5,
              "circle-color": colorStore,
            }
          }
        ]
      });
      map.addControl(draw);

      drawRef.current = draw;
      // Function to remove existing polygons
      function removeExistingPolygons() {
        const allFeatures = draw.getAll();
        if (allFeatures.features.length > 1) {
          draw.delete(allFeatures.features[0].id);
        }
      }
      const updateArea = (e) => {
        removeExistingPolygons()
        console.log(e)
        // Your update area logic here
        const data = draw.getAll();
        console.log(data)
        if (data.features.length > 0) {
          const area = turf.area(data);
          var areainacres = ((area * 100) / 100) / 4046.86
          const rounded_area = areainacres.toFixed(2);
          console.log(rounded_area)
          settotalarea(rounded_area)
        }
        // setAddress()
        console.log(e.features[0].geometry.coordinates)
        setgeodraw(e.features[0].geometry)
        setAddress(e.target.getCenter().lat, e.target.getCenter().lng)
        setzoomlevel(e.target.getZoom())
        if (map && map.getStyle().layers.length > 4) {
          for (var x = 0; x < map.getStyle().layers.length; x++) {
            if (map.getStyle().layers[x].id == 'outline') {
              map.removeLayer('outline');
            }
          }
          for (var x = 0; x < map.getStyle().sources.length; x++) {
            if (map.getStyle().sources[x].id == 'maine') {
              map.removeSource('maine')
            }
          }
        }
      };
      console.log(propertys)
      if (propertys.latlng && propertys.latlng.lat) {
        setcoords(prevState => ({
          ...prevState,
          ['lat']: propertys.latlng.lat
        }));
        setcoords(prevState => ({
          ...prevState,
          ['lng']: propertys.latlng.lng
        }));
      }
      if (propertys && propertys.geodraw && propertys.geodraw.coords) {

        map.on('load', () => {
          // Add a data source containing GeoJSON data.
          map.addSource('maine', {
            'type': 'geojson',
            'data': {
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                // These coordinates outline Maine.
                'coordinates': propertys.geodraw.coords.paths
              }
            }
          });
          map.addLayer({
            'id': 'outline',
            'type': 'line',
            'source': 'maine',
            'layout': {},
            'paint': {
              'line-color': propertys.propertycolor,
              'line-width': 3
            }
          });
        });
      }
      map.on("draw.create", updateArea);
      map.on("draw.delete", updateArea);
      map.on("draw.update", updateArea);
      map.on("draw.combine", updateArea);
      map.on("draw.uncombine", updateArea);
      map.on('wheel', (e) => {
        console.log('event type:', e.type);
        console.log(e.target.getZoom())
        console.log(e.features)
        setAddress(e.target.getCenter().lat, e.target.getCenter().lng)
        setzoomlevel(e.target.getZoom())
        // event type: wheel
      })

      setMap(map);
      setMapDraw(draw);
      return () => {
        map.remove();
        drawRef.current = null;
      };
    }
  }
  // On Edit Property
  async function getprop(close) {
    var body = {
      "apiurl": "/property/single",
      "propertyid": propertyid,
      headers: { "x-access-token": usertoken }
    }
    var propertys = await dispatch(fetchData(body))
    if (propertys && propertys.payload && propertys.payload.status == 200) {
      console.log(propertys.payload.data)
      setActiveStep(parseInt(propertystep))
      setproperty(propertys.payload.data)
      settotalarea(propertys.payload.data.totalarea)
      setselectedaddress(propertys.payload.data.areaaddress)
      // Step 0 Form
      setstep1InputField(prevState => ({ ...prevState, ['title']: propertys.payload.data.title }))
      setstep1InputField(prevState => ({ ...prevState, ['category']: propertys.payload.data.category }))
      setstep1InputField(prevState => ({ ...prevState, ['country']: propertys.payload.data.country }))
      setstep1InputField(prevState => ({ ...prevState, ['city']: propertys.payload.data.city }))
      setstep1InputField(prevState => ({ ...prevState, ['state']: propertys.payload.data.state }))
      setstep1InputField(prevState => ({ ...prevState, ['zipcode']: propertys.payload.data.zipcode }))
      setstep1InputField(prevState => ({ ...prevState, ['lease']: propertys.payload.data.lease }))
      setstep1InputField(prevState => ({ ...prevState, ['description']: propertys.payload.data.description }))
      setstep1InputField(prevState => ({ ...prevState, ['propertynotification']: propertys.payload.data.propertynotification }))
      setstep1InputField(prevState => ({ ...prevState, ['totalarea']: propertys.payload.data.totalarea }))
      if (propertys.payload.data.features && propertys.payload.data.features.length) {
        setstep1InputField((prevState) => ({
          ...prevState,
          ['features']: propertys.payload.data.features
        }));
        for (var x = 0; x < propertys.payload.data.features.length; x++) {
          if (document.getElementById(propertys.payload.data.features[x])) {
            document.getElementById(propertys.payload.data.features[x]).checked = true
          }
          console.log(propertys.payload.data.features[x])
        }
      }
      // Step 2 select area
      if (!close && areaautoselect && propertystep == '2') {
        // areaautoselect
        var e = {
          target: { id: "editarea_" + areaautoselect },
          property: propertys.payload.data
        }
        handleEditclick(e)
      }
      return propertys.payload.data
    }
    else {
      if (propertys.payload.response && propertys.payload.response.data && propertys.payload.response.data.message) {
        toastify({ message: propertys.payload.response.data.message })
        if (propertys.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
      return null
    }

  }


  const handlePrevious = () => {
    Navigategoback(`/addproperty?index=${propertyid}&step=${activeStep - 1}`);
    window.location.reload()
  };

  const usertoken = useSelector((state) => state.userdata.usertoken);

  const handleNext = async (e) => {
    console.log(step1inputField);
    var body = {};
    if (activeStep == 0) {
      if (
        step1inputField.title &&
        step1inputField.city &&
        step1inputField.state &&
        step1inputField.country &&
        step1inputField.zipcode &&
        step1inputField.category &&
        step1inputField.lease
      ) {
        body = {
          "apiurl": propertyid ? "/property/update" : "/property/create",
          "title": step1inputField.title,
          "description": step1inputField.description,
          "city": step1inputField.city,
          "county": step1inputField.county,
          "state": step1inputField.state,
          "country": step1inputField.country,
          "zipcode": step1inputField.zipcode,
          "features": step1inputField.features,
          "category": step1inputField.category,
          "lease": step1inputField.lease,
          headers: { "x-access-token": usertoken }
        }
        if (propertyid) {
          body._id = propertyid
        }

        if (coords.lat && coords.lng) {
          body.latlng = { lat: coords.lat, lng: coords.lng }
        }
      }
      else {
        return toastify({ "message": "All Fields are mandatory to create property" })
      }
    }
    else if (activeStep == 1) {
      console.log(property)
      body = {
        "apiurl": "/property/update",
        "_id": property._id,
        "areaaddress": selectedaddress,
        "totalarea": totalarea,
        "latlng": coords,
        "propertycolor": colorStore,
        headers: { "x-access-token": usertoken }
      }
      if (zoomlevel) {
        body.zoomlevel = zoomlevel
      }
      if (geodraw.coordinates) {
        body.geodraw = {
          "type": geodraw.type,
          "coords": {
            "paths": geodraw.coordinates
          }
        }
      }
    }
    else if (activeStep == 2) {
      console.log(property)
      if (editselectedarea && editselectedarea._id) {
        if (!stepEinputField.areaname) {
          if (e.target.id == 'step_final') {
            handleSave()
            return
          }
          else {
            toastify({ message: "Area name is required" })
            return
          }
        }
        var areas = []
        if (property.area.length) {
          areas = JSON.parse(JSON.stringify(property.area))
        }
        var dates = []
        if (stepEinputField.dates.length) {
          for (var x = 0; x < stepEinputField.dates.length; x++) {
            if (stepEinputField.dates[x].start && stepEinputField.dates[x].end && stepEinputField.dates[x].price) {
              dates.push(stepEinputField.dates[x])
            }
          }
        }
        for (var x = 0; x < areas.length; x++) {
          if (editselectedarea._id == areas[x]._id) {
            areas[x].areaname = stepEinputField.areaname
            areas[x].areacolor = stepEinputField.areacolor
            areas[x].slotdetail = stepEinputField.slotdetail
            areas[x].dates = dates
          }
        }
      }
      else {

        if (!step2inputField.areaname) {
          if (e.target.id == 'step_final') {
            handleSave()
            return
          }
          else {
            toastify({ message: "Area name is required" })
            return
          }
        }
        var areas = []
        if (property.area.length) {
          for (var x = 0; x < property.area.length; x++) {
            areas.push(property.area[x])
          }
        }
        var dates = []
        if (step2inputField.dates.length) {
          for (var x = 0; x < step2inputField.dates.length; x++) {
            if (step2inputField.dates[x].start && step2inputField.dates[x].end && step2inputField.dates[x].price) {
              dates.push(step2inputField.dates[x])
            }
          }
        }
        areas.push({
          "areaname": step2inputField.areaname,
          "slotdetail": step2inputField.slotdetail,
          "areacolor": step2inputField.areacolor,
          "dates": dates,
        })
      }
      body = {
        "apiurl": "/property/update",
        "_id": property._id,
        'area': areas,
        headers: { "x-access-token": usertoken }
      }
    }
    console.log(body)
    var propertys = await dispatch(fetchData(body))
    console.log(propertys)
    if (propertys && propertys.payload && propertys.payload.status == 200) {
      toastify({ message: propertys.payload.message })
      setproperty(propertys.payload.data)
      if (activeStep < steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if (editselectedarea && editselectedarea._id) {
        seteditselectedarea({})
        setstepEInputField({
          areaname: "",
          slotdetail: "",
          areacolor: "#000",
          dates: [{
            start: "",
            end: "",
            price: ""
          }]
        })
        setEditslotinf(!editslotinf);
      }
      if (activeStep == 0) {
        Navigate("/addproperty?index=" + propertys.payload.data._id + "&step=1");
        window.location.reload()
      }
      if (activeStep == 1) {
        Navigate("/addproperty?index=" + property._id + "&step=2");
      }
      else if (activeStep == 2 && e.target.id == '') {
        window.location.reload()
      }
      else if (activeStep == 2 && e.target.id == 'step_2_edit') {
        getprop(true)
      }
      if (e.target.id == 'step_final') {
        handleSave()
      }
    }
    else {
      toastify({ "message": propertys.payload.message })
    }
  };

  const handleChange = (event) => {
    // 👇️ this is the checked value of the field
    console.log(event.target.checked);
    console.log(step1inputField);
    setstep1InputField((prevState) => ({
      ...prevState, // Spread the previous state
      features: [...prevState.features, event.target.name] // Append the new feature to the features array
    }));
    console.log(step1inputField);
  };

  const handleStepClick = (stepIndex) => {
    // setActiveStep(stepIndex);
  };

  // outline
  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);

  function _suggestionSelect(result, lat, lng, text) {
    console.log(result, lat, lng, text)
    setselectedaddress(result)
    setMapCenter([lng, lat])
  }

  function suggestlistchange(e){
    console.log(e)
  }

  async function _suggestioncitySelect(index) {

    await setstep1InputField((prevState) => ({
      ...prevState,
      ['zipcode']: ""
    }));
    await setstep1InputField((prevState) => ({
      ...prevState,
      ['city']: ""
    }));
    await setstep1InputField((prevState) => ({
      ...prevState,
      ['state']: ""
    }));
    await setstep1InputField((prevState) => ({
      ...prevState,
      ['county']: ""
    }));
    // await setstep1InputField((prevState) => ({
    //   ...prevState,
    //   ['country']: ""
    // }));

    setAddress(suggestionlist[index].center[1], suggestionlist[index].center[0])
    var address = suggestionlist[index].context
    for (var x = 0; x < suggestionlist[index].context.length; x++) {
      if (suggestionlist[index].context[x] && suggestionlist[index].context[x].text) {
        if (address[x].id.includes('district')) {
          await setstep1InputField((prevState) => ({
            ...prevState,
            ['city']: suggestionlist[index].text
          }));
        }
        if (suggestionlist[index].context[x].id.includes('region')) {
          await setstep1InputField((prevState) => ({
            ...prevState,
            ['state']: suggestionlist[index].context[x].text
          }));
        }
        // if(suggestionlist[index].context[x].id.includes('country')){
        //   await setstep1InputField((prevState) => ({
        //     ...prevState,
        //     ['country']: suggestionlist[index].context[x].text
        //   }));
        // }
        if (suggestionlist[index].context[x].id.includes('postcode')) {
          await setstep1InputField((prevState) => ({
            ...prevState,
            ['zipcode']: suggestionlist[index].context[x].text
          }));
        }
        if (suggestionlist[index].context[x].id.includes('place')) {
          await setstep1InputField((prevState) => ({
            ...prevState,
            ['county']: suggestionlist[index].context[x].text
          }));
        }
      }
    }
    setsuggestionlist([])
  }

  function setMapCenter(coords) {
    console.log(coords)
    if (mapObject) {
      mapObject.setCenter(coords);
      mapObject.setZoom(16);
    }
  }
  const [drawFeatureID, setdrawFeatureID] = useState("");
  function setAddress(lat, lng) {
    setcoords(prevState => ({
      ...prevState,
      ['lat']: lat
    }));
    setcoords(prevState => ({
      ...prevState,
      ['lng']: lng
    }));
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?types=address&access_token=${mapboxgl.accessToken}`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.features && data.features.length) {
          var name = data.features[0].place_name.replace('Undefined, ', '')
          name = name.replace('Undefined', '')
          setselectedaddress(name)
        }
      });
  }
  var setDrawFeature = function (e) {
    if (e.features.length && e.features[0].type === 'Feature') {
      var feat = e.features[0];
      setdrawFeatureID(feat.id)
    }
  }
  // Function to handle button click
  const handleButtonClick = (action) => {
    if (drawRef.current) {
      switch (action) {
        case "create":
          drawRef.current.changeMode("draw_polygon");
          break;
        case "delete":
          drawRef.current.deleteAll();
          break;
        // Add more cases for other actions if needed
        default:
          break;
      }
    }
  };

  // Slotinformation

  const [numSlots, setNumSlots] = useState(1);
  let Navigate = useNavigate();

  const handleSave = () => {
    Navigate("/slotproperty?index=" + property._id);
  };

  const handleAddSlot = () => {
    console.log(step2inputField)
    var modifiedFood = step2inputField.dates
    for (let index = 0; index < modifiedFood.length; index++) {
      const element = modifiedFood[index];
      if(element.start.length==0){
        toastify({ "message": 'Please select start date' })
        return
      }
      if(element.end.length == 0){
        toastify({ "message": 'Please select end date ' })
        return
      }
    }
    modifiedFood.push({
      start: "",
      end: "",
      price: ""
    })
    var prop2 = {
      areaname: step2inputField.areaname,
      slotdetail: step2inputField.slotdetail,
      areacolor: step2inputField.areacolor,
      dates: modifiedFood
    }
    setstep2InputField(pre => pre = prop2)
  };

  const handleAddSlotEdit = () => {
    console.log(stepEinputField)
    var modifiedFood = stepEinputField.dates
    modifiedFood.push({
      start: "",
      end: "",
      price: ""
    })
    var prop2 = {
      areaname: stepEinputField.areaname,
      slotdetail: stepEinputField.slotdetail,
      areacolor: stepEinputField.areacolor,
      dates: modifiedFood
    }
    setstepEInputField(pre => pre = prop2)
  };

  return (
    <>
      <div className="step-div step-div-add-property">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </div>

      {activeStep === 0 && (
        <div className="add-main property-container">
          <div className="add-formdiv">
            <div className="add-top">
              <h2>Add New Property</h2>
            </div>
            <div className="add-form">
              <div className="form-div">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Name of Property <span className="required-star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Property"
                  name="title"
                  onChange={inputsHandler}
                  value={step1inputField.title}
                />
              </div>

              <div className="select-div">
                <div className="select-div1">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label required"
                  >
                    Category <span className="required-star">*</span>
                  </label>
                  <select
                    class="form-select form-select-lg mb-3"
                    aria-label="Large select example"
                    name="category"
                    onChange={inputsHandler}
                    value={step1inputField.category}
                  >
                    {/* <option selected>Select</option>
                    <option value="Cabelas">Cabelas</option>
                    <option value="Camping">Camping</option>
                    <option value="Cast & Blast">Cast & Blast</option>
                    <option value="Hunting">Hunting</option> */}
                    <option value="Dove Hunting">Dove Hunting</option>
                    {/* <option value="Other">Other</option> */}
                  </select>
                </div>

                <div className="select-div2">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label required"
                  >
                    Wanting to Lease <span className="required-star">*</span>
                  </label>
                  <select
                    class="form-select form-select-lg mb-3"
                    aria-label="Large select example"
                    name="lease"
                    onChange={inputsHandler}
                    value={step1inputField.lease}
                  >
                    <option selected>Select Wanting Lease </option>
                    <option value="1 Person Per Slot">1 Person Per Slot</option>
                    {/* <option value="Day - Multi Person"> */}
                    {/* Day - Multi Person
                    </option> */}
                  </select>
                </div>
              </div>

              <div className="select-div">
                <div className="select-div1">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label required"
                  >
                    Country <span className="required-star">*</span>
                  </label>

                  <select
                    class="form-select form-select-lg mb-3"
                    aria-label="Large select example"
                    name="country"
                    onChange={inputsHandler}
                    value={step1inputField.country}
                  >
                    {countries.map((country, index) => (
                      <option value={country.country}>{country.country}</option>
                    ))}
                  </select>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Country Name"
                    name="country"
                    onChange={inputsHandler}
                    value={step1inputField.country}
                  /> */}
                  {/* <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                    inputClass='form-control search-city'
                    placeholder="Enter City Name"
                    onSuggestionSelect={_suggestioncitySelect}
                    resetSearch={false} 
                    name="city"
                    // onChange={inputsHandler}
                    value={step1inputField.city}/> */}
                </div>
                <div className="select-div2">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label required"
                  >
                    State <span className="required-star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter State Name"
                    name="state"
                    onChange={inputsHandler}
                    value={step1inputField.state}
                  />
                </div>
              </div>

              <div className="select-div">
                <div className="select-div1">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label required"
                  >
                    City <span className="required-star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City Name"
                    name="city"
                    onChange={inputsHandler}
                    onBlur={() => removesuggestionlist}
                    value={step1inputField.city}
                  />
                  <div className={suggestionlist && suggestionlist.length ? "suggestion-list-container" : ''}>{suggestionlist && suggestionlist.length ?
                    suggestionlist.map((suggest, sindex) => (<div className="suggestion-list" onClick={() => _suggestioncitySelect(sindex)} ><div> {suggest.place_name}</div></div>))
                    : null}</div>
                </div>
                <div className="select-div2">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label required"
                  >
                    County or Parish
                    {/* <span className="required-star">*</span> */}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter County or Parish"
                    name="county"
                    onChange={inputsHandler}
                    value={step1inputField.county}
                  />
                </div>
              </div>

              <div className="select-div">
                <div className="select-div1">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label required"
                  >
                    Zip Code <span className="required-star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Zip Code"
                    name="zipcode"
                    onChange={inputsHandler}
                    value={step1inputField.zipcode}
                  />
                </div>
                <div className="select-div1"></div>
              </div>





              <div className="form-des">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Property Description
                </label>
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    name="description"
                    onChange={inputsHandler}
                    value={step1inputField.description}
                  ></textarea>
                  <label for="floatingTextarea2">Describe your property</label>
                </div>
              </div>

              <div className="dis-p">
                <p>
                  Select the features that apply to your property or service
                  below. If there is a feature not listed that applies to your
                  property or service, <br />
                  be sure to add it in the description which is used in the
                  keyword search.
                </p>
              </div>

              <div className="checkbox-div">
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Parking"
                    name="Parking"
                    value="Parking"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Parking</label>
                </div>

                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="CropStanding"
                    name="CropStanding"
                    value="CropStanding"
                    onChange={handleChange}
                  />
                  <label for="vehicle2">Crop Standing</label>
                </div>

                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="SesameField"
                    name="SesameField"
                    value="SesameField"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Sesame Field</label>
                </div>

                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="PastureLand"
                    name="PastureLand"
                    value="PastureLand"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Pasture Land</label>
                </div>

                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Creek"
                    name="Creek"
                    value="Creek"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Creek</label>
                </div>

                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Kitchen"
                    name="Kitchen"
                    value="Kitchen"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Kitchen</label>
                </div>

                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Guided"
                    name="Guided"
                    value="Guided"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Guided</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="CornField"
                    name="CornField"
                    value="CornField"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Corn Field</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="WildSunflowers"
                    name="WildSunflowers"
                    value="WildSunflowers"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Wild Sunflowers</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Treelines"
                    name="Treelines"
                    value="Treelines"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Treelines</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Lodging"
                    name="Lodging"
                    value="Lodging"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Lodging</label>
                </div>
                <div className="checkbox-details">
                  <input type="checkbox" id="Oven" name="Oven" value="Oven" />
                  <label for="vehicle1">Oven</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="SemiGuided"
                    name="SemiGuided"
                    value="SemiGuided"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Semi Guided</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="MiloField"
                    name="MiloField"
                    value="MiloField"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Milo Field</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Sunflowers"
                    name="Sunflowers"
                    value="Sunflowers"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Sunflowers</label>
                </div>
                <div className="checkbox-details">
                  <input type="checkbox" id="Pond" name="Pond" value="Pond" />
                  <label for="vehicle1">Pond(s)</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="RoomBoard"
                    name="RoomBoard"
                    value="RoomBoard"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Room & Board</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="StoveTop"
                    name="StoveTop"
                    value="StoveTop"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Stove Top</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="CropHarvested"
                    name="CropHarvested"
                    value="CropHarvested"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Crop Harvested</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="WheatField"
                    name="WheatField"
                    value="WheatField"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Wheat Field</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="FoodPlot"
                    name="FoodPlot"
                    value="FoodPlot"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Food Plot</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="River"
                    name="River"
                    value="River"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">River</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Showers"
                    name="Showers"
                    value="Showers"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Showers</label>
                </div>
                <div className="checkbox-details">
                  <input
                    type="checkbox"
                    id="Refrigerator"
                    name="Refrigerator"
                    value="Refrigerator"
                    onChange={handleChange}
                  />
                  <label for="vehicle1">Refrigerator</label>
                </div>
              </div>
              <hr className="save-tophr" />

              <div className="save-btndiv">
                <button className="add-btn" id="step_0" onClick={handleNext}>
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeStep === 1 && (
        <div className="add-main">
          <div className="outline-container">
            <div className="left-side">
              <div className="left-sidemain">
                <div className="left-side1 choose-left">
                  <div className="colorselecthandel">
                    <div className="colorselecthandel_1">
                      <h1>Choose Color</h1></div>
                    <div className="colorselecthandel_2">
                      <div
                        className="colorselect red"
                        style={{ backgroundColor: "#FF0000" }}
                        onClick={() => colorHandle("#FF0000")}
                      ></div>
                      <div
                        className="colorselect blue"
                        style={{ backgroundColor: "#17C653" }}
                        onClick={() => colorHandle("#17C653")}
                      ></div>
                      <div
                        className="colorselect black"
                        style={{ backgroundColor: "#FF9639" }}
                        onClick={() => colorHandle("#FF9639")}
                      ></div>
                      <div
                        className="colorselect yellow"
                        style={{ backgroundColor: "#202620" }}
                        onClick={() => colorHandle("#202620")}
                      ></div>
                      <div
                        className="colorselect black"
                        style={{ backgroundColor: "#6300C6" }}
                        onClick={() => colorHandle("#6300C6")}
                      ></div>
                      <div
                        className="colorselect yellow"
                        style={{ backgroundColor: "#0020C6" }}
                        onClick={() => colorHandle("#0020C6")}
                      ></div>

                      <div className="choosecolor-div" onClick={handleInputChange}>
                        <div className="choosecolor-input">
                          <input
                            type="color"
                            id="color"
                            value={colorStore}
                            onChange={handleInputChange}
                          />
                        </div>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>

                    </div>
                  </div>
                </div>
                {/* <div className="left-side2">
                  <button className="btn-add-back" onClick={removeExistingPolygons}>
                    Delete Selected Shape
                  </button>
                </div> */}
              </div>

              <div className="map-div">
                <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                  placeholder="Search Address by (Name / Longitude, Latitude)"
                  inputClass='form-control search'
                  onSuggestionSelect={_suggestionSelect}
                  onBlur={suggestlistchange}
                  resetSearch={false} />
                <div className="map_style">
                  {mapstyles.map((styles, i) => <div class="form-check">
                    <input class="form-check-input" type="radio" name="styledesign" id={"flexRadioDefault1" + styles.value}
                      value={styles.value} onChange={selectmapstyle} checked={styles.value == selectedmapstyles} />
                    <label class="form-check-label" for={"flexRadioDefault1" + styles.value}>
                      {styles.name}
                    </label>
                  </div>)}
                </div>
                <div
                  ref={mapContainerRef}
                  style={{
                    width: "100%",
                    height: "700px",
                    borderRadius: "10px"
                  }}
                />
              </div>
            </div>

            <div className="right-side">
              <div className="outline-top">
                <div className="add-top">
                  <h2>Property Details</h2>
                </div>
                <div className="pro-detailsdiv">
                  <div className="pro-details">
                    <p className="th-p">Property Name:</p>
                    <p className="td-p">{property.title}</p>
                  </div>

                  <div className="pro-details">
                    <p className="th-p">Category:</p>
                    <p className="td-p">{property.category}</p>
                  </div>
                  <div className="pro-details">
                    <p className="th-p">City:</p>
                    <p className="td-p">{property.city}</p>
                  </div>
                  <div className="pro-details">
                    <p className="th-p">County/Parish:</p>
                    <p className="td-p">{property.county}</p>
                  </div>
                  <div className="pro-details">
                    <p className="th-p">State:</p>
                    <p className="td-p">{property.state}</p>
                  </div>
                  <div className="pro-details">
                    <p className="th-p">Country:</p>
                    <p className="td-p">{property.country}</p>
                  </div>
                </div>
              </div>
              <div className="outline-top outline-bottom">
                <div className="add-top">
                  <h2>Selected Area Details</h2>
                </div>

                <div className="pro-detailsdiv">
                  <div className="pro-details">
                    <p className="th-p">Address:</p>
                    <p className="td-p">
                      {selectedaddress}
                    </p>
                  </div>

                  <div className="pro-details">
                    <p className="th-p">Total Area:</p>
                    <p className="td-p">{totalarea} Acres</p>
                  </div>
                  <div className="pro-details">
                    <p className="th-p">Coordinates:</p>
                    <p className="td-p">Longitude {coords.lng} & Latitude {coords.lat}</p>
                  </div>
                </div>
              </div>

              <div className="slot-btndiv">
                <button className="btn-add-back" onClick={handlePrevious}>
                  Go Back
                </button>
                <button className="add-btn" id="step_1" onClick={handleNext}>
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeStep === 2 && (
        <>
          <div className="add-main">
            <div className="add-formdiv">
              <div className="add-top">
                <h2>Area Information</h2>
              </div>
              <div className="add-form">
                <div className="select-div">
                  <div className="select-div1">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label required"
                    >
                      Area Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Area Name"
                      name="areaname"
                      onChange={inputsHandlerarea}
                      value={step2inputField.areaname}
                    />
                  </div>
                  <div className="select-div2">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label required"
                    >
                      Slot Details
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Slot Details"
                      name="slotdetail"
                      onChange={inputsHandlerarea}
                      value={step2inputField.slotdetail}
                    />
                  </div>
                </div>

                <div className="select-div">
                  <div className="select-div1">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label required"
                    >
                      Slot Color
                    </label>
                    <div className="areacolor-div">
                      <div className="areacolor-input">
                        <input
                          className=""
                          type="color"
                          id="color"
                          name="areacolor"
                          onChange={inputsHandlerarea}
                          value={step2inputField.areacolor}
                        />
                      </div>
                      <p>{selectedColor}</p>
                      {/* <p style={{ color: selectedColor }}>{selectedColorName}</p> */}
                    </div>
                  </div>
                  <div className="select-div2"></div>
                </div>

                <div className="slot-fields">{step2inputField.dates.map((date, i) => {
                  return (
                    <div className="slot-threefield" key={i}>
                      <div className="field-one">
                        <label htmlFor="startDate" className="form-label required">
                          Start Date
                        </label>
                        <input type="date" onKeyDown={(e) => e.preventDefault()} className="form-control" id={`startDate-${i}`}
                          name={"area_start_" + i}
                          onChange={inputsHandlerareadates}
                          value={date.start}
                          min={i > 0 ? step2inputField.dates[i - 1].end : mindate} />
                      </div>
                      <div className="field-two">
                        <label htmlFor="endDate" className="form-label required">
                          End Date
                        </label>
                        <input type="date" onKeyDown={(e) => e.preventDefault()} className="form-control" id={`endDate-${i}`}
                          name={"area_end_" + i}
                          onChange={inputsHandlerareadates}
                          value={date.end}
                          min={date.start.length > 0 ? date.start:i > 0 ? step2inputField.dates[i - 1].end.length > 0 ? step2inputField.dates[i - 1].end : mindate : mindate} />
                      </div>
                      <div className="field-three">
                        <label htmlFor="cost" className="form-label required">
                          Cost ($Per Day)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Cost"
                          id={`cost-${i}`}
                          name={"area_price_" + i}
                          onChange={inputsHandlerareadates}
                          value={date.price}
                        />
                      </div>
                    </div>
                  )
                }
                )}</div>

                <div className="slot-btndiv">
                  <button className="btn-add-slot" onClick={handleAddSlot}>
                    <AddIcon /> Add New Dates
                  </button>
                  <button className="add-btn" onClick={handleNext}>Save</button>
                </div>
              </div>
            </div>
          </div>

          <div className="add-main view-slot">
            <div className="add-formdiv">
              <div className="add-top">
                <h2>View All Areas</h2>
              </div>
              <div className="add-form">
                <div className="add-table">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th scope="col">Areas</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {property && property.area && property.area.length ? property.area.map((tdd, index) =>
                        <tr>
                          <td>{tdd.areaname}</td>
                          <td>
                            <span className="action-icons">
                              <img src={Editicon} alt="Editicon" id={'editarea_' + index} onClick={handleEditclick} />
                              <img src={Deleteicon} alt="Deleteicon" id={'deletearea_' + index} onClick={handleDeleteclick} />
                            </span>
                          </td>
                        </tr>
                      ) : <p className="header-empty">No Area Created yet!</p>}
                    </tbody>
                  </table>
                </div>
                <div className="slot-btndiv">
                  <button className="btn-add-back" onClick={handlePrevious}>
                    Go Back
                  </button>
                  <button className="add-btn" id="step_final" onClick={handleNext}>
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {editslotinf && (
        <div className="pro-review-popup">
          <div className="pro-review-content">
            <div className="slot-top">
              <h1>Area Information</h1>
              <i className="fa-solid fa-xmark" onClick={handleClosepopup}></i>
            </div>

            <div className="pro-review-popupcontent">
              <div className="add-form">
                <div className="select-div">
                  <div className="select-div1">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label manage-Qicon required"
                    >
                      Area Name
                      <img src={Questionicon} alt="Questionicon" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Area Name"
                      name="areaname"
                      onChange={inputsHandlerareaedit}
                      value={stepEinputField.areaname}
                    />
                  </div>
                  <div className="select-div2">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label manage-Qicon required"
                    >
                      Slot Details
                      <img src={Questionicon} alt="Questionicon" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Slot Details"
                      name="slotdetail"
                      onChange={inputsHandlerareaedit}
                      value={stepEinputField.slotdetail}
                    />
                  </div>
                  <div className="select-div3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label manage-Qicon required"
                    >
                      Slot Color
                      <img src={Questionicon} alt="Questionicon" />
                    </label>
                    <div className="areacolor-div">
                      <div className="areacolor-input">
                        <input
                          className=""
                          type="color"
                          id="color"
                          name="areacolor"
                          onChange={inputsHandlerareaedit}
                          value={stepEinputField.areacolor}
                        />
                      </div>
                      <p>{stepEinputField.areacolor}</p>
                      {/* <p style={{ color: selectedColor }}>
                        {selectedColorName}
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="slot-fields">
                  {stepEinputField.dates && stepEinputField.dates.length ? stepEinputField.dates.map((date, i) => {
                    return (
                      <div className="slot-threefield" key={i}>
                        <div className="field-one">
                          <label htmlFor="startDate" className="form-label required">
                            Start Date
                          </label>
                          <input type="date" onKeyDown={(e) => e.preventDefault()} className="form-control" id={`startDate-${i}`}
                            name={"area_start_" + i}
                            onChange={inputsHandlerareadatesedit}
                            value={date.start}
                            min={i > 0 ? stepEinputField.dates[i - 1].end : mindate} />
                        </div>
                        <div className="field-two">
                          <label htmlFor="endDate" className="form-label required">
                            End Date
                          </label>
                          <input type="date" onKeyDown={(e) => e.preventDefault()} className="form-control" id={`endDate-${i}`}
                            name={"area_end_" + i}
                            onChange={inputsHandlerareadatesedit}
                            value={date.end}
                            min={date.start.length > 0 ? date.start:i > 0 ? stepEinputField.dates[i - 1].end.length > 0 ? stepEinputField.dates[i - 1].end : mindate : mindate} />
                        </div>
                        <div className="field-three">
                          <label htmlFor="cost" className="form-label required">
                            Cost ($Per Day)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Cost"
                            id={`cost-${i}`}
                            name={"area_price_" + i}
                            onChange={inputsHandlerareadatesedit}
                            value={date.price}
                          />
                        </div>
                      </div>
                    )
                  }
                  ) : null}
                </div>

                <div className="slot-btndiv">
                  <button className="btn-add-slot" onClick={handleAddSlotEdit}>
                    <AddIcon /> Add New Dates
                  </button>
                  <div className="slot-backdiv">
                    <button className="back-btn" onClick={handleClosepopup}>
                      Go Back
                    </button>
                    <button className="add-btn" id="step_2_edit" onClick={handleNext}>
                      Save & Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {deletearea && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure Want to Delete?</h1>
              <p>Are you sure you want to delete this Area? Please note that this action cannot be undone.</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handlepopupclosedelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Addproperty.propTypes = {
  window: PropTypes.func
};

export default Addproperty;
