import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import defaultimage from "../assets/defaultimage_prop.jpg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import Deleteicon from "../assets/trash.png";
import Editicon from "../assets/pencil.png";
import Documenticon from "../assets/Document.svg";
import Locationicon from "../assets/Location.svg";
import Usericon from "../assets/User.svg";
import Timeicon from "../assets/Time Circle.svg";
import Graphicon from "../assets/Graph.svg";
import Dollericon from "../assets/$.svg";
import Xicon from "../assets/Delete Icon.svg"
import Playicon from "../assets/play.png"
import Pauseicon from "../assets/pause.png"
import { toastify, userlogout, clearuser } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Components/Loader";


export default function Managearea() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [selectedproperty, setSelectedproperty] = useState('');

  const [managecouponstatus, setManagecouponstatus] = useState(false);
  const [loading, setloading] = useState(false);
  const [arrayDataItems, setarrayDataItems] = useState([]);
  const [expirydates, setexpirydates] = useState([]);
  const [maxprices, setmaxprices] = useState([]);
  const [minprices, setminprices] = useState([]);
  const [callonce, setcallonce] = useState(true);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(9);
  const [totalcount, settotalcount] = useState(0);

  function handlepopupclose() {
    setManagecouponstatus(!managecouponstatus);
  }
  async function handlepopupclosedelete() {

    console.log(selectedproperty)
    var coupon = await dispatch(fetchData({
      "apiurl": "/property/delete",
      _id: selectedproperty,
      headers: { "x-access-token": usertoken }
    }))
    console.log(coupon)
    if (coupon && coupon.payload && coupon.payload.status == 200) {
      toastify({ message: coupon.payload.message })
      setManagecouponstatus(!managecouponstatus);
      // setarrayDataItems([]);
      // setpage(1)
      // getproperties(1);
      const newArray = arrayDataItems.filter((item, index) => item._id !== selectedproperty);
      setarrayDataItems(newArray); // Updates the state with the new array
      settotalcount(totalcount - 1)
    }
    else if (coupon && coupon.payload && coupon.payload.status == 400 && coupon.payload.message){
      setManagecouponstatus(!managecouponstatus);
      toastify({ message: coupon.payload.message })
    }
  }

  async function handlepauseEdit(propertyid, status){

    var response = await dispatch(fetchData({
      "apiurl": "/property/update/status",
      propertyid: propertyid,
      status: status,
      headers: { "x-access-token": usertoken }
    }))
    console.log(response)
    if (response && response.payload && response.payload.status == 200) {
      toastify({message: `Property ${status == 'true'?'Play':'Pause'} successfully`})
      setTimeout(()=>{
        window.location.reload()
      }, 2000)
    }
    else{
      toastify({message: `Not able to ${status == 'true'?'Play':'Pause'} property`})
    }

  }

  function handleDeleteclick(index) {
    if (index.target.id && index.target.id.split("_")[1]) {
      setSelectedproperty(index.target.id.split("_")[1])
      setManagecouponstatus(true);
    }
  }

  const handleEdit = (e) => {
    console.log(arrayDataItems)
    if (e.target.id.split("_")[1]) {
      Navigate("/propertyreview?index=" + e.target.id.split("_")[1]);
    }
  };

  function loadmore(page) {
    setpage(page)
    getproperties(page);
  }

  const initialized = useRef(false)
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getproperties(page);
    }
  }, []);

  const usertoken = useSelector((state) => state.userdata.usertoken);
  
  async function getproperties(page) {
    if (usertoken == null || usertoken.length < 1) {
      clearuser()
      userlogout()
      Navigate('/')
      window.location.reload()
      return
    }
    setloading(true)
    var propertylist = await dispatch(
      fetchData({
        apiurl: "/property/list",
        page: page,
        limit: limit,
        headers: { "x-access-token": usertoken }
      })
    );
    console.log(propertylist);
    settotalcount(0)
    if (propertylist && propertylist.payload && propertylist.payload.status == 200) {
      if (
        propertylist &&
        propertylist.payload &&
        propertylist.payload.data &&
        propertylist.payload.data.length
      ) {
        settotalcount(propertylist.payload.totalcount)
        var maxdate
        var maxprice = 0
        var minprice = 0
        var maxdatepush = []
        var maxpricepush = []
        var minpricepush = []
        for (var x = 0; x < propertylist.payload.data.length; x++) {
          maxdate = ''
          maxprice = ''
          minprice = ''
          for (var y = 0; y < propertylist.payload.data[x].area.length; y++) {
            for (var z = 0; z < propertylist.payload.data[x].area[y].dates.length; z++) {
              if (propertylist.payload.data[x].area[y].dates[z].price < minprice || minprice == 0) {
                minprice = propertylist.payload.data[x].area[y].dates[z].price
              }
              if (propertylist.payload.data[x].area[y].dates[z].price > maxprice || maxprice == 0) {
                maxprice = propertylist.payload.data[x].area[y].dates[z].price
              }
              if (propertylist.payload.data[x].area[y].dates[z].end > maxdate || maxdate == '') {
                maxdate = propertylist.payload.data[x].area[y].dates[z].end
              }
            }
          }
          if (maxprice) {
            maxpricepush.push(maxprice)
          }
          else {
            maxpricepush.push('')
          }
          if (minprice) {
            minpricepush.push(minprice)
          }
          else {
            minpricepush.push('')
          }
          if (maxdate) {
            maxdatepush.push(maxdate.substring(0, 10))
          }
          else {
            maxdatepush.push('')
          }
          setmaxprices(maxpricepush)
          setminprices(minpricepush)
          setexpirydates(maxdatepush)
          if (arrayDataItems.length) {
            var arr = []
            for (var x = 0; x < arrayDataItems.length; x++) {
              arr.push(arrayDataItems[x])
            }
            for (var x = 0; x < propertylist.payload.data.length; x++) {
              arr.push(propertylist.payload.data[x])
            }
            setarrayDataItems(arr);
            setloading(false)
          }
          else {
            setarrayDataItems(propertylist.payload.data);
            setloading(false)
          }
        }
      }
      else {
        if(arrayDataItems.length == 0){
          setarrayDataItems([]);
          Navigate('/addproperty')
        }
      }
    }
    else {
      setloading(false)
      if (propertylist.payload.response && propertylist.payload.response.data && propertylist.payload.response.data.message) {
        toastify({ message: propertylist.payload.response.data.message })
        if (propertylist.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }

  return (
    <>
      <div className={arrayDataItems && arrayDataItems.length ? "real-main" : ""}>
        {arrayDataItems && arrayDataItems.length ? arrayDataItems.map((property, index) => {
          return (<div className="real-section">
            {property.images && property.images.length && property.images[0] ?
              <img src={process.env.REACT_APP_BASEURL+'/'+property.images[0]} alt="Hotwireicon" className="real-img" />
              // <div className="image-section" style={{ backgroundImage: "url(" + process.env.REACT_APP_BASEURL + '/' + property.images[0] + ")" }}></div>
              :
              // <div className="image-section" style={{ backgroundImage: "url(" + defaultimage + ")" }}></div>
              <img src={defaultimage} alt="Hotwireicon" className="real-img" />
            }
            <div className="manage-actionmain">
              {property.approved == 'true' ? <div className="lable-status ribbon-green">Approved</div> : property.approved == 'false' ? <div className="lable-status ribbon-red">Rejected</div> : property.approved == 'paused' ? <div className="lable-status ribbon">Paused</div>:<div className="lable-status ribbon">Waiting for Approval</div>}
              <div className="manage-actiondiv">
                {property.approved == 'paused'?
                  <img id={"propertyedit_" + property._id} src={Playicon} alt="Playicon" onClick={()=>handlepauseEdit(property._id, 'true')} /> :
                  property.approved == 'true'?
                  <img id={"propertyedit_" + property._id} src={Pauseicon} alt="Pauseicon" onClick={()=>handlepauseEdit(property._id, 'paused')} />:""
                }
                
                <img id={"propertyedit_" + property._id} src={Editicon} alt="Editicon" onClick={handleEdit} />
                <img src={Deleteicon} alt="Deleteicon" id={"deleteproperty_" + property._id} onClick={handleDeleteclick} />
              </div>
            </div>
            <div className="real-subsection">
              <h1>{property.title}</h1>
              <div className="realicon-p">
                <img src={Locationicon} alt="Locationicon" />
                <p className="ellipses">
                  {property.city}, {property.county}, {property.state},{" "}
                  {property.country}, {property.zipcode}
                </p>
              </div>
              <div className="realicon-p">
                <img src={Documenticon} alt="Documenticon" />
                <p className="ellipses">{property.features.map((feature, fi) => {
                  return (feature + (property.features.length - 1 == fi ? '' : ", "))
                })}</p>
              </div>
              <div className="realicon-p">
                <img src={Usericon} alt="Usericon" />
                <p>
                  Available number of slot`s{" "}
                  {property.area.reduce(
                    (res, { slots }) => res + slots.length,
                    0
                  )}
                </p>
              </div>
              {expirydates && expirydates.length && expirydates[index] ? <div className="realicon-p">
                <img src={Timeicon} alt="Timeicon" />
                <p>Expires on {expirydates[index]} </p>
              </div> : null}
              {minprices[index] || maxprices[index] ? <div className="realicon-p">
                <img src={Dollericon} alt="Dollericon" />
                <p>Price: ${minprices[index]}-${maxprices[index]}</p>
              </div> : ''}
              <div className="realicon-p">
                <img src={Graphicon} alt="Graphicon" />
                <p>Total Area: {property.totalarea} Acres</p>
              </div>
              {property.rejectreason ? <div className="realicon-p">
                <p className="danger-color">Rejection reason: {property.rejectreason}</p>
              </div> : null}
            </div>
          </div>
          )
        })
          : loading?<div className="loader-top-spacing"><Loader /></div>:<p className="header-empty">No Property created yet!</p>}
      </div>
      {totalcount == arrayDataItems.length ? null : arrayDataItems.length > 0 ?<div className="loadmore-btn">
        <button className="btn1" onClick={() => loadmore(page + 1)}>
          {loading ? <div className="loader"></div> : "Load More"}
        </button>
      </div>:""}
      {managecouponstatus && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure Want to Delete?</h1>
              <p>Are you sure you want to delete this Property? Please note that this action cannot be undone.</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handlepopupclosedelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
